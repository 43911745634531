import {useEffect} from 'react'
import Header2 from '../Layout/Header2'
import { useNavigate } from 'react-router-dom';

const Welcome =()=> {
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
          navigate('/login');
        }, 3000);
    
        // Clear the timer if the component unmounts or the URL changes
        return () => clearTimeout(timer);
      }, [navigate]);
    
    

   
  return (
    <>
    <Header2/>
    <div className='col-lg-12' style={{marginTop:'150px'}}>
      <h2 style={{color:'green'}}>Your registration is Successful</h2>
      <h2 style={{color:'black'}}>Thank For Registering <span style={{color:'blue'}}>Tax</span></h2>
      <h2 style={{color:'green'}}>Happy eFiling !</h2>

    </div>
    </>
  )
}

export default Welcome