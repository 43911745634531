import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import IconButton from "@material-ui/core/IconButton";

import React, { forwardRef, useEffect, useState } from "react";

// import
// import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { PostApi } from "../../Helper/helper";
import { getApiWithToken } from "../../Helper/helper";

import BaseUrl from "../../BaseUrl/index";
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

const Packeges = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const back = () => {
    setShow(false);
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow(false);
  const back1 = () => {
    setShow1(false);
  };
  // const [name ,setname] = useState();
  // const [trade_name ,settrade_name] = useState();
  // const [disregarded_entity ,setdisregarded_entity] = useState();
  // const [identification ,setidentification] = useState();
  // const [address ,setaddress] = useState();
  // const [another_address, setanother_address] = useState();
  // const [city, setcity] = useState();

  // update state

  // const [nameu, setnameu] = useState();
  // const [trade_nameu, settrade_nameu] = useState();
  // const [disregarded_entityu, setdisregarded_entityu] = useState();

  const [loader, setLoader] = useState(false);
//   const [type,] = useState();

  // add Managepayer
  const [uid, setuid] = useState();
  const [package_name, setpackage_name] = useState();
  const [amount, setamount] = useState();
 
 

  const [userData] = useState(localStorage.getItem("token"));
//   const [idc] = useState(localStorage.getItem("id"));
  const [datas, setdatas] = useState([]);

  useEffect(() => {
    // if (!localStorage.getItem("token")) {
    //   navigate("/Login");
    // }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("package_name", package_name);

    // formData.append("name", name);
    formData.append("amount", amount);
    // formData.append("middle_name", mname);
    // formData.append("last_name", lname);
    // formData.append("suffix", suffix);
    // formData.append("ssn", ssn);
    
    

    PostApi(`${BaseUrl.baseUrl}customer/package/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data, "ali");

        if (data.data.message === "Package Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          getData();
          setShow(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  const updateData = () => {
    setLoader(true);
    let formData = new FormData();

    formData.append("package_name", package_nameu);
    formData.append("amount", amountu);
    // formData.append("first_name", fnameu);
    // formData.append("middle_name", mnameu);
    // formData.append("last_name", lnameu);
    // formData.append("suffix", suffixu);
    // formData.append("ssn", ssnu);
    // formData.append("type", typeu);
    // formData.append("e_in", e_inu);
    // formData.append("business_name", business_nameu);
    // formData.append("disregarded_entity", disregarded_entityu);
    // formData.append("address", addressu);
    // formData.append("line_2", line_2u);
    // formData.append("city", cityu);
    // formData.append("state", stateu);
    // formData.append("zip_code", zip_codeu);
    // formData.append("country", countryu);
    // formData.append("phone_no", phone_nou);
    // formData.append("email", emailu);
    // formData.append("holding", holdingu);
    // formData.append("last_filling", last_fillingu);
    // formData.append("client_id", client_idu);
    // formData.append("un_mask", un_masku);
    // formdata.append(
    //   "picture",
    //   UpdateProfileImage == null ? ProfileImage : UpdateProfileImage
    // );

    // console.log("form data", formdata);

    PostApi(
      `${BaseUrl.baseUrl}customer/package/update/${uid}`,
      formData,
      userData
    )
      .then((response) => {
        setLoader(false);
        console.log("hhhhh>>>>>", response);
        console.log("minhaj", response.data.message);
        if (response.data.status) {
          Swal.fire({
            title: "success",
            text: response.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          setShow1(false);
          getData();
        } else {
          Swal.fire({
            title: "error",
            text: response.data.message,
            icon: "error",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "error",
          text: err,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  const [package_nameu, setpackage_nameu]= useState()
  const [amountu, setamountu]= useState()

  const updatemodal = (rowData) => {
    // setLoader(true);
    console.log(rowData.id, "uid");
    setuid(rowData.id);

    getApiWithToken(
      `${BaseUrl.baseUrl}customer/package/edit/${rowData.id}`,
      "",
      userData
    )
      .then(({ data }) => {
        console.log("minhaj", data.data);
        setpackage_nameu(data.data.package_name);
        setamountu(data.data.amount);
        // setcityu(data.data.city);
        // setcountryu(data.data.country);
        // setdisregarded_entityu(data.data.disregarded_entity);
        // sete_inu(data.data.e_in);
        // setemailu(data.data.email);
        // setholdingu(data.data.holding);
        // setlast_fillingu(data.data.last_filling);
        // setline_2u(data.data.line_2);
        // setphone_nou(data.data.phone_no);
        // setstateu(data.data.state);
        // settypeu(data.data.type);
        // setun_masku(data.data.un_mask);
        // setzip_codeu(data.data.zip_code);
        // setaddressu(data.data.address);
        // setfnameu(data.data.first_name);
        // setlnameu(data.data.last_name);
        // setmnameu(data.data.middle_name);
        // setsuffixu(data.data.suffix);
        // setssnu(data.data.ssn);

        setLoader(false);
        setShow1(true);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const getData = () => {
    setLoader(true);
    getApiWithToken(`${BaseUrl.baseUrl}customer/package/view`, "", userData)
      .then(({ data }) => {
        console.log("mmmmmmmmmmmmmmmmmmmmm", data.data);
        setdatas(data.data);

        // setName(data.name);
        // setEmail(data.email);
        // setProfileImage(data.picture);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const delData = (rowData) => {
    console.log("didasf", rowData.id);
    // setdid(rowData.id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        getApiWithToken(
          `${BaseUrl.baseUrl}customer/package/delete/${rowData.id}`,
          "",
          userData
        )
          .then((response) => {
            console.log("response", response);

            // Swal.fire({
            //   title: "success",
            //   text: "Update Successfully ",
            //   icon: "success",
            //   confirmButtonColor: "#29BF12",
            //   timer: 1500,
            // });
          })
          .catch((err) => {
            console.log("errrrr", err);
          });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your file has been deleted. ",
          showConfirmButton: false,
          timer: 1500,
        });

        getData();
      }
    });
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DeleteOutline: forwardRef((props, ref) => (
      <DeleteOutline {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <>
      {loader ? <Loader fullpage loading /> : null}
      <Modal
        show={show1}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose1}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>EDIT PACKAGES</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          
          <div className="row">
            <div className="col-lg-12">
              {/* <input type="text" className="form-control" name="name" 
                       onChange={(e) => {
                        setname(e.target.value);
                      }}
                      /> */}
                      <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="disregarded entity"
                value={package_nameu}
                onChange={(e) => {
                  setpackage_nameu(e.target.value);
                }}
              />
                {/* <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                name="disregarded entity"
                value={mnameu}
                onChange={(e) => {
                  setmnameu(e.target.value);
                }}
              />
              <label>Ein</label>
              <input
                type="text"
                className="form-control"
                name="disregarded entity"
                value={e_inu}
                onChange={(e) => {
                  sete_inu(e.target.value);
                }}
              />
              <label>Business Name</label>
              <input
                type="text"
                className="form-control"
                name="address"
                value={business_nameu}
                onChange={(e) => {
                  setbusiness_nameu(e.target.value);
                }}
              />
              <label>Disregarded Entity</label>
              <input
                type="text"
                className="form-control"
                name="Suffix"
                value={disregarded_entityu}
                onChange={(e) => {
                  setdisregarded_entityu(e.target.value);
                }}
              />
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                name="Disregarded Entity"
                value={addressu}
                onChange={(e) => {
                  setaddressu(e.target.value);
                }}
              />
              <label>line 2</label>
              <input
                type="text"
                className="form-control"
                name="Address"
                value={line_2u}
                onChange={(e) => {
                  setline_2u(e.target.value);
                }}
              />
              <label>City</label>
              <input
                type="text"
                className="form-control"
                name="City"
                value={cityu}
                onChange={(e) => {
                  setcityu(e.target.value);
                }}
              />
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="City"
                value={emailu}
                onChange={(e) => {
                  setemailu(e.target.value);
                }}
              /> */}
            </div>
            <div className="col-lg-12">
            <label>Price</label>
              <input
                type="number"
                className="form-control"
                name="country"
                value={amountu}
                onChange={(e) => {
                  setamountu(e.target.value);
                }}
              />
              {/* <label>State</label>
              <input
                type="text"
                className="form-control"
                name="country"
                value={stateu}
                onChange={(e) => {
                  setstateu(e.target.value);
                }}
              />
              <label>Zip code</label>
              <input
                type="text"
                className="form-control"
                name="State"
                value={zip_codeu}
                onChange={(e) => {
                  setzip_codeu(e.target.value);
                }}
              />
              <label>Country</label>
              <input
                type="text"
                className="form-control"
                name="email"
                value={countryu}
                onChange={(e) => {
                  setcountryu(e.target.value);
                }}
              />
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                name="Phone"
                value={phone_nou}
                onChange={(e) => {
                  setphone_nou(e.target.value);
                }}
              />
              <label>Holding</label>
              <input
                type="text"
                className="form-control"
                name="Withholding/Tax State Id"
                value={holdingu}
                onChange={(e) => {
                  setholdingu(e.target.value);
                }}
              />
              <label>Client ID</label>
              <input
                type="text"
                className="form-control"
                name="Client ID"
                value={client_idu}
                onChange={(e) => {
                  setclient_idu(e.target.value);
                }}
              />
              <label>SSN</label>
              <input
                type="text"
                className="form-control"
                name="Client ID"
                value={ssnu}
                onChange={(e) => {
                  setssnu(e.target.value);
                }}
              />
              <label>suffix</label>
              <input
                type="text"
                className="form-control"
                name="Client ID"
                value={suffixu}
                onChange={(e) => {
                  setsuffixu(e.target.value);
                }}
              />
              <div className="d-inline-flex ">
                <input
                  type="radio"
                  name="Client ID"
                  value={last_fillingu}
                  onChange={(e) => {
                    setlast_fillingu(e.target.value);
                  }}
                />
                <label style={{ marginLeft: "4px" }}>Last Filing</label>

                <input
                  type="radio"
                  name="UnMask Recipient TIN"
                  value={un_masku}
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => {
                    setun_masku(e.target.value);
                  }}
                />
                <label style={{ marginLeft: "4px" }}>
                  UnMask Recipient TIN
                </label>
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-primary"
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            onClick={updateData}
          >
            Save
          </Button>

          <Button
            className="btn btn-primary"
            onClick={back1}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>ADD Packages</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              {/* <input type="text" className="form-control" name="name" 
                       onChange={(e) => {
                        setname(e.target.value);
                      }}
                      /> */}
                      <label>Package Name</label>
              <input
                type="text"
                className="form-control"
                name="disregarded entity"
                onChange={(e) => {
                  setpackage_name(e.target.value);
                }}
                placeholder=" Enter your package name"
              />
              {/* <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                name="disregarded entity"
                onChange={(e) => {
                  setmname(e.target.value);
                }}
              />
              <label>Ein</label>
              <input
                type="text"
                className="form-control"
                name="disregarded entity"
                onChange={(e) => {
                  sete_in(e.target.value);
                }}
              />
              <label>Business Name</label>
              <input
                type="text"
                className="form-control"
                name="address"
                onChange={(e) => {
                  setbusiness_name(e.target.value);
                }}
              />
              <label>Disregarded Entity</label>
              <input
                type="text"
                className="form-control"
                name="Suffix"
                onChange={(e) => {
                  setdisregarded_entity(e.target.value);
                }}
              />
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                name="Disregarded Entity"
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
              />
              <label>line 2</label>
              <input
                type="text"
                className="form-control"
                name="Address"
                onChange={(e) => {
                  setline_2(e.target.value);
                }}
              />
              <label>City</label>
              <input
                type="text"
                className="form-control"
                name="City"
                onChange={(e) => {
                  setcity(e.target.value);
                }}
              />
               */}
            </div>
            <div className="col-lg-12">
            <label>Price</label>
              <input
                type="number"
                className="form-control"
                name="country"
                onChange={(e) => {
                  setamount(e.target.value);
                }}
                placeholder=" Enter your Amount"
              />
              {/* <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="City"
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
              <label>State</label>
              <input
                type="text"
                className="form-control"
                name="country"
                onChange={(e) => {
                  setstate(e.target.value);
                }}
              />
              <label>Zip code</label>
              <input
                type="text"
                className="form-control"
                name="State"
                onChange={(e) => {
                  setzip_code(e.target.value);
                }}
              />
              <label>Country</label>
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={(e) => {
                  setcountry(e.target.value);
                }}
              />
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                name="Phone"
                onChange={(e) => {
                  setphone_no(e.target.value);
                }}
              />
              <label>Holding</label>
              <input
                type="text"
                className="form-control"
                name="Withholding/Tax State Id"
                onChange={(e) => {
                  setholding(e.target.value);
                }}
              />
              <label>Client ID</label>
              <input
                type="text"
                className="form-control"
                name="Client ID"
                onChange={(e) => {
                  setclient_id(e.target.value);
                }}
              />
              <div className="d-inline-flex ">
                <input
                  type="radio"
                  name="Client ID"
                  onChange={(e) => {
                    setlast_filling(e.target.value);
                  }}
                />
                <label style={{ marginLeft: "4px" }}>Last Filing</label>

                <input
                  type="radio"
                  name="UnMask Recipient TIN"
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => {
                    setun_mask(e.target.value);
                  }}
                />
                <label style={{ marginLeft: "4px" }}>
                  UnMask Recipient TIN
                </label>
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-primary"
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            onClick={onSubmit}
          >
            Add
          </Button>

          <Button
            className="btn btn-primary"
            onClick={back}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
        </Modal.Footer>
      </Modal>

      <div className="col-lg-12 " style={{ marginBottom: "10px" }}>
        <button
          className="btn btn-primary d-flex flex-row-reverse "
          onClick={() => setShow(true)}
        >
          Add Package
        </button>
      </div>

      <div className="col-lg-12">
        <MaterialTable
          icons={tableIcons}
          title="List"
          columns={[
            { title: "package name", field: "package_name" },
            { title: "amount", field: "amount" },
            // { title: "Disregarded Entity", field: "disregarded_entity" },

            // { title: "Address", field: "address" },
            // { title: "line 2", field: "line_2" },
          ]}
          data={datas}
          options={{
            addRowPosition: "first",
          }}
          actions={[
            {
              icon: Edit,
              tooltip: "Edit User",
              onClick: (event, rowData) => {
                updatemodal(rowData);
              },
            },
            (rowData) => ({
              icon: DeleteOutline,
              tooltip: "Delete User",
              onClick: (id, rowData) => {
                delData(rowData);
              },
            }),
          ]}
        />
      </div>
    </>
  );
};

export default Packeges;
