import { useState,  } from "react";
// import Header from "./Header";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

//
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import { PostApi } from "../../Helper/helper";
// import { getApiWithToken } from "../../Helper/helper";
import BaseUrl from "../../BaseUrl/index";
import Swal from "sweetalert2";
import Spinner from 'react-bootstrap/Spinner';
// import { Loader } from "react-overlay-loader";

// import "react-overlay-loader/styles.css";
// import { genModalMaskStyle } from "antd/es/modal/style";

const Form941 = () => {

  

  const [userData] = useState(localStorage.getItem("token"));
  console.log(userData, "token");
  const [idc] = useState(localStorage.getItem("id"));
  const [loder, setLoader] = useState(false);

  const [taxablesocial,  settaxablesocial]= useState(0);
 const taxable_social_security_wages = taxablesocial * 0.124; 
 const [qualified_sick_leave, setqualified_sick_leave]= useState(0);
 const qualified_sick_leave_wages = qualified_sick_leave * 0.062;  
 const [qualified_family_leave_wage, setqualified_family_leave_wage]= useState(0);
 const qualified_family_leave_wages = qualified_family_leave_wage * 0.062;  
 const [taxable_social_security_tip, settaxable_social_security_tip]= useState(0);
 const taxable_social_security_tips = taxable_social_security_tip * 0.124;
 const [taxable_wages_tip, settaxable_wages_tip]= useState(0);
 const taxable_wages_tips = taxable_wages_tip *  0.009;

 const [taxable_medicare_wages_tip, settaxable_medicare_wages_tip]= useState(0);
 const taxable_medicare_wages_tips = taxable_medicare_wages_tip * 0.029;

 const [employer_identification_number, setemployer_identification_number]= useState(null)
 const [name, setname]= useState(null)
 const [trade_name, settrade_name]= useState(null)
 const [address, setaddress]= useState(null)
 const [city, setcity]= useState(null)
 const [state, setstate]= useState(null)
 const [zip_code, setzip_code]= useState(null)
 const [foreign_country_name, setforeign_country_name]= useState(null)
 
 const [foreign_province, setforeign_province]= useState(null)
 const [foreign_postal_code, setforeign_postal_code]= useState(null)
 const [report_for_this_quarter, setreport_for_this_quarter]= useState(null)
 const [number_of_employees, setnumber_of_employees]= useState(null)
 const [wages, setwages]= useState(null)
 const [federal_income_tax, setfederal_income_tax]= useState(null)
 const [if_no_wages_tips, setif_no_wages_tips]= useState(null)


 const [total_social_security_and_medicare_taxes, settotal_social_security_and_medicare_taxes]= useState(null)
 const [section_3121_Notice_and_demand, ]= useState(null)
 const [total_taxes_before_adjustments, settotal_taxes_before_adjustments]= useState(null)
 const [current_quarters_adjustment_for_fractions_of_cents, setcurrent_quarters_adjustment_for_fractions_of_cents]= useState(null)
 const [current_quarters_adjustment_for_sick_pay, setcurrent_quarters_adjustment_for_sick_pay]= useState(null)
 const [current_quarters_adjustments_for_tips_and_group_life_insurance, setcurrent_quarters_adjustments_for_tips_and_group_life_insurance]= useState(null)
 
 const [total_taxes_after_adjustments, settotal_taxes_after_adjustments]= useState(null)
 const [qualified_small_business_payroll, setqualified_small_business_payroll]= useState(null)
 const [nonrefundable_portion_1, setnonrefundable_portion_1]= useState(null)
 const [reserved_for_future_use_1, setreserved_for_future_use_1]= useState(null)
 const [nonrefundable_portion_2, setnonrefundable_portion_2]= useState(null)
 const [reserved_for_future_use_2, setreserved_for_future_use_2]= useState(null)
 const [reserved_for_future_use_3, setreserved_for_future_use_3]= useState(null)

 const [total_nonrefundable_credits, settotal_nonrefundable_credits]= useState(null)
 const [total_taxes_after_adjustments_and_nonrefundable_credits, settotal_taxes_after_adjustments_and_nonrefundable_credits]= useState(null)
 const [total_deposits_for_this_quarter, settotal_deposits_for_this_quarter]= useState(null)
 const [reserved_for_future_use_4, setreserved_for_future_use_4]= useState(null)
 const [refundable_portion_of_credit_1, setrefundable_portion_of_credit_1]= useState(null)
 const [reserved_for_future_use_5, setreserved_for_future_use_5]= useState(null)
 const [refundable_portion_of_credit_2, setrefundable_portion_of_credit_2]= useState(null)

 const [reserved_for_future_use_6, setreserved_for_future_use_6]= useState(null)
 const [total_deposits_and_refundable_credits, settotal_deposits_and_refundable_credits]= useState(null)
 const [reserved_for_future_use_7, setreserved_for_future_use_7]= useState(null)
 const [reserved_for_future_use_8, setreserved_for_future_use_8]= useState(null)
 const [balance_due, setbalance_due]= useState(null)
 const [overpayment, setoverpayment]= useState(null)
 const [tax_liability_check_1, settax_liability_check_1]= useState(null)

 const [tax_liability_check_2, settax_liability_check_2]= useState(null)
 const [tax_liability_month_1, settax_liability_month_1]= useState(null)
 const [tax_liability_month_2, settax_liability_month_2]= useState(null)
 const [tax_liability_month_3, settax_liability_month_3]= useState(null)
 const [total_liability_for_quarter, settotal_liability_for_quarter]= useState(null)
 const [schedule_depositor, setschedule_depositor]= useState(null)

 const [if_your_business_paying_wages, setif_your_business_paying_wages]= useState(null)
 const [enter_the_final_date_you_paid_wages,]= useState(null)
 const [seasonal_employer, setseasonal_employer]= useState(null)
 const [qualified_health_plan_1, setqualified_health_plan_1]= useState(null)
 const [qualified_health_plan_2, setqualified_health_plan_2]= useState(null)
 const [qualified_sick_leave_wages_part3, setqualified_sick_leave_wages_part3]= useState(null)

 const [qualified_health_plan_expenses_sick, ]= useState(null)
 const [amounts_under_certain_collectively, setamounts_under_certain_collectively]= useState(null)
 const [qualified_family_leave_wages_part3, setqualified_family_leave_wages_part3]= useState(null)
 const [qualified_health_plan_expenses_family, setqualified_health_plan_expenses_family]= useState(null)
 const [qualified_family, setqualified_family]= useState(null)

 const [yes, setyes]= useState(null)
 const [no, setno]= useState(null)
 const [designee_name, setdesignee_name]= useState(null)
 const [phone_number, setphone_number]= useState(null)
 const [personal_identification_number, setpersonal_identification_number]= useState(null)
 const [sign_your_name_here, setsign_your_name_here]= useState(null)

 const [print_your_name_here, setprint_your_name_here]= useState(null)
 const [date, setdate]= useState(null)
 const [print_your_title_here, setprint_your_title_here]= useState(null)
 const [best_daytime_phone, setbest_daytime_phone]= useState(null)
 const [preparers_name, setpreparers_name]= useState(null)
 const [preparers_signature, setpreparers_signature]= useState(null)

 const [firm_name, setfirm_name]= useState(null)
 const [preparer_address, setpreparer_address]= useState(null)
 const [preparer_city, setpreparer_city]= useState(null)
 const [pttn, setpttn]= useState(null)
 const [preparer_date, setpreparer_date]= useState(null)
 const [preparer_ein, setpreparer_ein]= useState(null)

 const [preparer_phone, setpreparer_phone]= useState(null)
 const [preparer_zip_code, setpreparer_zip_code]= useState(null)
 const [preparer_self_employed, ]= useState(null)
 const [employer_identification_number_payment, setemployer_identification_number_payment]= useState(null)
 const [tax_period, settax_period]= useState(null)
 const [amount_in_dollars, setamount_in_dollars]= useState(null)

 const [amount_in_cents, setamount_in_cents]= useState(null)
 const [business_name, setbusiness_name]= useState(null)
 const [your_address, setyour_address]= useState(null)
 const [details, setdetails]= useState(null)




 const onsubmits = () => {
  setLoader(true);
  const formData = new FormData();
  formData.append("customer_id", idc);
  formData.append("employer_identification_number", employer_identification_number);
  formData.append("name", name);
  formData.append("trade_name", trade_name);
  formData.append("address", address);
  formData.append("city", city);
  formData.append("state", state);
  formData.append("zip_code", zip_code);
  formData.append("foreign_country_name", foreign_country_name);
  formData.append("foreign_province", foreign_province);
  formData.append("foreign_postal_code", foreign_postal_code);
  formData.append("report_for_this_quarter", report_for_this_quarter);
  formData.append("number_of_employees", number_of_employees);
 formData.append("wages", wages);
  formData.append("federal_income_tax", federal_income_tax);
  formData.append("if_no_wages_tips", if_no_wages_tips);
  formData.append("taxable_social_security_wages", taxable_social_security_wages);
  formData.append("qualified_sick_leave_wages", qualified_sick_leave_wages);


  formData.append("qualified_family_leave_wages", qualified_family_leave_wages);
  formData.append("taxable_social_security_tips", taxable_social_security_tips);
  formData.append("taxable_medicare_wages_tips", taxable_medicare_wages_tips);
  formData.append("taxable_wages_tips", taxable_wages_tips);
  formData.append("total_social_security_and_medicare_taxes", total_social_security_and_medicare_taxes);
  formData.append("section_3121_Notice_and_demand", section_3121_Notice_and_demand);
  formData.append("total_taxes_before_adjustments", total_taxes_before_adjustments);
  formData.append("current_quarters_adjustment_for_fractions_of_cents", current_quarters_adjustment_for_fractions_of_cents);
  formData.append("current_quarters_adjustment_for_sick_pay", current_quarters_adjustment_for_sick_pay);
  formData.append("current_quarters_adjustments_for_tips_and_group_life_insurance", current_quarters_adjustments_for_tips_and_group_life_insurance);
  formData.append("total_taxes_after_adjustments", total_taxes_after_adjustments);
  formData.append("qualified_small_business_payroll", qualified_small_business_payroll);
 formData.append("nonrefundable_portion_1", nonrefundable_portion_1);
  formData.append("reserved_for_future_use_1", reserved_for_future_use_1);
  formData.append("nonrefundable_portion_2", nonrefundable_portion_2);
  formData.append("reserved_for_future_use_2", reserved_for_future_use_2);
  formData.append("reserved_for_future_use_3", reserved_for_future_use_3);

  
  formData.append("total_nonrefundable_credits", total_nonrefundable_credits);
  formData.append("total_taxes_after_adjustments_and_nonrefundable_credits", total_taxes_after_adjustments_and_nonrefundable_credits);
  formData.append("total_deposits_for_this_quarter", total_deposits_for_this_quarter);
  formData.append("reserved_for_future_use_4", reserved_for_future_use_4);
  formData.append("refundable_portion_of_credit_1", refundable_portion_of_credit_1);
  formData.append("reserved_for_future_use_5", reserved_for_future_use_5);
  formData.append("refundable_portion_of_credit_2", refundable_portion_of_credit_2);
  formData.append("reserved_for_future_use_6", reserved_for_future_use_6);
  formData.append("total_deposits_and_refundable_credits", total_deposits_and_refundable_credits);
  formData.append("reserved_for_future_use_7", reserved_for_future_use_7);
  formData.append("reserved_for_future_use_8", reserved_for_future_use_8);
  formData.append("balance_due", balance_due);
  formData.append("overpayment", overpayment);
  formData.append("tax_liability_check_1", tax_liability_check_1);
  formData.append("tax_liability_check_2", tax_liability_check_2);
  formData.append("tax_liability_month_1", tax_liability_month_1);
  formData.append("tax_liability_month_2", tax_liability_month_2);
  formData.append("tax_liability_month_3", tax_liability_month_3);
  formData.append("total_liability_for_quarter", total_liability_for_quarter);
  formData.append("schedule_depositor", schedule_depositor);
  formData.append("if_your_business_paying_wages", if_your_business_paying_wages);
  formData.append("enter_the_final_date_you_paid_wages", enter_the_final_date_you_paid_wages);
  formData.append("seasonal_employer", seasonal_employer);
  formData.append("qualified_health_plan_1", qualified_health_plan_1);
  formData.append("qualified_health_plan_2", qualified_health_plan_2);
  formData.append("qualified_sick_leave_wages_part3", qualified_sick_leave_wages_part3);
  formData.append("qualified_health_plan_expenses_sick", qualified_health_plan_expenses_sick);
  formData.append("amounts_under_certain_collectively", amounts_under_certain_collectively);
  formData.append("qualified_family_leave_wages_part3", qualified_family_leave_wages_part3);
  formData.append("qualified_health_plan_expenses_family", qualified_health_plan_expenses_family);
  formData.append("qualified_family", qualified_family);
  formData.append("yes", yes);
  formData.append("no", no);
  formData.append("designee_name", designee_name);
  formData.append("phone_number", phone_number);
  formData.append("personal_identification_number", personal_identification_number);
  formData.append("sign_your_name_here", sign_your_name_here);
  formData.append("print_your_name_here", print_your_name_here);
  formData.append("date", date);
  formData.append("print_your_title_here", print_your_title_here);
  formData.append("best_daytime_phone", best_daytime_phone);
  formData.append("preparers_name", preparers_name);
  formData.append("qpreparers_signature", preparers_signature);
  formData.append("firm_name", firm_name);
  formData.append("preparer_address", preparer_address);
  formData.append("preparer_city", preparer_city);
  formData.append("pttn", pttn);
  formData.append("preparer_date", preparer_date);
  formData.append("preparer_ein", preparer_ein);
  formData.append("preparer_phone", preparer_phone);
  formData.append("preparer_zip_code", preparer_zip_code);
  formData.append("preparer_self_employed", preparer_self_employed);
  formData.append("employer_identification_number_payment", employer_identification_number_payment);
  formData.append("tax_period", tax_period);
  formData.append("amount_in_dollars", amount_in_dollars);
  formData.append("amount_in_cents", amount_in_cents);
  formData.append("business_name", business_name);
  formData.append("your_address", your_address);
  formData.append("details", details);
 

  PostApi(`${BaseUrl.baseUrl}customer/form941/store`, formData, userData)
    .then((data) => {
      setLoader(false);
      console.log(data.data, "ali");

      if (data.data.message === "Form 941 Successfully Created") {
        Swal.fire({
          title: "success",
          text: data.data.message,
          icon: "success",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
        window.location.href = data.data.pdf_url;
        // getDatar();
        // setShowr(false);

        // console.log(data.data,"minhaj")
        //   console.log("heheh",data.data.user.name)
        // console.log("name", data.data.accessToken.name)
        // console.log("hahahahaha",data.status)
        // console.log("hahahahaha",data.message)

        // localStorage.setItem("token", data.data.accessToken.name);
        // localStorage.setItem("username", data.data.user.name);
        // console.log("login");
        // navigate("/");
      } else {
        // console.log("else========", data.message);
        // // alert(data.data);
        // localStorage.setItem("tokenen", data.data);
        console.log("not login");
        // Swal.fire({
        //   title: "Oops",
        //   text: "Error",
        //   icon: "error",
        //   confirmButtonColor: "#29BF12",
        // });
        console.log(data.data.errors.DOB, "DOB error");

        // setDateError(data.data.errors.DOB, "Gender error");
        // setGenderError(data.data.errors.gender, "Gender error");
        // setPasswordError(data.data.errors.password, "Password Error");
        // setPasswordConError(
        //   data.data.errors.passwordConfirmation,
        //   "Password Con Error"
        // );
        // setEmailError(data.data.errors.email, "Email Error");
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "error",
        text: error,
        icon: "error",
        confirmButtonColor: "#29BF12",
        timer: 1500,
      });
    });
};
 



  return (
    
    <div className="col-lg-12">
      <div className="row">
        <div className="col-lg-8" style={{}}>
          <h3>
            <span style={{ fontSize: "12px" }}>Form</span>{" "}
            <span style={{ fontSize: "30px" }}>941 for 2023:</span>
            <span style={{ fontSize: "20px", marginLeft: "5px" }}>
              Employer’s QUARTERLY Federal Tax Return
            </span>
          </h3>
          <p>
            (Rev. March 2023){" "}
            <span style={{ marginLeft: "96px" }}>
              Department of the Treasury — Internal Revenue Service
            </span>
          </p>
        </div>
        <div className="col-lg-4">
          <p style={{ fontWeight: "bold", fontSize: "20px" }}>950122 </p>
          <p style={{}}>OMB No. 1545-0029 </p>
        </div>
        <div
          className="col-lg-8"
          style={{ padding: "10px", border: "1px solid" }}
        >
          <div
            className="col-lg-8 d-flex justify-content-start"
           
          >
            <p style={{ fontWeight: "bold" }}>
              Employer identification number (EIN){" "}
            </p>
            <input
              type="text"
              style={{ marginLeft: "5px" }}
              className="form-control"
              onChange={(e) => setemployer_identification_number(e.target.value)}
            />

            {/* <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <span style={{ marginLeft: "5px" }}>-</span>
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            />
            <input
              type="text"
              style={{ width: "45px", marginLeft: "5px" }}
              className="form-control"
            /> */}
          </div>
          <div className="col-lg-8 d-flex justify-content-start">
            <p style={{ fontWeight: "bold" }}>
              Name <span>(not your trade name)</span>
            </p>
            <input
              type="text"
              style={{ width: "450px" }}
              className="form-control"
              onChange={(e) => setname(e.target.value)}
            />
          </div>
          <div className="col-lg-8 d-flex justify-content-start">
            <p style={{ fontWeight: "bold" }}>Trade name (if any)</p>
            <input type="text" className="form-control"
             onChange={(e) => settrade_name(e.target.value)}
            
            />
          </div>
          <div className="col-lg-8 d-flex justify-content-start">
            <p style={{ fontWeight: "bold" }}>Address</p>
            <input
              type="text"
              style={{ width: "450px", marginLeft: "60px" }}
              className="form-control"
              onChange={(e) => setaddress(e.target.value)}
            />
          </div>
          <div className="col-lg-8 d-flex justify-content-start mt-2">
            <p style={{ fontWeight: "bold" }}>City/State/Zip Code</p>
            <input
              type="text"
              style={{ marginLeft: "6px", width: "300px" }}
              className="form-control"
              placeholder="city"
              onChange={(e) => setcity(e.target.value)}
            />
            <input
              type="text"
              style={{ marginLeft: "6px", width: "100px" }}
              className="form-control"
              placeholder="State"
              onChange={(e) => setstate(e.target.value)}
            />
            <input
              type="text"
              style={{ marginLeft: "6px", width: "100px" }}
              className="form-control"
              placeholder="ZIP code"
              onChange={(e) => setzip_code(e.target.value)}
            />
          </div>
          <div className="col-lg-8 d-flex justify-content-start mt-2">
            <p style={{ fontWeight: "bold" }}>
              Foreign country name/Foreign province/county Foreign postal code
            </p>
            <input
              type="text"
              style={{ marginLeft: "6px", width: "300px" }}
              className="form-control"
              placeholder="country name"
              onChange={(e) => setforeign_country_name(e.target.value)}
            />
            <input
              type="text"
              style={{ marginLeft: "6px", width: "300px" }}
              className="form-control"
              placeholder="province"
              onChange={(e) => setforeign_province(e.target.value)}
            />
            {/* <input
              type="text"
              style={{ marginLeft: "6px", width: "100px" }}
              className="form-control"
              placeholder="zI"
              onChange={(e) => setforeign_province(e.target.value)}
            /> */}
            <input
              type="text"
              style={{ marginLeft: "6px", width: "100px" }}
              className="form-control"
              placeholder="ZIP code"
              onChange={(e) => setforeign_postal_code(e.target.value)}
              
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="col-lg-4"
            style={{ padding: "2px", border: "1px solid", width: "100%" }}
          >
            <p
              style={{
                fontWeight: "bold",
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              this Quarter of 2023
              <br />
              <span style={{ fontSize: "12px" }}>(Check one.)</span>{" "}
            </p>
            <div
              className="col-lg-4 d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <input
                type="radio"
                id="vehicle1"
                name="vehicle1"
                value="January_February_March"
                onChange={(e) => setreport_for_this_quarter(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
                1: January, February, March
              </label>
            </div>
            <div
              className="col-lg-4 d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <input
                type="radio"
                id="vehicle1"
                name="vehicle1"
                value="April_May_June"
                onChange={(e) => setreport_for_this_quarter(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>2: April, May, June</label>
            </div>
            <div
              className="col-lg-4 d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <input
                type="radio"
                id="vehicle1"
                name="vehicle1"
                value="July_August_September"
                onChange={(e) => setreport_for_this_quarter(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
                3: July, August, September
              </label>
            </div>
            <div
              className="col-lg-4 d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <input
                type="radio"
                id="vehicle1"
                name="vehicle1"
                value="October_November_December"
                onChange={(e) => setreport_for_this_quarter(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
                4: October, November, December
              </label>
            </div>
            <p style={{ marginTop: "10px" }}>
              Go to www.irs.gov/Form941 for instructions and the latest
              information.
            </p>
          </div>
        </div>
        <div className="col-lg-12 mt-2">
          <p>
            Read the separate instructions before you complete Form 941. Type or
            print within the boxes
          </p>
        </div>
        <div className="col-lg-12 mt-2">
          <p style={{ border: "1px solid black" }}>
            <span
              style={{
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              Part 1:
            </span>{" "}
            Answer these questions for this quarter.
          </p>
        </div>
        <div className="col-lg-8">
          <p style={{ fontWeight: "bold" }}>
            1, Number of employees who received wages, tips, or other
            compensation for the pay period including: Mar. 12 (Quarter 1), June
            12 (Quarter 2), Sept. 12 (Quarter 3), or Dec. 12 (Quarter 4)
          </p>
        </div>
        <div className="col-lg-4 d-flex justify-content-start">
          <span style={{ fontWeight: "bold" }}>1</span>{" "}
          <input
            type="text"
            className="form-control"
            style={{ marginLeft: "6px" }}
            onChange={(e) => setnumber_of_employees(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p style={{ fontWeight: "bold" }}>
            2, Wages, tips, and other compensation . . . . . . . . . . . . . . .
            . .
          </p>
        </div>
        <div className="col-lg-4 d-flex justify-content-start">
          <span style={{ fontWeight: "bold" }}>2</span>{" "}
          <input
            type="text"
            className="form-control"
            style={{ marginLeft: "6px" }}
            onChange={(e) => setwages(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p style={{ fontWeight: "bold" }}>
            3, Federal income tax withheld from wages, tips, and other
            compensation . . . . . .
          </p>
        </div>
        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>3</span>{" "}
          <input
            type="text"
            className="form-control"
            style={{ marginLeft: "6px" }}
            onChange={(e) => setfederal_income_tax(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p style={{ fontWeight: "bold" }}>
            4, If no wages, tips, and other compensation are subject to social
            security or Medicare tax
          </p>
        </div>
        <div className="col-lg-4 d-flex justify-content-start">
          <span style={{ fontWeight: "bold" }}>4</span>
          <input
            type="checkbox"
            id="vehicle1"
            name="vehicle1"
            value="1"
            style={{ marginLeft: "10px" }}
            onChange={(e) => setif_no_wages_tips(e.target.value)}
          />
          <label style={{ marginLeft: "5px" }}>Check and go to line 6</label>
        </div>
        <div className="col-lg-8" style={{}}>
          <div className="row">
            <div className="col-lg-4">
              <span style={{ fontWeight: "bold" }}>5a</span> Taxable social
              security wages* . .
            </div>
            <div className="col-lg-6 d-flex justify-content-start">
              <input type="number" className="form-control" onChange={(e) => settaxablesocial(+e.target.value)} />
              <span style={{ fontSize: "16PX", width: "180PX" }}>
                × 0.124 =
              </span>
              <input type="number" className="form-control" value={taxable_social_security_wages} />
            </div>

            <div className="col-lg-4">
              <span style={{ fontWeight: "bold" }}>5a</span> (i) Qualified sick
              leave wages* .
            </div>
            <div className="col-lg-6 d-flex justify-content-start mt-2">
              <input type="number" className="form-control"  onChange={(e) => setqualified_sick_leave(+e.target.value)} />
              <span style={{ fontSize: "16PX", width: "180PX" }}>
                × 0.062 =
              </span>
              <input type="number" className="form-control" value={qualified_sick_leave_wages}/>
            </div>
            <div className="col-lg-4">
              <span style={{ fontWeight: "bold" }}>5a</span> (ii) Qualified
              family leave wages*
            </div>
            <div className="col-lg-6 d-flex justify-content-start mt-2">
              <input type="number" className="form-control" onChange={(e) => setqualified_family_leave_wage(+e.target.value)}/>
              <span style={{ fontSize: "16PX", width: "180PX" }}>
                × 0.062 =
              </span>
              <input type="number" className="form-control" value={qualified_family_leave_wages} />
            </div>
            <div className="col-lg-4">
              <span style={{ fontWeight: "bold" }}>5b</span>Taxable social
              security tips . . .
            </div>
            <div className="col-lg-6 d-flex justify-content-start mt-2">
              <input type="number" className="form-control"  onChange={(e) => settaxable_social_security_tip(+e.target.value)}/>
              <span style={{ fontSize: "16PX", width: "180PX" }}>
                × 0.124 =
              </span>
              <input type="number" className="form-control" value={taxable_social_security_tips} />
            </div>
            <div className="col-lg-4">
              <span style={{ fontWeight: "bold" }}>5c</span>Taxable Medicare
              wages & tips.
            </div>
            <div className="col-lg-6 d-flex justify-content-start mt-2">
              <input type="number" className="form-control" onChange={(e) => settaxable_medicare_wages_tip(+e.target.value)}/>
              <span style={{ fontSize: "16PX", width: "180PX" }}>
                × 0.029 =
              </span>
              <input type="number" className="form-control" value={taxable_medicare_wages_tips}/>
            </div>
            <div className="col-lg-4">
              <span style={{ fontWeight: "bold" }}>5d</span>Taxable wages & tips
              subject to Additional Medicare Tax withholding
            </div>
            <div className="col-lg-6 d-flex justify-content-start mt-2">
              <input type="number" className="form-control" onChange={(e) => settaxable_wages_tip(+e.target.value)}/>
              <span style={{ fontSize: "16PX", width: "180PX" }}>
                × 0.009 =
              </span>
              <input type="number" className="form-control" value={taxable_wages_tips} />
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          style={{ padding: "10px", border: "1px solid black" }}
        >
          <p>
            *Include taxable qualified sick and family leave wages paid in this
            quarter of 2023 for leave taken after March 31, 2021, and before
            October 1, 2021, on line 5a. Use lines 5a(i) and 5a(ii) only for
            taxable qualified sick and family leave wages paid in this quarter
            of 2023 for leave taken after March 31, 2020, and before April 1,
            2021.{" "}
          </p>
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>5e</span> Total social security
            and Medicare taxes. Add Column 2 from lines 5a, 5a(i), 5a(ii), 5b,
            5c, and 5d{" "}
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>5e</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_social_security_and_medicare_taxes(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>6</span> Total taxes before
            adjustments. Add lines 3, 5e, and 5f . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>6</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_taxes_before_adjustments(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>7</span>Current quarter’s
            adjustment for fractions of cents . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>7</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setcurrent_quarters_adjustment_for_fractions_of_cents(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>8</span>Current quarter’s
            adjustment for sick pay . . . . . . . . . . . . . . . ..
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>8</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setcurrent_quarters_adjustment_for_sick_pay(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>9</span>Current quarter’s
            adjustments for tips and group-term life insurance . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>9</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setcurrent_quarters_adjustments_for_tips_and_group_life_insurance(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>10</span>Total taxes after
            adjustments. Combine lines 6 through 9 . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>10</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_taxes_after_adjustments(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>11a</span>Qualified small
            business payroll tax credit for increasing research activities.
            Attach Form 8974
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11a</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_small_business_payroll(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>11b</span>Nonrefundable portion
            of credit for qualified sick and family leave wages for leave taken
            before April 1, 2021 . . . . . . . . . . . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11b</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setnonrefundable_portion_1(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold" }}>11c</span>Reserved for future
            use . . . . . . . . . . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11c</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_1(e.target.value)}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <p style={{ border: "1px solid black" }}>
            <span
              style={{
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              Part 1:
            </span>{" "}
            Answer these questions for this quarter. (continued)
          </p>
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>11d</span>Nonrefundable portion of credit for qualified sick and family leave wages for leave taken
after March 31, 2021, and before October 1, 2021 . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11d</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setnonrefundable_portion_2(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>11e</span>Reserved for future use . . . . . . . . . . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11e</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_2(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>11f</span>Reserved for future use . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11f</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_3(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>11g</span>Total nonrefundable credits. Add lines 11a, 11b, and 11d . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>11g</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_nonrefundable_credits(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>12</span>Total taxes after adjustments and nonrefundable credits. Subtract line 11g from line 10 . 
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>12</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_taxes_after_adjustments_and_nonrefundable_credits(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13a</span>Total deposits for this quarter, including overpayment 
            applied from a prior quarter and overpayments applied from Form 941-X, 941-X 
            (PR), 944-X, or 944-X (SP) filed in the current quarter
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13a</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_deposits_for_this_quarter(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13b</span>Reserved for future use 
            . . . . . . . . . . . . . . . . . . . . . .
          </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13b</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_4(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13c</span>Refundable portion of credit for qualified sick and family leave wages for leave taken
before April 1, 2021 . . . . . . . . . . . . . . . . . . . . . . .</p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13c</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setrefundable_portion_of_credit_1(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13d</span>Reserved for future use . . . . . . . . . . . . . . . . . . . . . . </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13d</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_5(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13e</span>Refundable portion of credit for qualified sick and family leave wages for leave taken
after March 31, 2021, and before October 1, 2021 . . . . . . . . . . . . . .</p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13e</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setrefundable_portion_of_credit_2(e.target.value)}
          />
        </div>
        {/* <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13e</span>Refundable portion of credit for qualified sick and family leave wages for leave taken
after March 31, 2021, and before October 1, 2021 . . . . . . . . . . . . . .</p>
        </div> */}

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13e</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setrefundable_portion_of_credit_2(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13f</span>Reserved for future use
             . . . . . . . . . . . . . . . . . . . . . .</p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13f</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_6(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13g</span>Total deposits and refundable credits. 
            Add lines 13a, 13c, and 13e . . . . . . . .</p>
        </div>

     <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13g</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => settotal_deposits_and_refundable_credits(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13h</span>Reserved for future use . . . . . . . . . . . . . . . . . . . . . .</p>
        </div>

     <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13h</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_7(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>13i</span>Reserved for future use . . . . . . . . . . . . . . . . . . . . . .</p>
        </div>

     <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>13i</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setreserved_for_future_use_8(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>14</span>Balance due. If line 12 is more than line 13g, enter the difference and see instructions . . .</p>
        </div>

     <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>14</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setbalance_due(e.target.value)}
          />
        </div>
        <div className="col-lg-4">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>15</span>Overpayment. If line 13g is more than line 12,
             enter the difference</p>
        </div>

     <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>15</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px", width:'300px' }}
            onChange={(e) => setoverpayment(e.target.value)}
          />
          <p style={{marginleft:'15px'}}>Check one:</p>
        </div>
        <div
              className="col-lg-2 d-flex justify-content-start"
              style={{  }}
            >
              <input
                type="checkbox"
                id="vehicle1"
                name="1"
                value="Bike"
                // onChange={(e) => settax_liability_check_1(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
              Apply to next return
              </label>
            </div>
            <div
              className="col-lg-2 d-flex justify-content-start"
              style={{  }}
            >
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                // onChange={(e) => settax_liability_check_2(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
              Send a refund
              </label>
            </div>
            <div className="col-lg-12 mt-2">
          <p style={{ border: "1px solid black" }}>
            <span
              style={{
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              Part 2:
            </span>{" "}
            Tell us about your deposit schedule and tax liability for this quarter.
          </p>
          <p style={{fontWeight:'bold'}}>If you’re unsure about whether you’re a monthly schedule depositor 
            or a semiweekly schedule depositor, see section 11 of Pub. 15.</p>
        </div>
        <div className="col-lg-12">
         <div className="row">
            <div className="col-lg-2 d-flex justify-content-start">
               <span style={{fontWeight:'bold', width:'26%',}}>16:</span>
            <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
            Check one
              </label>
            <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                style={{margnLeft:'5px', }}
                onChange={(e) => settax_liability_check_1(e.target.value)}
              />
              



            </div>
            <div className="col-lg-10">
               <p>Line 12 on this return is less than $2,500 or line 12 on the return for the prior quarter was less than $2,500,
and you didn’t incur a $100,000 next-day deposit obligation during the current quarter. If line 12 for the prior
quarter was less than $2,500 but line 12 on this return is $100,000 or more, you must provide a record of your
federal tax liability. If you’re a monthly schedule depositor, complete the deposit schedule below; if you’re a
semiweekly schedule depositor, attach Schedule B (Form 941). Go to Part 3.</p>
            </div>


         </div>
        </div>
        <div className="col-lg-12">
         <div className="row">
            <div className="col-lg-2 d-flex justify-content-start">
               <span style={{fontWeight:'bold', width:'26%',}}></span>
            <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
           
              </label>
            <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                style={{margnLeft:'5px', }}
                onChange={(e) => settax_liability_check_2(e.target.value)}
              />
              



            </div>
            <div className="col-lg-10">
               <p>You were a monthly schedule depositor for the entire quarter. Enter your tax liability for each month and total
liability for the quarter, then go to Part 3.</p>
            </div>


         </div>
        </div>
        <div className="col-lg-12">
         <div className="row">
            <div className="col-lg-2 d-flex justify-content-start">
               <span style={{fontWeight:'bold', width:'26%',}}></span>
            <label style={{ marginLeft: "5px", width:'80%',color:'black',fontWeight:'bold', }}>
            Tax liability:
              </label>
              {/* <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
              Month 1
              </label> */}
            



            </div>
            <div className="col-lg-10">
               <div className="col-lg-3  d-flex justify-content-start ">
       <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
              Month 1
              </label>
              <input type="text" className="form-control"
                 onChange={(e) => settax_liability_month_1(e.target.value)}
              />


               </div>
               <div className="col-lg-3  d-flex justify-content-start mt-2">
       <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
              Month 2
              </label>
              <input type="text" className="form-control"
                onChange={(e) => settax_liability_month_2(e.target.value)}/>


               </div>
               <div className="col-lg-3  d-flex justify-content-start mt-2">
       <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
              Month 3
              </label>
              <input type="text" className="form-control"
                onChange={(e) => settax_liability_month_3(e.target.value)}/>


               </div>
               <div className="col-lg-6  d-flex justify-content-start mt-2">
       <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
       Total liability for quarter
              </label>
              <input type="text" className="form-control"
              
              onChange={(e) => settotal_liability_for_quarter(e.target.value)}/>
              
              
              <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
              Total must equal line 12
              </label>


               </div>
              
            </div>


         </div>
        </div>
        <div className="col-lg-12">
         <div className="row">
            <div className="col-lg-2 d-flex justify-content-start">
               <span style={{fontWeight:'bold', width:'26%',}}></span>
            <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
           
              </label>
            <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                style={{margnLeft:'5px', }}
                onChange={(e) => setschedule_depositor(e.target.value)}
              />
              



            </div>
            <div className="col-lg-10 mt-2">
               <p>You were a semiweekly schedule depositor for any part of this quarter. Complete Schedule B (Form 941),
Report of Tax Liability for Semiweekly Schedule Depositors, and attach it to Form 941. Go to Part 3</p>
            </div>


         </div>
        </div>
        <div className="col-lg-12 mt-2">
          <p style={{ border: "1px solid black" }}>
            <span
              style={{
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              Part 3:
            </span>{" "}
            Tell us about your business. If a question does NOT apply to your business, leave it blank.
          </p>
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>17</span>If your business has closed
             or you stopped paying wages . . . . . . . . . . . . . . . </p>
        </div>

        <div
              className="col-lg-2 d-flex justify-content-start"
              style={{  }}
            >
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => setif_your_business_paying_wages(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
              Check here, and
              </label>
            </div>
            <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>18</span>If you’re a seasonal
             employer and you don’t have to file a return for every quarter of the year . . . . . . . . . . . . . . . . . . </p>
        </div>

        <div
              className="col-lg-2 d-flex justify-content-start"
              style={{  }}
            >
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => setseasonal_employer(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
              Check here
              </label>
            </div>
            <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>19</span>
            Qualified health plan expenses allocable to qualified sick leave wages for leave taken before April 1, 2021
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>19</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_health_plan_1(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>20</span>
            Qualified health plan expenses allocable to qualified family leave wages for leave taken before April 1, 2021
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>20</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_health_plan_2(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>21</span>
            Reserved for future use . . . . . . . . . . . . . . . . . . . . . .
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>21</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_health_plan_2(e.target.value)}
          />
        </div>
        {/* <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>22</span>
            Reserved for future use . . . . . . . . . . . . . . . . . . . . . .
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>22</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
          />
        </div> */}
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>23</span>
            Qualified sick leave wages for leave taken after March 31, 2021, and before October 1, 2021
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>23</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_sick_leave_wages_part3(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>24</span>
            Qualified health plan expenses allocable to qualified sick leave wages reported on line 23
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>24</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_health_plan_expenses_family(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>25</span>
            Amounts under certain collectively bargained agreements allocable to qualified sick
leave wages reported on line 23 . . . . . . . . . . . . . . . . . . .
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>25</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setamounts_under_certain_collectively(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>26</span>
            Qualified family leave wages for leave taken after March 31, 2021, and before October 1, 2021
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>26</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_family_leave_wages_part3(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>27</span>
            Qualified health plan expenses allocable to qualified family leave wages reported on line 26
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>27</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_health_plan_expenses_family(e.target.value)}
          />
        </div>
        <div className="col-lg-8">
          <p>
            <span style={{ fontWeight: "bold", marginRight:'5px' }}>28</span>
            Amounts under certain collectively bargained agreements allocable to qualified family
leave wages reported on line 26 . . . . . . . . . . . . . . . . . . . 
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          <span style={{ fontWeight: "bold" }}>28</span>
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setqualified_family(e.target.value)}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <p style={{ border: "1px solid black" }}>
            <span
              style={{
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              Part 4:
            </span>{" "}
            May we speak with your third-party designee?
          </p>
        </div>
        <div className="col-lg-12">
        <p>Do you want to allow an employee, a paid tax preparer, or another person to discuss this return with the IRS? See the instructions
for details.</p>

        </div>
        <div className="col-lg-12">
         <div className="row">
            <div className="col-lg-4 d-flex justify-content-start">
               
            
            <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                style={{margnLeft:'5px', }}
                onChange={(e) => setyes(e.target.value)}
              />
              <label style={{ marginLeft: "5px", width:'50%',color:'black',fontWeight:'bold', }}>
            Yes. Designee’s name and phone number
           
              </label>
              



            </div>
            <div className="col-lg-4 mt-2">
              <input type="text" className="form-control"  onChange={(e) => setdesignee_name(e.target.value)}/>
            </div>
            <div className="col-lg-4 mt-2">
              <input type="text" className="form-control" onChange={(e) => setphone_number(e.target.value)}/>
            </div>


         </div>
        </div>
        <div className="col-lg-8 mt-2">
          <p>
            {/* <span style={{ fontWeight: "bold", marginRight:'5px' }}>28</span> */}
            Select a 5-digit personal identification number (PIN) to use when talking to the IRS.
            </p>
        </div>

        <div className="col-lg-4 d-flex justify-content-start mt-2">
          {/* <span style={{ fontWeight: "bold" }}>28</span> */}
          <input
            type="number"
            className="form-control"
            style={{ marginLeft: "5px" }}
            onChange={(e) => setpersonal_identification_number(e.target.value)}
          />
        </div>
        <div
              className="col-lg-12 d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => setno(e.target.value)}
              />
              <label style={{ marginLeft: "5px" }}>
              No.
              </label>
            </div>
            <div className="col-lg-12 mt-2">
          <p style={{ border: "1px solid black" }}>
            <span
              style={{
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
              }}
            >
              Part 5:
            </span>{" "}
            Sign here. You MUST complete all three pages of Form 941 and SIGN it.
          </p>
        </div>
        <div className="col-lg-12">
         <p>
         Under penalties of perjury, I declare that I have examined this return, including accompanying schedules and statements, and to the best of my knowledge
and belief, it is true, correct, and complete. Declaration of preparer (other than taxpayer) is based on all information of which preparer has any knowledge.
         </p>
        </div>
        <div className="col-lg-6 d-flex justify-content-start">
         <p style={{fontWeight:'bold', fontSize:'20px'}}>Sign your name here</p>
         <textarea id="w3review" className="form-control"  style={{height:'100px', marginLeft:'10px', width:'400px'}}
         
         onChange={(e) => setsign_your_name_here(e.target.value)}
         >

</textarea>


        </div>
        <div className="col-lg-6 ">
        <label>Print your name here</label>
        <input type="text" className="form-control"
         
         onChange={(e) => setprint_your_name_here(e.target.value)}
        />
        <label>Print your title here</label>
        <input type="text" className="form-control"
        onChange={(e) => setprint_your_title_here(e.target.value)}
        />



        </div>
        <div className="col-lg-6" style={{padding:'40px'}}>
      <label>Date</label>
      <input type="date" className="form-control"
      onChange={(e) => setdate(e.target.value)}
      
      />


        </div>
        <div className="col-lg-6" style={{padding:'40px'}}>
      <label>Best daytime phone</label>
      <input type="text" className="form-control"
      onChange={(e) => setbest_daytime_phone(e.target.value)}
      
      
      />


        </div>
        <hr/>
        <div className="col-lg-12">
         <div className="row">
        <div className="col-lg-6">
      <h4>Paid Preparer Use Only</h4>
      


        </div>
        <div
              className="col-lg-6 d-flex justify-content-start"
              
            >
               <label style={{ marginLeft: "5px" }}>
               Check if you’re self-employed . . .
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
              />
              
            </div>
            <div className="col-lg-6">
               <label>Preparer’s name</label>
               <input type="text" className="form-control"
               onChange={(e) => setpreparers_name(e.target.value)}
               
               />



            </div>
            <div className="col-lg-6">
               <label>PIN</label>
               <input type="text" className="form-control"
               
               onChange={(e) => setpttn(e.target.value)}/>



            </div>
            <div className="col-lg-6">
               <label>Preparer’s signature</label>
               <input type="text" className="form-control"
                  onChange={(e) => setpreparers_signature(e.target.value)}
               
               />



            </div>
            <div className="col-lg-6">
               <label>Date</label>
               <input type="date" className="form-control"
               onChange={(e) => setpreparer_date(e.target.value)}
               />



            </div>
            <div className="col-lg-6">
               <label>Firm’s name (or yours
if self-employed)</label>
               <input type="text" className="form-control"
               
               onChange={(e) => setfirm_name(e.target.value)}
               />



            </div>
            <div className="col-lg-6">
               <label>EIN</label>
               <input type="text" className="form-control"
                 onChange={(e) => setpreparer_ein(e.target.value)}
               />



            </div>
            <div className="col-lg-6">
               <label>Address</label>
               <input type="text" className="form-control"
                 onChange={(e) => setpreparer_address(e.target.value)}
               
               />



            </div>
            <div className="col-lg-6">
               <label>Phone</label>
               <input type="number" className="form-control"
               onChange={(e) => setpreparer_phone(e.target.value)}
               
               />



            </div>
            <div className="col-lg-4">
               <label>City</label>
               <input type="text" className="form-control"
               
               onChange={(e) => setpreparer_city(e.target.value)}
               />
            </div>
            {/* <div className="col-lg-4">
               <label>State</label>
               <input type="text" className="form-control"
                   onChange={(e) => setpreparer_city(e.target.value)}
               />
            </div> */}
            <div className="col-lg-4">
               <label>Zip code</label>
               <input type="text" className="form-control"
               onChange={(e) => setpreparer_zip_code(e.target.value)}
               
               />
            </div>
            </div>
        </div>
        <div className="col-lg-12" style={{marginTop:'20px'}}>
         <h4>
         Form 941-V,
Payment Voucher
         </h4>
        </div>
        <div className="col-lg-6">
         <h5>Purpose of Form</h5>
         <p>Complete Form 941-V if you’re making a payment with
Form 941. We will use the completed voucher to credit
your payment more promptly and accurately, and to
improve our service to you.</p>
<h5>Making Payments With Form 941</h5>
         <p>To avoid a penalty, make your payment with Form 941
only if:
• Your total taxes after adjustments and nonrefundable
credits (Form 941, line 12) for either the current quarter or
the preceding quarter are less than $2,500, you didn’t
incur a $100,000 next-day deposit obligation during the
current quarter, and you’re paying in full with a timely filed
return; or
• You’re a monthly schedule depositor making a
payment in accordance with the Accuracy of Deposits
Rule. See section 11 of Pub. 15 for details. In this case,
the amount of your payment may be $2,500 or more.
Otherwise, you must make deposits by electronic funds
transfer. See section 11 of Pub. 15 for deposit
instructions. Don’t use Form 941-V to make federal tax
deposits.
▲! CAUTION
Use Form 941-V when making any payment with
Form 941. However, if you pay an amount with
Form 941 that should’ve been deposited, you
may be subject to a penalty. See Deposit Penalties in
section 11 of Pub. 15.
</p>
        </div>
        <div className="col-lg-6">
         <h5>Specific Instructions</h5>
         <p>Box 1—Employer identification number (EIN). If you
don’t have an EIN, you may apply for one online by
visiting the IRS website at www.irs.gov/EIN. You may also
apply for an EIN by faxing or mailing Form SS-4 to the
IRS. If you haven’t received your EIN by the due date of
Form 941, write “Applied For” and the date you applied in
this entry space.
Box 2—Amount paid. Enter the amount paid with
Form 941.
Box 3—Tax period. Darken the circle identifying the
quarter for which the payment is made. Darken only
one circle.
Box 4—Name and address. Enter your name and
address as shown on Form 941.
• Enclose your check or money order made payable to
“United States Treasury.” Be sure to enter your
EIN, “Form 941,” and the tax period (“1st Quarter 2023,”
“2nd Quarter 2023,” “3rd Quarter 2023,” or “4th Quarter
2023”) on your check or money order. Don’t send cash.
Don’t staple Form 941-V or your payment to Form 941 (or
to each other).
• Detach Form 941-V and send it with your payment
and Form 941 to the address in the Instructions for
Form 941.
<br/>
<b>Note:</b> You must also complet
</p>
        </div>
        <div className="col-lg-12">
         <div className="col-lg-12">
         <h5 style={{textAlign:'center'}}>Detach Here and Mail With Your Payment and Form 941</h5>
      </div>
        </div>
        <div className="col-lg-12" >
        <div className="row">
         <div className="col-lg-2" style={{border:'1px solid black'}}>
            <h3>941-V</h3>
            <p>Department of the Treasury
Internal Revenue Service </p>
         </div>
         <div className="col-lg-8 " style={{border:'1px solid black'}}>
            <h4 style={{textAlign:'center', marginTop:'30px'}}>Payment Voucher</h4>


         </div>
         <div className="col-lg-2" style={{border:'1px solid black'}}>
            <p>OMB No. 1545-0029</p>
            <h3>2023</h3>
         </div>
         </div>
         </div>
         <div className="col-lg-12">
            <div className="row">
               <div className="col-lg-2" style={{border:"1px solid black"}}>
                  <span>
                 1, Enter your employer identification
                   number (EIN).

                  </span>
                  <input type="number" className="form-control" onChange={(e) => setemployer_identification_number_payment(e.target.value)}   />
               </div>
               <div className="col-lg-6" style={{border:"1px solid black"}}>
                  <p>1<h6>
                  Enter the amount of your payment.</h6></p>
                  <p>Make your check or money order payable to “United States Treasury.”</p>
               </div>
               <div className="col-lg-2" style={{border:"1px solid black"}}>
                  <h6>
                  Dollars
                  </h6>
                  <input type="number" className="form-control"
                  onChange={(e) => setamount_in_dollars(e.target.value)}  
                  />
                
               </div>
               <div className="col-lg-2" style={{border:"1px solid black"}}>
                  <h6>
                  Cents
                  </h6>
                  <input type="number" className="form-control"
                   onChange={(e) => setamount_in_cents(e.target.value)}  
                  />
                
               </div>
            </div>
         </div>
         <div className="col-lg-12">
        <div className="row">
         <div className="col-lg-4" style={{border:"1px solid black"}}>
            <p><h6>3</h6> Tax Period</p>
            <hr/>
            <div className="row">
               <div className="col-lg-2" style={{border:"1px solid black"}}>
               <input type="radio" id="html" name="fav_language" value="1" 
                 onChange={(e) => settax_period(e.target.value)}  
               />
<label for="html">1st
Quarter</label>
               </div>
               <div className="col-lg-2" style={{border:"1px solid black"}}>
               <input type="radio" id="html" name="fav_language" value="2"
               onChange={(e) => settax_period(e.target.value)}  
               />
<label for="html">3rd
Quarter</label>
               </div>
               <div className="col-lg-2" style={{border:"1px solid black"}}>
               <input type="radio" id="html" name="fav_language" value="3"
               onChange={(e) => settax_period(e.target.value)}  />
<label for="html">2nd 

Quarter</label>
               </div>
               <div className="col-lg-2" style={{border:"1px solid black"}}>
               <input type="radio" id="html" name="fav_language" value="4"
               
               onChange={(e) => settax_period(e.target.value)}  
               />
<label for="html">4th
Quarter</label>
               </div>
            </div>

         </div>
         <div className="col-lg-8" style={{border:"1px solid black"}}>
         <p><h6>4</h6> 4 Enter your business name (individual name if sole proprietor)</p>
         <input type="number" className="form-control"
         onChange={(e) => setbusiness_name(e.target.value)}  
         
         />
         <label> Enter your address</label>
         <input type="number" className="form-control"
           onChange={(e) => setyour_address(e.target.value)}  
         />
         <label>Enter your city, state, and ZIP code; or your city, foreign country name, foreign province/county, and foreign postal code. </label>
         <input type="number" className="form-control"
          onChange={(e) => setdetails(e.target.value)}  
         
         />
         </div>
        </div>


         </div>
         <div className="col-lg-12 d-flex justify-content-end mt-2">
         <button type="button" class="btn btn-primary  " onClick={onsubmits}>Submit
         
         {loder !== false? 
                        <Spinner animation="grow" size="sm"  />:null
                     }
         </button>
         </div>

        </div>
      </div>
    
  );
};

export default Form941;
