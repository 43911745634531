import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import axios from "axios";
import BaseUrl from "../../../BaseUrl";
import { TailSpin } from "react-loader-spinner";
import Swal from "sweetalert2";

const Signup = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [workEmail, setWorkEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");

  const signUp = () => {
    setLoader(true);
    const formData = new FormData();

    formData.append("work_email", workEmail);
    formData.append("email", confirmEmail);
    formData.append("password", password);

    axios
      .post(`${BaseUrl.baseUrl}customer/register`, formData)
      .then((response) => {
        setLoader(false);
        Swal.fire("Good job!", "Your Account has been created!", "success");
        console.log("response ==>", response);
        if (response?.data?.message === "Customer Successfully Registered") {
          navigate("/users/" + response?.data?.customer_id);
          console.log("aa raha he ==>", response?.data?.customer_id);
        }
      })
      .catch((error) => {
        console.log("error ==>", error);
        // if (
        //   error?.message ===
        //   "Request failed with status code 422"
        // ) {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Something went wrong!",
        //   });
        // }
        if (
          error?.response?.data?.message ===
          "Email and Confirm Email Must be same"
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Work Email and Confirm Email Must be same!",
          });
        }else if(error?.response?.data?.message === "The Email is already taken"){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Email is already taken",
          });
        }
        setLoader(false);
      });
  };

  return (
    <>
      <section className="vh-100 align-items-center justify-content-center d-flex login_bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col box_shadow p-5 signup_width">
              <h2 className="mb-4 text-light">Signup for Tax for Free</h2>

              {/* <Form>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label className="d-block width-fit text-light">
                        Work Email
                      </Form.Label>
                      <Form.Control type="email" placeholder="Work Email" />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label className="d-block width-fit text-light">
                        Confirm Email
                      </Form.Label>
                      <Form.Control type="email" placeholder="Confirm Email" />
                    </Form.Group>
                  </div>
                </div>

                <Form.Group className="mb-4" controlId="formBasicPassword">
                  <Form.Label className="d-block width-fit text-light">
                    Password
                  </Form.Label>
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <Form.Check
                  className="width-fit text-light"
                  type={"checkbox"}
                  id={`api`}
                  label={`Enable API Access`}
                />
                <Form.Check
                  className="width-fit text-light"
                  type={"checkbox"}
                  id={`terms`}
                  label={`I Accept Terms & Conditions`}
                />
                <Form.Check
                  className="width-fit text-light"
                  type={"checkbox"}
                  id={`communications`}
                  label={`Receive Compliance Updates and Marketing Communications.`}
                />
                <span className="text-light width-fit d-block me-auto">
                  Already have an account?{" "}
                  <Link className="text-dark fw-bold" to="/login">
                    Login
                  </Link>
                </span>
                <Form.Group
                  className="mb-4"
                  controlId="formBasicCheckbox"
                ></Form.Group>
                <Button variant="primary" type="submit" className="d-block">
                  Submit
                </Button>
              </Form> */}

              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label className="d-block width-fit text-light">
                      Work Email
                    </label>
                    <Form.Item
                      className="mb-2"
                      name="workemail"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Work Email!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setWorkEmail(e.target.value)}
                        className="py-2"
                        placeholder="Work Email"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <label className="d-block width-fit text-light">
                      Confirm Email
                    </label>
                    <Form.Item
                      className="mb-2"
                      name="confirmemail"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Confirm Email!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        className="py-2"
                        placeholder="Confirm Email"
                      />
                    </Form.Item>
                  </div>
                </div>

                <label className="d-block width-fit text-light">Password</label>
                <Form.Item
                  className="mb-2"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </Form.Item>

                <span className="text-light width-fit d-block mb-2">
                  Already have an account? &nbsp;{" "}
                  <Link to="/login" className="text-light fw-bold">
                    Login
                  </Link>
                </span>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="width-fit mb-1"
                >
                  <Checkbox className="text-light">Enable API Access</Checkbox>
                </Form.Item>
                <Form.Item
                  name="accept"
                  valuePropName="checked"
                  className="width-fit mb-1"
                >
                  <Checkbox className="text-light">
                    I Accept Terms & Conditions
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="receive"
                  valuePropName="checked"
                  className="width-fit mb-1"
                >
                  <Checkbox className="text-light">
                    Receive Compliance Updates and Marketing Communications.
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="d-flex align-items-center me-auto"
                    onClick={signUp}
                  >
                    Sign Up&nbsp;
                    {loader ? (
                      <TailSpin
                        height="20"
                        width="20"
                        color="#fff"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : null}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
