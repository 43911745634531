import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Button, Checkbox, Form, Input, Radio } from "antd";
import Header2 from "../Layout/Header2";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../../BaseUrl";
import Swal from "sweetalert2";

const Register = () => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const { id } = useParams();

  //   for checkbox

  //   const onChange = (checkedValues) => {
  //     console.log("checked = ", checkedValues);
  //   };
  //   const options = [
  //     {
  //       label: "Quickbooks Desktop",
  //       value: "Quickbooks Desktop",
  //     },
  //     {
  //       label: "SAP",
  //       value: "SAP",
  //     },
  //     {
  //       label: "Thomson Reuters",
  //       value: "Thomson Reuters",
  //     },
  //     {
  //       label: "Quickbooks Pro/Premier",
  //       value: "Quickbooks Pro/Premier",
  //     },
  //     {
  //       label: "Sage Intacct",
  //       value: "Sage Intacct",
  //     },
  //     {
  //       label: "Entrata",
  //       value: "Entrata",
  //     },
  //     {
  //       label: "Quickbooks Enterprise",
  //       value: "Quickbooks Enterprise",
  //     },
  //     {
  //       label: "Sage (200/300/ERP)",
  //       value: "Sage (200/300/ERP)",
  //     },
  //     {
  //       label: "Yardi",
  //       value: "Yardi",
  //     },
  //     {
  //       label: "Quickbooks Enterprise",
  //       value: "Quickbooks Enterprise",
  //     },
  //     {
  //       label: "Sage (200/300/ERP)",
  //       value: "Sage (200/300/ERP)",
  //     },
  //     {
  //       label: "Yardi",
  //       value: "Yardi",
  //     },
  //   ];

  // const onChange = (e) => {
  //   console.log(`checked = ${e.target.checked}`);
  //   console.log(`checked = ${e.target.value}`);
  // };

  //   for button large

  const [size] = useState("large");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [number, setNumber] = useState("");
  const [iAm, setIAm] = useState("");
  const [eFiling, setEFiling] = useState("");
  const [accountingSoftware, setAccountingSoftware] = useState([]);

  const handleChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;
    if (checked) {
      setAccountingSoftware([...accountingSoftware, value]);
    } else {
      setAccountingSoftware(accountingSoftware.filter((e) => e !== value));
    }
  };

  //   for radio

  const radio1 = (e) => {
    console.log("radio checked", e.target.value);
    setIAm(e.target.value);
  };
  const radio2 = (e) => {
    console.log("radio checked", e.target.value);
    setEFiling(e.target.value);
  };

  const register = () => {
    const formData = new FormData();

    formData.append("customer_id", id);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("company_name", companyName);
    formData.append("contact_no", number);
    formData.append("Iam_with_a", iAm);
    formData.append("how_many_forms", eFiling);
    formData.append("accounting_software", accountingSoftware);

    axios
      .post(`${BaseUrl.baseUrl}customer/details`, formData)
      .then((response) => {
        console.log("response ==>", response);
        navigate("/welcome");
      })
      .catch((error) => {
        console.log("error ==>", error);
        if(error?.message === "Request failed with status code 422"){
          Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
        }
      });
  };

  return (
    <>
      <Header2 />
     
      <section className="align-items-center justify-content-center d-flex mt-3">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col box_shadow p-5">
              <h2 className="mb-4 text-start">Business Info</h2>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label className="d-block width-fit fs-5">First Name <span className="text-danger">*</span></label>
                    <Form.Item
                      className="mb-2"
                      name="fname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setFirstName(e.target.value)}
                        className="py-2"
                        placeholder="First Name"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <label className="d-block width-fit fs-5">Last Name <span className="text-danger">*</span></label>
                    <Form.Item
                      className="mb-2"
                      name="lname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setLastName(e.target.value)}
                        className="py-2"
                        placeholder="Last Name"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="d-block width-fit fs-5">
                      Company Name <span className="text-danger">*</span>
                    </label>
                    <Form.Item
                      className="mb-2"
                      name="cname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Company Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="py-2"
                        placeholder="Company Name"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <label className="d-block width-fit fs-5">
                      Contact Number <span className="text-danger">*</span>
                    </label>
                    <Form.Item
                      className="mb-2"
                      name="cnumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Contact Number!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setNumber(e.target.value)}
                        type="number"
                        className="py-2"
                        placeholder="Contact Number"
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* I am with a */}

                <div className="row mt-4">
                  <div className="col text-start">
                    <h4 className=" text-start">
                      I am with a <span className="text-danger">*</span>
                    </h4>
                    <Radio.Group onChange={radio1} value={iAm} className="my-1">
                      <Radio className="fs-5" value={"Small & Mid Business"}>
                        Small & Mid Business
                      </Radio>
                      <Radio className="fs-5" value={"Accounting Firm"}>
                        Accounting Firm
                      </Radio>
                      <Radio
                        className="fs-5"
                        value={"Crypto / Fin Tech / Gig platform"}
                      >
                        Crypto / Fin Tech / Gig platform
                      </Radio>
                      <Radio className="fs-5" value={"Large Enterprize"}>
                        Large Enterprize
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>

                {/* How many forms you will be eFiling */}

                <div className="row mt-4">
                  <div className="col text-start">
                    <h4 className=" text-start">
                      How many forms you will be eFiling
                      <span className="text-danger">*</span>
                    </h4>
                    <Radio.Group
                      onChange={radio2}
                      value={eFiling}
                      className="my-1"
                    >
                      <Radio className="fs-5" value={"0 to 100"}>
                        0 to 100
                      </Radio>
                      <Radio className="fs-5" value={"101 to 500"}>
                        101 to 500
                      </Radio>
                      <Radio className="fs-5" value={"501 to 1000"}>
                        501 to 1000
                      </Radio>
                      <Radio className="fs-5" value={"1001 to 10000"}>
                        1001 to 10000
                      </Radio>
                      <Radio className="fs-5" value={"10,001+"}>
                        10,001+
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>

                {/* Which accounting software do you use */}

                <div className="row mt-4">
                  <div className="col text-start">
                    <h4 className=" text-start">
                      Which Accounting Software do you use
                      <span className="text-danger">*</span>
                    </h4>

                    {/* <Checkbox.Group
                        style={{display: "block"}}
                      options={options}
                    //
                       defaultValue={["Pear"]}
                      onChange={handleChange}
                    /> */}
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-4">
                        <div>
                          <Checkbox
                            value="Quickbooks Desktop"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Quickbooks Desktop
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Quickbooks Pro/Premier"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Quickbooks Pro/Premier
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Quickbooks Enterprise"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Quickbooks Enterprise
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value=" Quickbooks for MAC"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Quickbooks for MAC
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Quicken"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Quicken
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Xero"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Xero
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Intacct"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Intacct
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Netsuite"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Netsuite
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="MS Dynamics"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            MS Dynamics
                          </Checkbox>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <Checkbox
                            value="SAP"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            SAP
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Sage Intacct"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Sage Intacct
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Sage (200/300/ERP)"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Sage (200/300/ERP)
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Sage 50"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Sage 50
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Sage 100"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Sage 100
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Freshbooks"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Freshbooks
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Zoho"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Zoho
                          </Checkbox>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <Checkbox
                            value="Thomson Reuters"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Thomson Reuters
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Entrata"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Entrata
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Yardi"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Yardi
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Traction Ag"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Traction Ag
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value="Other"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            Other
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value=" None"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            None
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            value=" I don’t know"
                            onChange={handleChange}
                            className="fs-5"
                          >
                            I don’t know
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Form.Item className="mt-3">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="d-block ms-auto"
                    size={size}
                    onClick={register}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
