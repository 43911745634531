import React from "react";
import Header from "./Layout/Header";
import Logo from "../assets/images/logo/logo-light.png";
import PriceTab from "./PricingTab/PriceTab";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Footer from "./Layout/Footer";

const Pricing = () => {
  return (
    <>
      <Header Logo={Logo} style={{ color: "white" }} invert={"invert(100)"} />
      <section>
        <div className="price_bg">
          <div className="w-100 ms-md-5 pt-5 padding-left">
            <h1 className="text-white text-center text-md-start fs-65">
              Pricing Plan
            </h1>
            {/* <p className="h5 text-white text-center text-md-start mt-3">
              Price comparison of Editute Variants
            </p> */}
          </div>
          <div
            className="absolute inset-0 bg-primary mix-blend-multiply"
            aria-hidden="true"
          ></div>
        </div>
      </section>
      <PriceTab />

      <div className="container mb-4">
        <div className="row">
          <div className="col">
            <h2 className="text-danger text-start">Tiered Pricing Charts</h2>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  IRS eFile for 1099, 1098, W-2/C, 5498, 1095-B/C, 3921/2 ,
                  1099-OID, 1097-BTC, W-2G, 592-B
                </Accordion.Header>
                <Accordion.Body>
                  <div class="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                        <tr style={{ fontWeight: "bold" }}>
                          <th className="text-primary"># of forms</th>
                          <td>First 10 forms</td>
                          <td>Forms 11-150</td>
                          <td>Forms 151-500 </td>
                          <td>Forms 501-1000 </td>
                          <td>1000+</td>
                        </tr>
                        <tr>
                          <th className="text-primary">Pricing tier</th>
                          <td>$2.99/form</td>
                          <td>$1.99/form</td>
                          <td>$1.19/form</td>
                          <td>$0.69/form</td>
                          <td>
                            <Link to="#" className="a-contact">
                              Contact us!
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th className="text-primary" style={{ width: "23%" }}>
                            Online Form Delivery
                          </th>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>eFile for 1042-S, 1042</Accordion.Header>
                <Accordion.Body>
                  <div className="table-responsive">
                    <table className="table table-bordered table-responsive">
                      <tbody>
                        <tr style={{ fontWeight: "bold" }}>
                          <th width="17.5%"># of forms</th>
                          <td width="17%">First 100 forms</td>
                          <td width="17%">Next 900 forms</td>
                          <td>1000+</td>
                        </tr>
                        <tr>
                          <th>Pricing tier</th>
                          <td>$5.00/form</td>
                          <td>$3.50/form</td>
                          <td>$1.99/form</td>
                        </tr>
                        <tr>
                          <th style={{ width: "23%" }}>Online Form Delivery</th>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  eFile for 480.6A, 480.6B, 480.7A
                </Accordion.Header>
                <Accordion.Body>
                  <div className="table-responsive">
                    <table className="table table-bordered table-responsive">
                      <tbody>
                        <tr style={{ fontWeight: "bold" }}>
                          <th width="17.5%"># of forms</th>
                          <td width="17%">First 100 forms</td>
                          <td width="17%">Next 900 forms</td>
                          <td>1000+</td>
                        </tr>
                        <tr>
                          <th>Pricing tier</th>
                          <td>$5.00/form</td>
                          <td>$3.50/form</td>
                          <td>
                            <Link to="#" className="a-contact">
                              Contact us!
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "23%" }}>Online Form Delivery</th>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                          <td>$0.25/form</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>









      <Footer/>
    </>
  );
};

export default Pricing;
