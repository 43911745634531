import React from "react";
import { Link } from "react-router-dom";
import Hero1 from "../assets/images/hero/hero-one-img-1.jpg";
import Shape1 from "../assets/images/shape/shape-1.png";
import Shape2 from "../assets/images/shape/shape-2.png";
import Shape3 from "../assets/images/shape/shape-3.png";
import About1 from "../assets/images/about/about-1.jpg";
import Logo2 from "../assets/images/shape/circle-logo-2.png";
import Cta1 from "../assets/images/cta/img-1.jpg";
import Test3 from "../assets/images/testimonial/img-3.jpg";
import Test1 from "../assets/images/testimonial/img-1.jpg";
import Team1 from "../assets/images/team/img-1.jpg";
import Team2 from "../assets/images/team/img-2.jpg";
import Team3 from "../assets/images/team/img-3.jpg";
import Team4 from "../assets/images/team/img-4.jpg";
import Header from "./Layout/Header";
import Logo from "../assets/images/logo/logo-dark.png";
import Footer from "./Layout/Footer";
import "../assets/css/default.css";
import "../assets/css/responsive.css";
import "../assets/css/style.css";
import taximg from "../assets/images/tax_reports.png";
import w2forms from "../assets/images/w2_forms.png";
import w9 from "../assets/images/w9.png";
import report from "../assets/images/aca_reports.png";
import misc from "../assets/images/MISC.png";
import print from "../assets/images/print.png";
import monitor from "../assets/images/monitor.png";
import click from "../assets/images/click.png";
import email from "../assets/images/email.png";

const Home = () => {
  return (
    <>
      <Header Logo={Logo} style={{ color: "black" }} />
      <section className="hero-banner-v1 position-relative">
        <div className="bg-one" />
        <div className="bg-two" />
        <div className="hero-img hero-img-one scene">
          <span data-depth=".5">
            <img src={Hero1} className="wow fadeInLeft" alt="hero imag" />
          </span>
        </div>
        {/* <div className="hero-img hero-img-two scene">
          <span data-depth=".2">
            <img src={Hero2} className="wow fadeInLeft" alt="hero imag" />
          </span>
        </div>
        <div className="hero-img hero-img-three scene">
          <span data-depth=".3">
            <img src={Logo1} className="wow fadeInLeft" alt="hero imag" />
          </span>
        </div> */}
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src={Shape1} alt="shape" />
          </span>
        </div>
        <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src={Shape2} alt="shape" />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src={Shape3} alt="shape" />
          </span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="hero-content">
                <h1 className="wow fadeInUp" data-wow-delay=".5s">
                  Award-Wining &amp; IRS-Authorized eFiling Platform
                </h1>
                <p className="wow fadeInDown" data-wow-delay="1s">
                  Easily create, verify, and file your 1099 MISC forms and more
                  with the Federal & State compliant eFiling platform - Tax1099.
                  Trusted by 150,000 businesses. Join us today!
                </p>
                <Link
                  to="/signup"
                  className="main-btn arrow-btn wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  Sign Up For Free
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="hero-content">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0LhBvp8qpro"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services section start */}
      <section className="counter-area counter-area-v1 pt-240 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <span className="sub-title st-one">Services</span>
                {/* <h2>Why People’s Like Us</h2> */}
                <p>The Best eFiling Services for Businesses</p>
              </div>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  {/* <i className="flaticon-start-up" /> */}
                  <img alt="Tax Img" src={taximg} />
                </div>
                <div className="text">
                  <h4 className="number">1099 Series Tax Reporting</h4>
                  <p>
                    File IRS forms, such as 1099, W2, ACA, and more online.
                    Choose eFiling to eliminate complex procedures and save time
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  {/* <i className="flaticon-creativity" /> */}
                  <img alt="W2 Img" src={w2forms} />
                </div>
                <div className="text">
                  <h4 className="number">94X and W-2 Forms</h4>
                  <p>
                    Easily file your payroll forms at the end of each quarter
                    and financial year
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="icon">
                  {/* <i className="flaticon-medal" /> */}
                  <img alt="W2 Img" src={report} />
                </div>
                <div className="text">
                  <h4 className="number">W-9 and TIN Match</h4>
                  <p>
                    Access W-9 forms, update vendor records and validate TIN
                    Match using the IRS database
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  {/* <i className="flaticon-start-up" /> */}
                  <img alt="Tax Img" src={misc} />
                </div>
                <div className="text">
                  <h4 className="number">ACA Reporting</h4>
                  <p>
                    Use our easy-to-import bulk data feature to process
                    thousands of forms at once
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  {/* <i className="flaticon-creativity" /> */}
                  <img alt="W2 Img" src={print} />
                </div>
                <div className="text">
                  <h4 className="number">All 50 States 1099-MISC Filing</h4>
                  <p>
                    Apart from enabling combined federal/state filing, we also
                    help you comply with all the states that require a 1099-MISC
                    filing
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="icon">
                  {/* <i className="flaticon-medal" /> */}
                  <img alt="W2 Img" src={w9} />
                </div>
                <div className="text">
                  <h4 className="number">Print and Mail Forms</h4>
                  <p>Print and mail forms at the click of a button</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services section end */}
      <section className="about-area about-area-v1 position-relative pt-130">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img-holder-box mb-50">
                <div className="img-holder wow fadeInLeft">
                  <img src={About1} alt="Img" />
                </div>
                <div className="shape shape-one">
                  <span className="animate-float-y">
                    <img
                      src={Logo2}
                      className="circle-logo"
                      alt="circle logo"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fadeInRight">
                <div className="section-title mb-15">
                  <span className="sub-title st-one">About Us</span>
                  <h2>We’re Creative Agency Since 1993</h2>
                </div>
                <h4>Professional Design Agency to provide solutions</h4>
                <p>
                  On the other hand denounce with righteous and dislike men who
                  beguile and demoralizes by the charms of pleasure thes moment,
                  so blinded by desire that they cannot
                </p>
                <ul className="list-style-one mb-35">
                  <li>Digital Creative Agency</li>
                  <li>Professional Problem Solutions</li>
                  <li>Web Design &amp; Development</li>
                </ul>
                <Link
                  to="#"
                  className="main-btn bordered-btn btn-blue arrow-btn"
                >
                  Learn More Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-area pt-90 pb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-55 wow fadeInUp">
                {/* <span className="sub-title st-one">Services</span> */}
                <p>Simplified eFiling with Tax1099</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fadeInDown"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  <i className="flaticon-strategy" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="service-details.html">One-Point Access</Link>
                  </h3>
                  {/* <ul className="list-style-two">
                      <li>Product Management</li>
                      <li>MVP Definition</li>
                      <li>Product Strategy</li>
                    </ul> */}
                  <p>
                    Access all your payers or companies in one place. File any
                    type of form for any payer from your account. Simply create
                    multiple payer profiles, or import your data to get started
                  </p>
                  {/* <Link to="service-details.html" className="btn-link">
                      Read More
                    </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fadeInDown"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  <i className="flaticon-strategy" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="service-details.html">Notice Management</Link>
                  </h3>
                  {/* <ul className="list-style-two">
                      <li>Product Management</li>
                      <li>MVP Definition</li>
                      <li>Product Strategy</li>
                    </ul> */}
                  <p>
                    Avoid time-consuming compliance procedures with our guided
                    notice management tool. The in-built notice management
                    dashboard allows you to organize your communications with
                    the IRS and other involved parties.
                  </p>
                  {/* <Link to="service-details.html" className="btn-link">
                      Read More
                    </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fadeInDown"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  <i className="flaticon-strategy" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="service-details.html">
                      Security & Authentication
                    </Link>
                  </h3>
                  {/* <ul className="list-style-two">
                      <li>Product Management</li>
                      <li>MVP Definition</li>
                      <li>Product Strategy</li>
                    </ul> */}
                  <p>
                    Protect confidential information with our 256-bit bank-grade
                    security features. Disable unauthorized access to your data
                    with two-factor authentication
                  </p>
                  {/* <Link to="service-details.html" className="btn-link">
                      Read More
                    </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* pricing section start */}

      {/* <section className="pricing-area pricing-area-v1">
          <div className="container-1350">
            <div className="pricing-wrapper pt-75 pb-70">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="section-title text-center mb-55">
                    <h2>Pricing Package</h2>
                    <p>Professional Design Agency to provide solutions</p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="pricing-item pricing-item-one mb-40 wow fadeInUp"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="pricing-head text-center">
                      <span className="plan">Basic Plan</span>
                      <h2 className="price">
                        <span className="currency">$</span>16.95
                      </h2>
                    </div>
                    <div className="pricing-body">
                      <p>Sit amet consectetur adiising seddo eiusmod teme</p>
                      <ul className="pricing-list">
                        <li className="check">Web Design</li>
                        <li className="check">Web Development</li>
                        <li className="check">SEO Optimizations</li>
                        <li className="uncheck">Online Support</li>
                        <li className="uncheck">Domain &amp; Hosting</li>
                      </ul>
                      <a href="contact.html" className="main-btn arrow-btn">
                        Choose Plan
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="pricing-item pricing-item-one mb-40 wow fadeInUp"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="ribbon">Popular</div>
                    <div className="pricing-head text-center">
                      <span className="plan">Standard Plan</span>
                      <h2 className="price">
                        <span className="currency">$</span>46.95
                      </h2>
                    </div>
                    <div className="pricing-body">
                      <p>Sit amet consectetur adiising seddo eiusmod teme</p>
                      <ul className="pricing-list">
                        <li className="check">Web Design</li>
                        <li className="check">Web Development</li>
                        <li className="check">SEO Optimizations</li>
                        <li className="check">Online Support</li>
                        <li className="check">Domain &amp; Hosting</li>
                      </ul>
                      <a href="contact.html" className="main-btn arrow-btn">
                        Choose Plan
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="pricing-item pricing-item-one mb-40 wow fadeInUp"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="pricing-head text-center">
                      <span className="plan">Silver Plan</span>
                      <h2 className="price">
                        <span className="currency">$</span>96.95
                      </h2>
                    </div>
                    <div className="pricing-body">
                      <p>Sit amet consectetur adiising seddo eiusmod teme</p>
                      <ul className="pricing-list">
                        <li className="check">Web Design</li>
                        <li className="check">Web Development</li>
                        <li className="check">SEO Optimizations</li>
                        <li className="check">Online Support</li>
                        <li className="check">Domain &amp; Hosting</li>
                      </ul>
                      <a href="contact.html" className="main-btn arrow-btn">
                        Choose Plan
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      {/* pricing section end */}

      <section className="cta-area cta-area-v1 pt-130">
        <div className="container-1450">
          <div className="cta-wrapper dark-blue-bg">
            <div className="cta-img wow fadeInDown">
              <img src={Cta1} alt="" />
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-12">
                <div className="text-wrapper wow fadeInUp">
                  <div className="section-title section-title-white mb-55">
                    <span className="sub-title st-one">Let’s Work</span>
                    <h2>
                      Experience &amp; innovative
                      <span className="fill-text">solutions</span>for
                      <span className="fill-text">creative</span> design &amp;
                      development
                      <span className="fill-text">agency</span>
                    </h2>
                  </div>
                  <Link
                    to="contact.html"
                    className="main-btn bordered-btn btn-white arrow-btn"
                  >
                    Let's Work Together
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="testimonial-area testimonial-area-v1 dark-blue-bg pattern-bg pt-130 pb-80">
        <div className="shape-img wow fadeInRight animate-float-y">
          <span>
            <img src={Test3} alt="" />
          </span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="section-title section-title-white mb-50 wow fadeInLeft">
                <span className="sub-title st-one">Testimonials</span>
                <h2>What Our Client’s About Us</h2>
                <p>Professional Design Agency to provide solutions</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="img-holder mb-50 wow fadeInLeft">
                <img src={Test1} alt="" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="testimonial-slider-one mb-50 wow fadeInRight">
                <div className="testimonial-item">
                  <div className="testimonial-content">
                    <i className="flaticon-quotation" />
                    <h3>
                      Sit amet consectetur adipiscing elit sed do eiusmod
                      temporincde idunt ut labore et dolore magnas aliqua. Quis
                      ipsum suspendissey sltrices gravida. Risus commodo verra
                      maecenas lacusvel.
                    </h3>
                    <div className="author-title">
                      <h4>Dustin A. McPherson</h4>
                      <p className="position">Senior Manager</p>
                    </div>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="testimonial-content">
                    <i className="flaticon-quotation" />
                    <h3>
                      Sit amet consectetur adipiscing elit sed do eiusmod
                      temporincde idunt ut labore et dolore magnas aliqua. Quis
                      ipsum suspendissey sltrices gravida. Risus commodo verra
                      maecenas lacusvel.
                    </h3>
                    <div className="author-title">
                      <h4>Dustin A. McPherson</h4>
                      <p className="position">Senior Manager</p>
                    </div>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="testimonial-content">
                    <i className="flaticon-quotation" />
                    <h3>
                      Sit amet consectetur adipiscing elit sed do eiusmod
                      temporincde idunt ut labore et dolore magnas aliqua. Quis
                      ipsum suspendissey sltrices gravida. Risus commodo verra
                      maecenas lacusvel.
                    </h3>
                    <div className="author-title">
                      <h4>Dustin A. McPherson</h4>
                      <p className="position">Senior Manager</p>
                    </div>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="testimonial-content">
                    <i className="flaticon-quotation" />
                    <h3>
                      Sit amet consectetur adipiscing elit sed do eiusmod
                      temporincde idunt ut labore et dolore magnas aliqua. Quis
                      ipsum suspendissey sltrices gravida. Risus commodo verra
                      maecenas lacusvel.
                    </h3>
                    <div className="author-title">
                      <h4>Dustin A. McPherson</h4>
                      <p className="position">Senior Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="team-area team-area-v1 pt-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <span className="sub-title st-one">Meet Our Team</span>
                <h2>Experience Team Members</h2>
                <p>Professional Design Agency to provide solutions</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="img-holder">
                  <img src={Team1} alt="Team Imag" />
                  <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <Link to="team-details.html">Douglas J. Bleau</Link>
                  </h4>
                  <p className="position">UX/UI Designer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="img-holder">
                  <img src={Team2} alt="Team Imag" />
                  <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <Link to="team-details.html">Thomas M. Wilso</Link>
                  </h4>
                  <p className="position">Web Developer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="img-holder">
                  <img src={Team3} alt="Team Imag" />
                  <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <Link to="team-details.html">Robert J. Ryan</Link>
                  </h4>
                  <p className="position">SEO Marketing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="img-holder">
                  <img src={Team4} alt="Team Imag" />
                  <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <Link to="team-details.html">Kenneth K. Joiner</Link>
                  </h4>
                  <p className="position">UX/UI Designer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="contact-area contact-area-v1 pt-70 pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="text-wrapper mb-50 wow fadeInLeft">
                <h2>
                  Have Any on <span className="fill-text">Project</span> Mind!
                  <span className="fill-text">Contact</span> Us
                </h2>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error voluptatem
                  accusantium doloremque laudan tium, totam rem aperiam, eaque
                  ipsa quae abillo <span>inventore veritatis</span> et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem
                </p>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="information-style-one mb-40">
                      <div className="icon">
                        <span>
                          <i className="far fa-envelope-open" />
                          Email Us
                        </span>
                      </div>
                      <div className="info">
                        <h4>
                          <Link to="https://wordpressriverthemes.com/cdn-cgi/l/email-protection#4a393f3a3a25383e0a2d272b232664292527">
                            <span
                              className="__cf_email__"
                              data-cfemail="a7d4d2d7d7c8d5d3e7c0cac6cecb89c4c8ca"
                            >
                              [email&nbsp;protected]
                            </span>
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="information-style-one mb-40">
                      <div className="icon">
                        <span>
                          <i className="far fa-phone" />
                          Phone Us
                        </span>
                      </div>
                      <div className="info">
                        <h4>
                          <Link to="tel:+0123456789">+012 (345) 67 89</Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-form">
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            type="text"
                            className="form_control"
                            placeholder="Full Name"
                            name="name"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            type="email"
                            className="form_control"
                            placeholder="Email Address"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form_group">
                          <textarea
                            className="form_control"
                            placeholder="Write Message"
                            name="message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form_group">
                          <button className="main-btn arrow-btn">
                            Send Us Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-holder mb-50 wow fadeInRight">
                <div className="shape shape-icon-one scene">
                  <span data-depth={1}>
                    <img src={Shape4} alt="" />
                  </span>
                </div>
                <div className="shape shape-icon-two">
                  <span />
                </div>
                <img src={Contact1} alt="Contact Imag" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="team-area team-area-v1 pt-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <span className="sub-title st-one">Connect with Tax</span>
              </div>
            </div>
          </div>
          <div className="row no-gutters pb-5">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-2">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  {/* <i className="flaticon-start-up" /> */}
                  <img alt="Tax Img" src={monitor} />
                </div>
                <div className="text">
                  <h4 className="number mt-3">Explore</h4>
                  <p>
                    See how easily our solutions work with your business
                    application.
                  </p>
                  <Link to="#" className="main-btn bordered-btn btn-blue">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-2 ">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  {/* <i className="flaticon-creativity" /> */}
                  <img alt="W2 Img" src={click} />
                </div>
                <div className="text">
                  <h4 className="number mt-3">Live Support</h4>
                  <p>
                    Connect with the Tax1099 team directly and find effective
                    resolutions to your queries instantly.
                  </p>
                  <Link to="#" className="main-btn bordered-btn btn-blue">
                    Let’s Chat
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-2 ">
              <div
                className="counter-item mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="icon">
                  {/* <i className="flaticon-medal" /> */}
                  <img alt="W2 Img" src={email} />
                </div>
                <div className="text">
                  <h4 className="number mt-3">Email Us</h4>
                  <p>
                    Have questions write to us and our team will revert shortly.
                  </p>
                  <Link to="#" className="main-btn bordered-btn btn-blue">
                    sales@tax.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area blog-area-v1 light-gray-bg pt-130 pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div
                className="section-title text-center mb-55 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span className="sub-title st-one">Articles News</span>
                <h2>Latest News &amp; Blogs</h2>
                <p>Professional Design Agency to provide solutions</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <div
                className="blog-post-item mb-40 wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="entry-content">
                  <Link to="#" className="cat-btn">
                    Design
                  </Link>
                  <h3 className="title">
                    <Link to="blog-details.html">
                      Everything You Want To Know About
                    </Link>
                  </h3>
                  <p>
                    Sit amet consectete adipising elit wedo eiusmod temeidiunt
                    laboret dolore magna ways
                  </p>
                  <Link to="blog-details.html" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="blog-post-item mb-40 wow fadeInUp"
                data-wow-delay=".30s"
              >
                <div className="entry-content">
                  <Link to="#" className="cat-btn">
                    Design
                  </Link>
                  <h3 className="title">
                    <Link to="blog-details.html">
                      Designing Better Links For Websites And Emails
                    </Link>
                  </h3>
                  <p>
                    Sit amet consectete adipising elit wedo eiusmod temeidiunt
                    laboret dolore magna ways
                  </p>
                  <Link to="blog-details.html" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="blog-post-item mb-40 wow fadeInUp"
                data-wow-delay=".35s"
              >
                <div className="entry-content">
                  <Link to="#" className="cat-btn">
                    Design
                  </Link>
                  <h3 className="title">
                    <Link to="blog-details.html">
                      Everything You Want To Know About
                    </Link>
                  </h3>
                  <p>
                    Sit amet consectete adipising elit wedo eiusmod temeidiunt
                    laboret dolore magna ways
                  </p>
                  <Link to="blog-details.html" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="button-box text-center wow fadeInUp">
                <Link to="blogs.html" className="main-btn arrow-btn">
                  View More News
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
