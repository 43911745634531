import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
       <footer className="footer-area">
          <div className="container">
            {/* <div className="footer-top pt-75 pb-40">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="footer-logo mb-40 wow fadeInLeft">
                    <img  src={Footer1} alt="Brand Logo" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-wrapper mb-40 wow fadeInRight">
                    <h3>
                      Modern Solutionsd For
                      <span className="blue-dark">Creative Agency</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="footer-widget pt-70 pb-40">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div
                    className="widget footer-nav-widget mb-40 wow fadeInUp"
                    data-wow-delay=".15s"
                  >
                    <h4 className="widget-title">Platforms</h4>
                    <ul className="widget-nav">
                      <li>
                        <Link to="services.html">UX/UI Design</Link>
                      </li>
                      <li>
                        <Link to="services.html">Web Development</Link>
                      </li>
                      <li>
                        <Link to="services.html">SEO Optimization</Link>
                      </li>
                      <li>
                        <Link to="services.html">Product Design</Link>
                      </li>
                      <li>
                        <Link to="services.html">Logo Design</Link>
                      </li>
                      <li>
                        <Link to="services.html">Brand Design</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div
                    className="widget footer-nav-widget mb-40 wow fadeInUp"
                    data-wow-delay=".20s"
                  >
                    <h4 className="widget-title">Learn</h4>
                    <ul className="widget-nav">
                      <li>
                        <Link to="services.html">About Agency</Link>
                      </li>
                      <li>
                        <Link to="services.html">Latest News &amp; Blog</Link>
                      </li>
                      <li>
                        <Link to="services.html">Meet The Team</Link>
                      </li>
                      <li>
                        <Link to="services.html">Popular Services</Link>
                      </li>
                      <li>
                        <Link to="services.html">Testimonials</Link>
                      </li>
                      <li>
                        <Link to="services.html">Need a Career?</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div
                    className="widget social-widget mb-40 wow fadeInUp"
                    data-wow-delay=".25s"
                  >
                    <h4 className="widget-title">About Tax1099</h4>
                    <ul className="social-nav">
                      <li>
                        <Link to="#">
                          <i className="fab fa-dribbble" />
                          Dribbble
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                          Instagram
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                          Twitter
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-behance" />
                          Behance
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                          Facebook
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-medium-m" />
                          Medium
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="widget newsletter-widget mb-40 wow fadeInUp"
                    data-wow-delay=".30s"
                  >
                    <div className="newsletter-content">
                      <h3>Subscribe Our Newsletter</h3>
                      <p>
                        Natus errorsit voluptatem accusa dolore mque quae
                        <Link to="#">abillo inventore verita</Link> achitec
                        beatae vitae dicta sunt explicabo.
                      </p>
                      <form>
                        <div className="form_group">
                          <input
                            type="email"
                            className="form_control"
                            placeholder="Email Address"
                            name="email"
                            required=""
                          />
                          <button className="main-btn">Sign Up</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright">
              <div className="row">
                <div className="col-lg-6">
                  <div className="copyright-text">
                    <p>Copyright © 2023. <Link target={"_blank"} to={"https://hnhtechsolutions.com"}>Hnh Tech Solutions&nbsp;</Link>All Right Reserved</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="footer-nav float-lg-right">
                    <ul>
                      <li>
                        <Link to="service-details.html">
                          Setting &amp; Privacy
                        </Link>
                      </li>
                      <li>
                        <Link to="service-details.html">Faqs</Link>
                      </li>
                      <li>
                        <Link to="service-details.html">Services</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
    </>
  );
};

export default Footer;
