import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <>
        <header className="theme-header transparent-header">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img width="100px" src={props.Logo} alt="Logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" style={{filter: props.invert}} />
              </button>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link fw-bold" style={props.style} to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fw-bold" to="/pricing" style={props.style}>
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fw-bold" to="#" style={props.style}>
                      Services
                    </Link>
                  </li>
                  <li className="nav-item dropdown has-megamenu">
                    <Link
                      className="nav-link fw-bold dropdown-toggle"
                      to="#" style={props.style}
                      data-bs-toggle="dropdown" 
                    >
                      Mega menu
                    </Link>
                    <div className="dropdown-menu megamenu" role="menu">
                      <div className="row g-3">
                        <div className="col-lg-3 col-6">
                          <div className="col-megamenu">
                            <h6 className="title">Title Menu One</h6>
                            <ul className="list-unstyled ">
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                            </ul>
                          </div>
                          {/* col-megamenu.// */}
                        </div>
                        {/* end col-3 */}
                        <div className="col-lg-3 col-6">
                          <div className="col-megamenu">
                            <h6 className="title">Title Menu Two</h6>
                            <ul className="list-unstyled ">
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                            </ul>
                          </div>
                          {/* col-megamenu.// */}
                        </div>
                        {/* end col-3 */}
                        <div className="col-lg-3 col-6">
                          <div className="col-megamenu">
                            <h6 className="title">Title Menu Three</h6>
                            <ul className="list-unstyled">
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                            </ul>
                          </div>
                          {/* col-megamenu.// */}
                        </div>
                        <div className="col-lg-3 col-6">
                          <div className="col-megamenu">
                            <h6 className="title">Title Menu Four</h6>
                            <ul className="list-unstyled">
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                              <li className="dropdown-item">
                                <Link to="#">Custom Menu</Link>
                              </li>
                            </ul>
                          </div>
                          {/* col-megamenu.// */}
                        </div>
                        {/* end col-3 */}
                      </div>
                      {/* end row */}
                    </div>
                    {/* dropdown-mega-menu.// */}
                  </li>
                </ul>
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    {/* <Link className="nav-link fw-bold" to="#">
                      Menu item
                    </Link> */}
                    <Link
                      to="/login"
                      className="main-btn arrow-btn wow fadeInUp me-2"
                      data-wow-delay=".5s"
                    >
                      Login
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    {/* <Link
                      className="nav-link fw-bold  dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Dropdown right
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to="#">
                          Submenu item 1
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Submenu item 2
                        </Link>
                      </li>
                    </ul> */}
                    <Link
                      to="/signup"
                      className="main-btn arrow-btn wow fadeInUp mt-2 mt-md-0"
                      data-wow-delay=".5s"
                    >
                      Sign up
                    </Link>
                  </li>
                </ul>
              </div>
              {/* navbar-collapse.// */}
            </div>
            {/* container-fluid.// */}
          </nav>
        </header>
    </>
  );
};

export default Header;
