import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/images/logo/logo-dark.png'

function Header2() {
  return (
    <>
      <Navbar bg="light" className='solid_shadow py-4'>
        <div className="container">
            <div className="row">
                <div>
                    <img width="100px" src={Logo} alt="" />
                </div>
            </div>
        </div>
      </Navbar>
    </>
  );
}

export default Header2;