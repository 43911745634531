import React from "react";
import Comp from '../../assets/images/comp.svg'

const Tab4 = () => {
  return (
    <>
      <div className="container border mb-4">
        
        <div className="row mt-2">
            <div className="col-md-3">
                <img width="100px" src={Comp} alt="" />
            </div>
            <div className="col-md-9 width-fit mt-2">
                <h1 className="text-danger">Notice Management</h1>
                <p className="text-danger">A $199 add-on feature for Enterprise accounts</p>
            </div>
        </div>


        <div className="container">
        <p className="text-primary mt-4 text-start">
          Keep track of every notice, and manage all seamlessly on our
          integrated Notice Management platform.
        </p>
        <p className="text-primary text-start">
          Combat the time-consuming confusion of compliance with our guided
          Notice Management tool. Organize your communication with the IRS, and
          other involved parties, with our Notice Management dashboard.
        </p>
        <p className="text-secondary text-start">
          New for tax year 2018, Tax1099 now offers a comprehensive tool for
          managing notices from the IRS, anything from the CP2100/CP2100A for an
          incorrect TIN to a CP136B for a 941 deposit. You can view and
          customize your tasks for handling notices. Keep tabs on the research,
          response, document management, and billing tasks involved in notice
          management. Add clients, files, deadlines, and notes to your records.
          We’ll help you prevent headaches and penalties with the Notice
          Management add-on to Tax1099 Enterprise accounts.
        </p>
        <button className="btn btn-primary mb-3">
            Learn More
        </button>
        </div>
      </div>
    </>
  );
};

export default Tab4;
