import React, { useState } from "react";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
// import axios from "axios";
import BaseUrl from "../../../BaseUrl";
import { TailSpin } from "react-loader-spinner";
import Swal from "sweetalert2";
import { PostApi } from '../../../Helper/helper';

const Forget = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const formData = new FormData();

  formData.append("email", email);
  // formData.append("password", password);


  const forgtepass = () => {
    setLoader(true);
    PostApi(`${BaseUrl.baseUrl}customer/forget`, formData)
    .then((resolve) => {
      // console.log(data.data.message, "minhaj");
        setLoader(false);
        console.log("resolve ==>", resolve);
        // console.log(resolve.data.token,'hahhaha')
        // console.log(resolve.data.data.id,'id')
        if (resolve?.data?.message === "Reset Token Has Been Sent! Check Your Email For The Link") {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: '',
            text: resolve.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          navigate("/verifyotp");
        }
         else if (resolve?.data?.message === "We Dont have account") {
          console.log(resolve?.data?.message,'kkk')
          
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: resolve?.data?.message,
            // footer: '<a href="">Why do I have this issue?</a>'
          })
        }
        // localStorage.setItem("loginEmail", email);
        // localStorage.setItem("token", resolve.data.token);
        // localStorage.setItem("id", resolve.data.data.id);
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          // text: resolve.data.data.message,
        });
        console.log("error ==>", error);
      });
  };

  return (
    <>
      <section className="vh-100 align-items-center justify-content-center d-flex login_bg">
        <div className="container">
          <div className="row align-items-center justify-content-center px-md-0 px-5">
            <div className="col box_shadow py-5 login_width">
              <h2 className="mb-4 text-light">Forget Your Password</h2>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <label className="d-block width-fit text-light">
                  Email address
                </label>
                <Form.Item
                  className="mb-2"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Email!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    className="py-2"
                    placeholder="Email"
                  />
                </Form.Item>
                {/* <label className="d-block width-fit text-light">Password</label> */}
                {/* <Form.Item
                  className="mb-2"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </Form.Item> */}
                {/*
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="width-fit"
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item> */}

                {/* <span className="width-fit d-block text-light">
                  Don't have an account? &nbsp;
                  <Link to="/signup" className="text-light fw-bold">
                    Sign up
                  </Link>
                  <Link to="/signup" className="text-light fw-bold" style={{marginLeft:'86px'}}>
                  Forget Password
                  </Link>
                </span> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="d-flex align-items-center me-auto mt-1"
                    onClick={forgtepass}
                  >
                    Send &nbsp;
                    {loader ? (
                      <TailSpin
                        height="20"
                        width="20"
                        color="#fff"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : null}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forget;
