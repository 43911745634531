import { useEffect} from 'react'
import {  useNavigate } from 'react-router-dom'

const ProtectedRout =(props)=> {
    const {Component} = props;
    const navigate = useNavigate();
    useEffect(() => {
        if(!localStorage.getItem("token")){
          navigate('/Login')
      
      
      
        }
        // getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

  return (
    <>
    <Component/>
    
    </>
  )
}

export default ProtectedRout