// import { forwardRef } from "react";
// import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
// import FilterList from "@material-ui/icons/FilterList";
// import FirstPage from "@material-ui/icons/FirstPage";
// import LastPage from "@material-ui/icons/LastPage";
// import Remove from "@material-ui/icons/Remove";
// import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
// import ViewColumn from "@material-ui/icons/ViewColumn";
// import MaterialTable from "material-table";
// import { Delete } from "@material-ui/icons";
// import { ThemeProvider, createTheme } from "@mui/material";
import "../../../src/UserPanel/assets/styles/main.css";
import "../../../src/UserPanel/assets/styles/responsive.css";
import React from "react";
import MUIDataTable from "mui-datatables";

function Tables() {
  // const defaultMaterialTheme = createTheme();
  // const history = useHistory();

  // const [columns, setColumns] = useState([
  //   { title: "S.NO", field: "id" },
  //   { title: "Name", field: "name" },
  //   {
  //     title: "property type",
  //     field: "property_type",
  //     initialEditValue: "initial edit value",
  //   },
  //   { title: "check in date", field: "Checkindate" },
  //   {
  //     title: "check out date",
  //     field: "checkoutdate",
  //   },
  //   {
  //     title: "address",
  //     field: "address",
  //   },
  // ]);

  // const [data, setData] = useState([
  //   {
  //     id: 1,
  //     name: "one bedroom",
  //     property_type: "Room",
  //     Checkindate: "10/11/1987",
  //     checkoutdate: "10/11/1988",
  //     address: "torronto",
  //   },
  //   {
  //     id: 2,
  //     name: "Sea facing House",
  //     property_type: "home",
  //     Checkindate: "10/11/1987",
  //     checkoutdate: "10/11/1988",
  //     address: "albert",
  //   },
  //   {
  //     id: 3,
  //     name: "luxary apartment",
  //     property_type: "apartment",
  //     Checkindate: "10/11/1987",
  //     checkoutdate: "10/11/1988",
  //     address: "ontario",
  //   },
  //   {
  //     id: 4,
  //     name: "sing room",
  //     property_type: "Room",
  //     Checkindate: "10/11/1987",
  //     checkoutdate: "10/11/1988",
  //     address: "Montreal",
  //   },
  //   {
  //     id: 5,
  //     name: "wood House",
  //     property_type: "House",
  //     Checkindate: "10/11/1987",
  //     checkoutdate: "10/11/1988",
  //     address: "Calgary",
  //   },
  // ]);

  // const tableIcons = {
  //   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  //   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  //   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  //   DetailPanel: forwardRef((props, ref) => (
  //     <ChevronRight {...props} ref={ref} />
  //   )),
  //   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  //   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  //   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  //   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  //   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  //   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  //   PreviousPage: forwardRef((props, ref) => (
  //     <ChevronLeft {...props} ref={ref} />
  //   )),
  //   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  //   SortArrow: forwardRef((props, ref) => (
  //     <ArrowDownward {...props} ref={ref} />
  //   )),
  //   ThirdStateCheck: forwardRef((props, ref) => (
  //     <Remove {...props} ref={ref} />
  //   )),
  //   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  // };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    {
      name: "James Houston",
      company: "Test Corp",
      city: "Dallas",
      state: "TX",
    },
  ];

  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      <div className="mt-5">
        {/* <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            style={{ margin: "0px", padding: "0px" }}
            icons={tableIcons}
            title="Listing"
            columns={[
              { title: "Name", field: "name" },
              { title: "Surname", field: "surname" },
              { title: "Birth Year", field: "birthYear", type: "numeric" },
              {
                title: "Birth City",
                field: "birthCity",
                lookup: { 1: "Linz", 2: "Vöcklabruck", 3: "Salzburg" },
              },
            ]}
            data={[
              {
                name: "Max",
                surname: "Mustermann",
                birthYear: 1987,
                birthCity: 1,
              },
              {
                name: "Cindy",
                surname: "Musterfrau",
                birthYear: 1995,
                birthCity: 2,
              },
            ]}
            actions={[
              {
                icon: () => <Edit style={{ color: "#000" }} />,
                tooltip: "Edit Property",
                // onClick: (value, rowData) => {
                //   navigate("/editlisting/" + rowData.id);
                //   console.log(rowData.id, "object");
                // },
              },
              {
                icon: () => <Delete style={{ color: "red" }} />,
                tooltip: "Delete Property",
                // onClick: (value, rowData) => {
                //   deldata(rowData);
                // },
              },
            ]}
            options={{
              rowStyle: {
                // backgroundColor: "#212529",
                // color: "#fff",
              },
              actionsColumnIndex: -1,
            }}
          />
        </ThemeProvider> */}

        <MUIDataTable
          title={"Employee List"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

export default Tables;
