import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Tab1 from "./Tab1";
import Tab4 from "./Tab4";

function PriceTab() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-3 d-flex align-items-center justify-content-center lightblue mt-3 rounded"
          >
            <Tab eventKey="home" title="Essential">
              <Tab1/>
            </Tab>
            <Tab eventKey="profile" title="eFile Plus ">
            <Tab1/>
            </Tab>
            <Tab eventKey="contact" title="Enterprise" >
            <Tab1/>
            </Tab>
            <Tab eventKey="Enterprise" title="Enterprise With Notice Management" >
              <Tab4/>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default PriceTab;
