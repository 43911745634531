import { useState,  } from "react";
// import Header from "./Header";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

//
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import { PostApi } from "../../Helper/helper";
// import { getApiWithToken } from "../../Helper/helper";
import BaseUrl from "../../BaseUrl/index";
import Swal from "sweetalert2";
import Spinner from 'react-bootstrap/Spinner';
// import { Loader } from "react-overlay-loader";

// import "react-overlay-loader/styles.css";
// import { genModalMaskStyle } from "antd/es/modal/style";

const Form1099 = () => {

  

  const [userData] = useState(localStorage.getItem("token"));
  console.log(userData, "token");
  const [idc] = useState(localStorage.getItem("id"));
  const [loder, setLoader] = useState(false);

  
 const [account_number, setaccount_number]= useState(null)
 const [notice, ]= useState(null)
 const [tax_year, settax_year]= useState(null)
 const [nonemployee_amount, setnonemployee_amount]= useState(null)
 const [recipient_resale, setrecipient_resale]= useState(null)
 const [federal_income_tax, setfederal_income_tax]= useState(null)
 const [state_tax_1, setstate_tax_1]= useState(null)
 const [state_tax_2, setstate_tax_2]= useState(null)
 const [payer_state_no_1, setpayer_state_no_1]= useState(null)
 const [payer_state_no_2, setpayer_state_no_2]= useState(null)
 const [state_income_1, setstate_income_1]= useState(null)
 const [state_income_2, setstate_income_2]= useState(null)
 const [payer_details, setpayer_details]= useState(null)

 const [payer_tin, setpayer_tin]= useState(null)
 const [recipient_details, setrecipient_details ]= useState(null)
 const [recipient_tin, setrecipient_tin]= useState(null)
 const [recipient_street_address, setrecipient_street_address]= useState(null)
 const [recipient_city_town, setrecipient_city_town]= useState(null)
 const onsubmits = () => {
  setLoader(true);
  const formData = new FormData();
  formData.append("customer_id", idc);
  formData.append("account_number", account_number);
  formData.append("notice", notice);
  formData.append("tax_year", tax_year);
  formData.append("nonemployee_amount", nonemployee_amount);
  formData.append("recipient_resale", recipient_resale);
  formData.append("federal_income_tax", federal_income_tax);
  formData.append("state_tax_1", state_tax_1);
  formData.append("state_tax_2", state_tax_2);
  formData.append("payer_state_no_1", payer_state_no_1);
  formData.append("payer_state_no_2", payer_state_no_2);
 formData.append("state_income_1", state_income_1);
  formData.append("state_income_2", state_income_2);
  formData.append("payer_details", payer_details);
  formData.append("payer_tin", payer_tin);
  formData.append("recipient_details", recipient_details);
  formData.append("recipient_tin", recipient_tin);
  formData.append("recipient_street_address", recipient_street_address);
  formData.append("recipient_city_town", recipient_city_town);


 
 

  PostApi(`${BaseUrl.baseUrl}customer/nec/store`, formData, userData)
    .then((data) => {
      setLoader(false);
      console.log(data.data, "ali");

      if (data.data.message === "Form 1099-NEC Successfully Created") {
        Swal.fire({
          title: "success",
          text: data.data.message,
          icon: "success",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
        window.location.href = data.data.pdf_url;
        // getDatar();
        // setShowr(false);

        // console.log(data.data,"minhaj")
        //   console.log("heheh",data.data.user.name)
        // console.log("name", data.data.accessToken.name)
        // console.log("hahahahaha",data.status)
        // console.log("hahahahaha",data.message)

        // localStorage.setItem("token", data.data.accessToken.name);
        // localStorage.setItem("username", data.data.user.name);
        // console.log("login");
        // navigate("/");
      } else {
        // console.log("else========", data.message);
        // // alert(data.data);
        // localStorage.setItem("tokenen", data.data);
        console.log("not login");
        // Swal.fire({
        //   title: "Oops",
        //   text: "Error",
        //   icon: "error",
        //   confirmButtonColor: "#29BF12",
        // });
        console.log(data.data.errors.DOB, "DOB error");

        // setDateError(data.data.errors.DOB, "Gender error");
        // setGenderError(data.data.errors.gender, "Gender error");
        // setPasswordError(data.data.errors.password, "Password Error");
        // setPasswordConError(
        //   data.data.errors.passwordConfirmation,
        //   "Password Con Error"
        // );
        // setEmailError(data.data.errors.email, "Email Error");
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "error",
        text: error,
        icon: "error",
        confirmButtonColor: "#29BF12",
        timer: 1500,
      });
    });
};
 



  return (
    
    <div className="col-lg-12 ">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center" style={{}}>
        <input type="radio" id="html" name="fav_language" value="HTML" />
        <label for="html" style={{marginLeft:'2px'}}>VOID</label>
<input type="radio" id="html" name="fav_language" value="HTML" style={{marginLeft:'15px'}}/>

<label for="html" style={{marginLeft:'2px'}}>CORRECTED</label>
        </div>
        <div className="col-lg-12">
<div className="row">

    <div className="col-lg-6" style={{border:'1px solid black'}}>

        <p>PAYER’S name, street address, city or town, state or province, country, ZIP
or foreign postal code, and telephone no.</p>
<textarea className="form-control"   
onChange={(e) => setpayer_details(e.target.value)}  

></textarea>
    </div>
    <div className="col-lg-2" style={{border:'1px solid black',backgroundColor:'#c0c0c0'}}>


</div>
<div className="col-lg-2" style={{border:'1px solid black',}}>
    <p>OMB No. 1545-0116</p>
    <p>Form <span style={{fontSize:'15px', fontWeight:'bold'}}>1099-NEC</span></p>
    <p>(Rev. January 2022)</p>
    <hr/>
    <p>For calendar year</p>
    <p>
20<span><input type="number" style={{width:'50px'}} onChange={(e) => settax_year(e.target.value)}  /></span></p>
    


</div>
<div className="col-lg-2 d-flex justify-content-center"  

style={{borderBottom: '1px solid black'}} >

    <h5>Nonemployee
Compensation
</h5>
</div>

<div className="col-lg-4" style={{border:'1px solid black', paddingBottom:'10px'}}>
    <label>PAYER’S TIN</label>
    <input type="number" className="form-control"
    onChange={(e) => setpayer_tin(e.target.value)} 
    
    
    />
</div>
<div className="col-lg-4" style={{border:'1px solid black',}}>
    <label>RECIPIENT’S TIN</label>
    <input type="number" className="form-control"
     onChange={(e) => setrecipient_tin(e.target.value)} 
    
    />
</div>
<div className="col-lg-4" style={{border:'1px solid black',}}>
    <label>1 Nonemployee compensation</label>
    <input type="number" className="form-control"  onChange={(e) => setnonemployee_amount(e.target.value)}  />
</div>
<div className="col-lg-12">
    <div className="row">

    <div className="col-lg-6" style={{border:'1px solid black', paddingBottom:'10px'}}>
    <label>RECIPIENT’S name</label>
    <input type="text" className="form-control" onChange={(e) => setrecipient_details(e.target.value)} />
    <label>Street address (including apt. no.)</label>
    <input type="text" className="form-control"
    
    onChange={(e) => setrecipient_street_address(e.target.value)} 
    />
    <label>City or town, state or province, country, and ZIP or foreign postal code</label>
    <input type="text" className="form-control"
     onChange={(e) => setrecipient_city_town(e.target.value)} 
    />
</div>

<div className="col-lg-6" style={{border:'1px solid black',}}>
    <div className="d-flex justify-content-start" style={{padding:'2px'}}>
    <label for="html" >2 Payer made direct sales totaling $5,000 or more of
consumer products to recipient for resale</label>
<input type="radio" id="html" name="fav_language" value="HTML" 
onChange={(e) => setrecipient_resale(e.target.value)}
style={{marginLeft:'10px'}}/>
        
</div>
<div  style={{
    height: '56px',
    backgroundColor: 'silver',


}}>
 <h6 style={{backgroundColor:'#fff', width:'30px'}}>3</h6>

</div>
<div>
    <label>4 Federal income tax withheld</label>
    <input type="number" className="form-control"
    onChange={(e) => setfederal_income_tax(e.target.value)}
    
    />
</div>

</div>
    </div>




</div>


    <div className="col-lg-6" style={{border:'1px solid black',}}>
        <label>Account number (see instructions)</label>
    <input type="number" className="form-control"
    
    onChange={(e) => setaccount_number(e.target.value)}
    />
    </div>
    <div className="col-lg-6" style={{border:'1px solid black',paddingBottom:'10px'}}>
        <div className="row">
       <div className="col-lg-2">
       <label>5 State tax withheld</label>
    <input type="number" className="form-control"
    onChange={(e) => setstate_tax_1(e.target.value)}
    
    />
    <hr/>
       <input type="number" className="form-control"
       
       onChange={(e) => setstate_tax_2(e.target.value)}
       />
       </div>
       <div className="col-lg-2">
       <label>6 Payer state no.</label>
    <input type="number" className="form-control"
     onChange={(e) => setpayer_state_no_1(e.target.value)}
    
    
    />
    <hr/>
       <input type="number" className="form-control"
       
       onChange={(e) => setpayer_state_no_2(e.target.value)}
       />
       </div>
       <div className="col-lg-2">
       <label>7 State income.</label>
    <input type="number" className="form-control"
     onChange={(e) => setstate_income_1(e.target.value)}
    
    />
    <hr/>
       <input type="number" className="form-control"
        onChange={(e) => setstate_income_2(e.target.value)}
       
       />
       </div>

     
       </div>
    </div>
</div>



        </div>
        <div className="col-lg-12 d-flex justify-content-end mt-2">
         <button type="button" class="btn btn-primary  " onClick={onsubmits}>Submit
         
         {loder !== false? 
                        <Spinner animation="grow" size="sm"  />:null
                     }
         </button>
         </div>
        
      </div>
      </div>

    
  );
};

export default Form1099;
