import "./App.css";
import "./Web/assets/css/style.css";
import "./Web/assets/css/responsive.css";
import "./Web/assets/css/default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Web/Components/Home";
import ErrorPage from "./Web/Components/404/ErrorPage";
import Login from "./Web/Components/Auth/Login";
import Signup from "./Web/Components/Auth/Singup";
import Pricing from "./Web/Components/Pricing";
import Footer from "./Web/Components/Layout/Footer";
import Register from "./Web/Components/Auth/Register";
// user Panel
import Main from "./UserPanel/components/layout/Main";
import Home2 from "../src/UserPanel/pages/Home";
import React from "react";
import Billing from "./UserPanel/pages/Billing";
import Tables from "./UserPanel/pages/Tables";
import Welcome from "./Web/Components/Auth/Welcome";
import Newform from "./UserPanel/pages/Newform";
import Newemp from "./UserPanel/pages/Newemp";
import ProtectedRout from './ProtectedRout';
import Managepayer from "./UserPanel/pages/Managepayer";
import Order from "./UserPanel/pages/Order";
import Form941 from "./UserPanel/pages/Form941";
import Forget from "./Web/Components/Auth/Forget";
import Verifyotp from "./Web/Components/Auth/Verifyotp";
import Resetpass from "./Web/Components/Auth/Resetpass";
import Packeges from "./UserPanel/pages/Packeges";
import Form1099 from "./UserPanel/pages/Form1099";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route
            path="/dashboard"
            element={ <Main/> }
          >
          <Route path="" element={<Home2 />} />
          <Route path="billing" element={<ProtectedRout Component={Billing}/>} />
          <Route path="table" element={<Tables />} />
          {/* <Route path="table" element={<Profile />} /> */}
          <Route path="Newform" element={ <ProtectedRout Component={Newform }  />} />
          <Route path="941" element={ <ProtectedRout Component={Form941}  />} />
          <Route path="1099" element={ <ProtectedRout Component={Form1099}  />} />
          <Route path="Newemp" element={<ProtectedRout  Component={Newemp}/>} />
          <Route path="Managepayer" element={<ProtectedRout  Component={Managepayer}/>} />
          <Route path="Order" element={<ProtectedRout  Component={Order}/>} />
          <Route path="package" element={<ProtectedRout  Component={Packeges}/>} />
         
            
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/verifyotp" element={<Verifyotp />} />
          <Route path="/resetpassword" element={<Resetpass />} />
          <Route path="/signup" element={<Signup   />} />
          <Route path="/users/:id" element={<Register />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="footer" element={<Footer />} />
          <Route path="welcome" element={<Welcome />} />
          
       
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      <></>
    </div>
  );
}

export default App;
