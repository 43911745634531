/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
// import Header from "./Header";
// import Footer from "./Footer";
import { Link } from "react-router-dom";

//
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PostApi } from "../../Helper/helper";
import { getApiWithToken } from "../../Helper/helper";
import BaseUrl from "../../BaseUrl/index";
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { genModalMaskStyle } from "antd/es/modal/style";
// import { useNavigate } from "react-router-dom";

const Newform = () => {
  // const currentUrl = window.location.href;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const back = () => {
    setShow(false);
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow(false);
  const back1 = () => {
    setShow1(false);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const back2 = () => {
    setShow2(false);
  };
  const m1099 = () => {
    setShow2(true);
    setShow1(false);
  };
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const back3 = () => {
    setShow3(false);
  };
  const nec = () => {
    setShow3(true);
    setShow1(false);
  };
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow3(false);
  const back4 = () => {
    setShow4(false);
    setShow1(false);
  };
  const a1099 = () => {
    setShow4(true);
    setShow1(false);
  };
  const [showr, setShowr] = useState(false);
  const handleCloser = () => setShowr(false);
  const backr = () => {
    setShowr(false);
    setShow1(false);
  };
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const back5 = () => {
    setShow5(false);
  };
  const b1099 = () => {
    setShow5(true);
    setShow1(false);
  };

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const back6 = () => {
    setShow6(false);
  };
  const c1099 = () => {
    setShow6(true);
    setShow1(false);
  };
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow6(false);
  const back8 = () => {
    setShow8(false);
  };
  const div1099 = () => {
    setShow8(true);
    setShow1(false);
  };
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const back9 = () => {
    setShow9(false);
  };
  const s1099 = () => {
    setShow9(true);
    setShow1(false);
  };

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const back10 = () => {
    setShow10(false);
  };
  const ls1099 = () => {
    setShow10(true);
    setShow1(false);
  };

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const back11 = () => {
    setShow11(false);
  };
  const sa1099 = () => {
    setShow11(true);
    setShow1(false);
  };

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const back12 = () => {
    setShow12(false);
  };
  const Form1098 = () => {
    setShow12(true);
    setShow1(false);
  };

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const back13 = () => {
    setShow13(false);
  };
  const form1098C = () => {
    console.log("sho13");
    setShow13(true);
    setShow1(false);
  };

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const back14 = () => {
    setShow14(false);
  };
  const t1098 = () => {
    console.log("sho13");
    setShow14(true);
    setShow1(false);
  };
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const back15 = () => {
    setShow14(false);
  };
  const g1099 = () => {
    console.log("sho13");
    setShow15(true);
    setShow1(false);
  };
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const back16 = () => {
    setShow16(false);
  };
  const int1099 = () => {
    setShow16(true);
    setShow1(false);
  };

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const back17 = () => {
    setShow17(false);
  };
  const k1099 = () => {
    setShow17(true);
    setShow1(false);
  };
  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const back18 = () => {
    setShow18(false);
  };
  const oid1099 = () => {
    setShow18(true);
    setShow1(false);
  };
  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const back19 = () => {
    setShow19(false);
  };
  const part1099 = () => {
    setShow19(true);
    setShow1(false);
  };
  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const back20 = () => {
    setShow20(false);
  };
  const r1099 = () => {
    setShow20(true);
    setShow1(false);
  };

  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const back21 = () => {
    setShow21(false);
  };
  const e1098 = () => {
    setShow21(true);
    setShow1(false);
  };
  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const back22 = () => {
    setShow22(false);
  };
  const f3921 = () => {
    setShow22(true);
    setShow1(false);
  };
  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const back23 = () => {
    setShow23(false);
  };
  const f3922 = () => {
    setShow23(true);
    setShow1(false);
  };
  const [show24, setShow24] = useState(false);
  const handleClose24 = () => setShow24(false);
  const back24 = () => {
    setShow24(false);
  };
  const f1095b = () => {
    setShow24(true);
    setShow1(false);
  };
  const [show25, setShow25] = useState(false);
  const handleClose25 = () => setShow25(false);
  const back25 = () => {
    setShow25(false);
  };
  const f1095c = () => {
    setShow25(true);
    setShow1(false);
  };

   const [show26, setShow26] = useState(false);
 const handleClose26 = () => setShow25(false);
  const back26 = () => {
    setShow26(false);
  };
  const f1097btc = () => {
    setShow26(true);
    setShow1(false);
  };
  const [show27, setShow27] = useState(false);
  const handleClose27 = () => setShow27(false);
  const back27 = () => {
    setShow27(false);
  };
  const f941 = () => {
    setShow27(true);
    setShow1(false);
  };
  const [show27f, setShow27f] = useState(false);
  const handleClose27f = () => setShow27f(false);
  const back27f = () => {
    setShow27f(false);
  };
  const f941f = () => {
    setShow27f(true);
    setShow27(false);
  };
  const [show27e, setShow27e] = useState(false);
  const handleClose27e = () => setShow27e(false);
  const back27e = () => {
    setShow27e(false);
  };
  const f940 = () => {
    setShow27e(true);
    setShow1(false);
  };
  const [show29, setShow29] = useState(false);
  const handleClose29 = () => setShow29(false);
  const back29 = () => {
    setShow29(false);
  };
  const f940e = () => {
    setShow29(true);
    setShow27e(false);
  };

  


  const [show28, setShow28] = useState(false);
  const handleClose28 = () => setShow27f(false);
  const back28 = () => {
    setShow28(false);
  };
  const fW2  = () => {
    setShow28(true);
    setShow1(false);
  };

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const back7 = () => {
    setShow7(false);
  };
  // Applicable check box on Form8949
  const Box = () => {
    setShow7(true);
  };
  const [showhealth, setShowhealth] = useState(false);
  const handleClosehealth = () => setShowhealth(false);
  const backhealth = () => {
    setShowhealth(false);
  };
  // Applicable check box on Form8949
  const health = () => {
    setShowhealth(true);
  };
  const [coverages, setcoverages] = useState();
  const [showcov, setShowcov] = useState(false);
  const handleClosecov = () => setShowcov(false);
  const backcov = () => {
    setShowcov(false);
  };
  // Applicable check box on Form8949
  const coverage = () => {
    setShowcov(true);
  };

  const Recip = () => {
    setShowr(true);
    setShow1(false);
  };

  // jan
  const [jan, setjan] = useState();
  const [showjan, setShowjan] = useState(false);
  const handleClosejan = () => setShowjan(false);
  const backjan = () => {
    setShowjan(false);
  };
  // Applicable check box on Form8949
  const janm = () => {
    setShowjan(true);
  };
  // feb
  const [feb, setfeb] = useState();
  const [showfeb, setShowfeb] = useState(false);
  const handleClosefeb = () => setShowfeb(false);
  const backfeb = () => {
    setShowfeb(false);
  };
  // Applicable check box on Form8949
  const febm = () => {
    setShowfeb(true);
  };

  // mar
  const [mar, setmar] = useState();
  const [showmar, setShowmar] = useState(false);
  const handleClosemar = () => setShowfeb(false);
  const backmar = () => {
    setShowmar(false);
  };
  // Applicable check box on Form8949
  const marm = () => {
    setShowmar(true);
  };
  // apr
  const [apr, setapr] = useState();
  const [showapr, setShowapr] = useState(false);
  const handleCloseapr = () => setShowapr(false);
  const backapr = () => {
    setShowapr(false);
  };
  // Applicable check box on Form8949
  const aprm = () => {
    setShowapr(true);
  };
  // may
  const [maym, setmaym] = useState();
  const [showmaym, setShowmaym] = useState(false);
  const handleClosemaym = () => setShowmaym(false);
  const backmaym = () => {
    setShowmaym(false);
  };
  // Applicable check box on Form8949
  const maymm = () => {
    setShowmaym(true);
  };
  // jun
  const [jun, setjun] = useState();
  const [showjun, setShowjun] = useState(false);
  const handleClosejun = () => setShowjun(false);
  const backjun = () => {
    setShowjun(false);
  };
  const junm = () => {
    setShowjun(true);
  };
  // july
  const [julym, setjulym] = useState();
  const [showjulym, setShowjulym] = useState(false);
  const handleClosejulym = () => setShowjulym(false);
  const backjulym = () => {
    setShowjulym(false);
  };
  // Applicable check box on Form8949
  const julymm = () => {
    setShowjulym(true);
  };

  // Aug
  const [aug, setaug] = useState();
  const [showaug, setShowaug] = useState(false);
  const handleCloseaug = () => setShowaug(false);
  const backaug = () => {
    setShowaug(false);
  };
  // Applicable check box on Form8949
  const augm = () => {
    setShowaug(true);
  };
  // Sep
  const [sep, setsep] = useState();
  const [showsep, setShowsep] = useState(false);
  const handleClosesep = () => setShowsep(false);
  const backsep = () => {
    setShowsep(false);
  };
  // Applicable check box on Form8949
  const sepm = () => {
    setShowsep(true);
  };
  // Oct
  const [oct, setoct] = useState();
  const [showoct, setShowoct] = useState(false);
  const handleCloseoct = () => setShowoct(false);
  const backoct = () => {
    setShowoct(false);
  };
  // Applicable check box on Form8949
  const octm = () => {
    setShowoct(true);
  };
  // Nov
  const [nov, setnov] = useState();
  const [shownov, setShownov] = useState(false);
  const handleClosenov = () => setShownov(false);
  const backnov = () => {
    setShownov(false);
  };
  // Applicable check box on Form8949
  const novm = () => {
    setShownov(true);
  };

  // Dec
  const [dec, setdec] = useState();
  const [showdec, setShowdec] = useState(false);
  const handleClosedec = () => setShowdec(false);
  const backdec = () => {
    setShowdec(false);
  };
  // Applicable check box on Form8949
  const decm = () => {
    setShowdec(true);
  };


  // Applicable Section 4980H Safe Harbor (enter code, if applicable)
  const [app1, setapp1] = useState();
  const [showapp1, setShowapp1] = useState(false);
  const handleCloseapp1 = () => setShowapp1(false);
  const backapp1 = () => {
    setShowapp1(false);
  };
  // Applicable check box on Form8949
  const appl1 = () => {
    setShowapp1(true);
  };


   // Applicable Section 4980H Safe Harbor (enter code, if applicable) 2
   const [app2, setapp2] = useState();
   const [showapp2, setShowapp2] = useState(false);
   const handleCloseapp2 = () => setShowapp2(false);
   const backapp2 = () => {
     setShowapp2(false);
   };
   // Applicable check box on Form8949
   const appl2 = () => {
     setShowapp2(true);
   };

    // Applicable Section 4980H Safe Harbor (enter code, if applicable) 3
    const [app3, setapp3] = useState();
    const [showapp3, setShowapp3] = useState(false);
    const handleCloseapp3 = () => setShowapp3(false);
    const backapp3 = () => {
      setShowapp3(false);
    };
    // Applicable check box on Form8949
    const appl3 = () => {
      setShowapp3(true);
    };
     // Applicable Section 4980H Safe Harbor (enter code, if applicable) 4
     const [app4, setapp4] = useState();
     const [showapp4, setShowapp4] = useState(false);
     const handleCloseapp4 = () => setShowapp4(false);
     const backapp4 = () => {
       setShowapp4(false);
     };
     // Applicable check box on Form8949
     const appl4 = () => {
       setShowapp4(true);
     };
      // Applicable Section 4980H Safe Harbor (enter code, if applicable) 5
      const [app5, setapp5] = useState();
      const [showapp5, setShowapp5] = useState(false);
      const handleCloseapp5 = () => setShowapp5(false);
      const backapp5 = () => {
        setShowapp5(false);
      };
      // Applicable check box on Form8949
      const appl5 = () => {
        setShowapp5(true);
      };
      // Applicable Section 4980H Safe Harbor (enter code, if applicable) 6
      const [app6, setapp6] = useState();
      const [showapp6, setShowapp6] = useState(false);
      const handleCloseapp6 = () => setShowapp6(false);
      const backapp6 = () => {
        setShowapp6(false);
      };
      // Applicable check box on Form8949
      const appl6 = () => {
        setShowapp6(true);
      };


      // Applicable Section 4980H Safe Harbor (enter code, if applicable) 7
      const [app7, setapp7] = useState();
      const [showapp7, setShowapp7] = useState(false);
      const handleCloseapp7 = () => setShowapp7(false);
      const backapp7 = () => {
        setShowapp7(false);
      };
      // Applicable check box on Form8949
      const appl7 = () => {
        setShowapp7(true);
      };
// Applicable Section 4980H Safe Harbor (enter code, if applicable) 8
const [app8, setapp8] = useState();
const [showapp8, setShowapp8] = useState(false);
const handleCloseapp8 = () => setShowapp8(false);
const backapp8 = () => {
  setShowapp8(false);
};
// Applicable check box on Form8949
const appl8 = () => {
  setShowapp8(true);
};
// Applicable Section 4980H Safe Harbor (enter code, if applicable) 9
const [app9, setapp9] = useState();
const [showapp9, setShowapp9] = useState(false);
const handleCloseapp9 = () => setShowapp9(false);
const backapp9 = () => {
  setShowapp9(false);
};
// Applicable check box on Form8949
const appl9 = () => {
  setShowapp9(true);
};
// Applicable Section 4980H Safe Harbor (enter code, if applicable) 10
const [app10, setapp10] = useState();
const [showapp10, setShowapp10] = useState(false);
const handleCloseapp10 = () => setShowapp10(false);
const backapp10 = () => {
  setShowapp10(false);
};
// Applicable check box on Form8949
const appl10 = () => {
  setShowapp10(true);
};
// Applicable Section 4980H Safe Harbor (enter code, if applicable) 11
const [app11, setapp11] = useState();
const [showapp11, setShowapp11] = useState(false);
const handleCloseapp11 = () => setShowapp11(false);
const backapp11 = () => {
  setShowapp11(false);
};
// Applicable check box on Form8949
const appl11 = () => {
  setShowapp11(true);
};
// Applicable Section 4980H Safe Harbor (enter code, if applicable) 12
const [app12, setapp12] = useState();
const [showapp12, setShowapp12] = useState(false);
const handleCloseapp12 = () => setShowapp12(false);
const backapp12 = () => {
  setShowapp12(false);
};
// Applicable check box on Form8949
const appl12 = () => {
  setShowapp12(true);
};
const [app13, setapp13] = useState();
const [showapp13, setShowapp13] = useState(false);
const handleCloseapp13 = () => setShowapp13(false);
const backapp13 = () => {
  setShowapp13(false);
};
// Applicable check box on Form8949
const appl13 = () => {
  setShowapp13(true);
};

  // user
  const [showuser, setShowuser] = useState(false);
  const handleCloseuser = () => setShowuser(false);
  const backuser = () => {
    setShowuser(false);
  };
  // Applicable check box on Form8949
  const user = () => {
    setShowuser(true);
  };
  useEffect(() => {
    // if (!localStorage.getItem("token")) {
    //   navigate("/Login");
    // }
    getData();
    getDatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [datas, setdatas] = useState([]);
  const [einn, seteinn] = useState();
  const [disregarded_entity, setdisregarded_entity] = useState();
  console.log(disregarded_entity, "hahahaah")
  console.log(einn, "fatir")
  const [loader, setLoader] = useState(false);
  const [userData] = useState(localStorage.getItem("token"));
  console.log(userData, "token");
  const [idc] = useState(localStorage.getItem("id"));
  const getData = () => {
    setLoader(true);
    getApiWithToken(`${BaseUrl.baseUrl}customer/payer/view`, "", userData)
      .then(({ data }) => {
        console.log("mmmmmmmmmmmmmmmmmmmmm", data.data);
        setdatas(data.data);
       

        // setName(data.name);
        // setEmail(data.email);
        // setProfileImage(data.picture);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const [datar, setdatar] = useState([]);
  const getDatar = () => {
    setLoader(true);
    getApiWithToken(`${BaseUrl.baseUrl}customer/recipient/view`, "", userData)
      .then(({ data }) => {
        console.log("rrrrrrrrrr", data.data);
        setdatar(data.data);

        // setName(data.name);
        // setEmail(data.email);
        // setProfileImage(data.picture);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const [name, setname] = useState();
  const [state, setstate] = useState();
  const [address, setaddress] = useState();
  const [zipcode, setzipcode] = useState();
  const [city, setcity] = useState();
  const [phone, setphone] = useState();
  const [pid, setpid] = useState();
  const [countryes, setcountryes] = useState();

  const singledata = (id) => {
    console.log(id, "idddddddddddd");
    setLoader(true);
    getApiWithToken(`${BaseUrl.baseUrl}customer/payer/edit/${id}`, "", userData)
      .then(({ data }) => {
        console.log("minhaj", data.data);
        setpid(id);

        setcity(data.data.city);
        setphone(data.data.phone_no);
        setstate(data.data.state);
        setzipcode(data.data.zip_code);
        setaddress(data.data.address);
        setname(data.data.first_name);
        seteinn(data.data.e_in);
        setdisregarded_entity(data.data.disregarded_entity);
        setcountryes(data.data.country);

        setLoader(false);
        // setShow1(true);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const [namer, setnamer] = useState();
  const [staterr, setstaterr] = useState();
  const [addressrr, setaddressrr] = useState();
  const [zipcoder, setzipcoder] = useState();
  const [cityrr, setcityrr] = useState();
  const [phoner, setphoner] = useState();
  const [recid, setrecid] = useState();
  const singledatar = (id) => {
    console.log(id, "idddddddddddd");
    setLoader(true);
    getApiWithToken(
      `${BaseUrl.baseUrl}customer/recipient/edit/${id}`,
      "",
      userData
    )
      .then(({ data }) => {
        console.log("minhaj", data.data.disregarded_entity);
        setrecid(id);

        setcityrr(data.data.city);
        setphoner(data.data.phone_no);
        setstaterr(data.data.state);
        setzipcoder(data.data.zip_code);
        setaddressrr(data.data.address);
        setnamer(data.data.first_name);
        setdisregarded_entity(data.data.disregarded_entity);
    

        setLoader(false);
        // setShow1(true);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };

  // recp form
  const [type, settype] = useState();
  const [e_in, sete_in] = useState();
  const [business_name, setbusiness_name] = useState();
  const [attention_to, setattention_to] = useState();
  const [line_2, setline_2] = useState();
  const [addressr, setaddressr] = useState();
  const [cityr, setcityr] = useState();
  const [stater, setstater] = useState();
  const [zip_code, setzip_code] = useState();
  const [country, setcountry] = useState();
  const [phone_no, setphone_no] = useState();
  const [email, setemail] = useState();
  const [first_name, setfirst_name] = useState();
  const [middle_name, setmiddle_name] = useState();
  const [suffix, setsuffix] = useState();
  const [last_name, setlast_name] = useState();
  const [ssn, setssn] = useState();

  const onSubmit = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);

    formData.append("type", type);
    formData.append("e_in", e_in);
    formData.append("business_name", business_name);
    formData.append("attention_to", attention_to);
    formData.append("line_2", line_2);
    formData.append("address", addressr);
    formData.append("city", cityr);
    formData.append("state", stater);
    formData.append("zip_code", zip_code);
    formData.append("country", country);
    formData.append("phone_no", phone_no);
    formData.append("email", email);

    formData.append("first_name", first_name);
    formData.append("middle_name", middle_name);
    formData.append("last_name", last_name);
    formData.append("suffix", suffix);
    formData.append("ssn", ssn);

    PostApi(`${BaseUrl.baseUrl}customer/recipient/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data, "ali");

        if (data.data.message === "Recipient Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  // misc form
  const [account_numbermisc, setaccount_numbermisc] = useState(null);
  const [fatca_filling, setfatca_filling] = useState(1);
  const [second_tin_notice, setsecond_tin_notice] = useState(1);
  const [tax_yearmisc, settax_yearmisc] = useState(null);
  const [substitute_payments, setsubstitute_payments] = useState(null);
  const [rents, setrents] = useState(1);
  const [royalties, setroyalties] = useState(null);
  const [other_income, setother_income] = useState(null);
  const [federal_income_taxmisc, setfederal_income_taxmisc] = useState(1)
  const [fishing_boat_proceeds, setfishing_boat_proceeds] = useState(null);
  const [medical_healthcare_payments, setmedical_healthcare_payments] =
    useState(null);
  const [recipient_for_resale, setrecipient_for_resale] = useState(null);
  const [crop_insurance_proceeds, setcrop_insurance_proceeds] = useState(null);
  const [gross_proceeds_paid_attorney, setgross_proceeds_paid_attorney] =
    useState(null);
  const [fish_purchased_for_resale, setfish_purchased_for_resale] = useState(null);
  const [section_def, setsection_def] = useState(null);
  const [excess_golden, setexcess_golden] = useState(null);
  const [nonqualified, setnonqualified] = useState(null);
  const [state_tax_1misc, setstate_tax_1misc] = useState(null);
  const [state_tax_2misc, setstate_tax_2misc] = useState(null);
  const [sel_1misc, setsel_1misc] = useState(null);
  const [sel_2misc, setsel_2misc] = useState(null);
  const [payer_state_1, setpayer_state_1] = useState(null);
  const [payer_state_2, setpayer_state_2] = useState(null);
  const [state_income_1misc, setstate_income_1misc] = useState(null);
  const [state_income_2misc, setstate_income_2misc] = useState(null);

  const onSubmitmisc = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numbermisc);
    formData.append("fatca_filling", fatca_filling);
    formData.append("second_tin_notice", second_tin_notice);

    formData.append("tax_year", tax_yearmisc);
    formData.append("rents", rents);
    formData.append("royalties", royalties);
    formData.append("other_income", other_income);
    formData.append("federal_income_tax", federal_income_taxmisc);
    formData.append("fishing_boat_proceeds", fishing_boat_proceeds);
    formData.append("medical_healthcare_payments", medical_healthcare_payments);
    formData.append("recipient_for_resale", recipient_for_resale);
    formData.append("substitute_payments", substitute_payments);
    formData.append("crop_insurance_proceeds", crop_insurance_proceeds);
    formData.append(
      "gross_proceeds_paid_attorney",
      gross_proceeds_paid_attorney
    );
    formData.append("fish_purchased_for_resale", fish_purchased_for_resale);
    formData.append("section_def", section_def);
    formData.append("excess_golden", excess_golden);
    formData.append("nonqualified", nonqualified);
    formData.append("state_tax_1", state_tax_1misc);
    formData.append("state_tax_2", state_tax_2misc);
    formData.append("sel_1", sel_1misc);
    formData.append("sel_2", sel_2misc);
    formData.append("payer_state_1", payer_state_1);
    formData.append("payer_state_2", payer_state_2);
    formData.append("state_income_1", state_income_1misc);
    formData.append("state_income_2", state_income_2misc);

    PostApi(`${BaseUrl.baseUrl}customer/misc/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-MISC Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  // nnc form
  const [account_number, setaccount_number] = useState();
  const [notice, setnotice] = useState(1);
  const [tax_year, settax_year] = useState();
  const [nonemployee_amount, setnonemployee_amount] = useState();
  const [recipient_resale, setrecipient_resale] = useState(1);
  const [federal_income_tax, setfederal_income_tax] = useState();
  const [state_tax_1, setstate_tax_1] = useState();
  const [state_tax_2, setstate_tax_2] = useState();
  const [sel_1, setsel_1] = useState();
  const [sel_2, setsel_2] = useState();
  const [payer_state_no_1, setpayer_state_no_1] = useState();
  const [payer_state_no_2, setpayer_state_no_2] = useState();
  const [state_income_1, setstate_income_1] = useState();
  const [state_income_2, setstate_income_2] = useState();

  const onSubmitnnc = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_number);
    formData.append("notice", notice);
    formData.append("tax_year", tax_year);
    formData.append("nonemployee_amount", nonemployee_amount);
    formData.append("recipient_resale", recipient_resale);
    formData.append("federal_income_tax", federal_income_tax);
    formData.append("state_tax_1", state_tax_1);
    formData.append("state_tax_2", state_tax_2);
    formData.append("sel_1", sel_1);
    formData.append("sel_2", sel_2);
    formData.append("payer_state_no_1", payer_state_no_1);
    formData.append("payer_state_no_2", payer_state_no_2);
    formData.append("state_income_1", state_income_1);
    formData.append("state_income_2", state_income_2);

    PostApi(`${BaseUrl.baseUrl}customer/nec/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-NEC Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  //  box
  const [box, setbox] = useState();
  // Enter letter identifying Origin of the Health Coverage
  // (see instructionsfor codes):
  const [healts, sethealths] = useState();
  // 1009a
  const [tax_yeara, settax_yeara] = useState();
  const [date_of_lender, setdate_of_lender] = useState(1);
  const [balance_of_principle, setbalance_of_principle] = useState();
  const [fair_market_value, setfair_market_value] = useState();
  const [borrower_repayment, setborrower_repayment] = useState(1);
  const [description_of_property, setdescription_of_property] = useState();

  const onSubmit009a = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("tax_year", tax_yeara);
    formData.append("date_of_lender", date_of_lender);
    formData.append("balance_of_principle", balance_of_principle);
    formData.append("fair_market_value", fair_market_value);
    formData.append("borrower_repayment", borrower_repayment);
    formData.append("description_of_property", description_of_property);

    PostApi(`${BaseUrl.baseUrl}customer/formA/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "1099a");

        if (data.data.message === "Form 1099-A Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  // 1099c
  const [account_number1009c, setaccount_number1099c] = useState();
  const [tax_year1099c, settax_year1099c] = useState(1);
  const [date_of_event, setdate_of_event] = useState();
  const [amount_discharged, setamount_discharged] = useState();
  const [interest, setinterest] = useState();
  const [debt_description, setdebt_description] = useState();
  const [repayment_debt, setrepayment_debt] = useState(1);
  const [event_code1099c, setevent_code1099c] = useState();
  const [fair_market_value1099c, setfair_market_value1099c] = useState();

  const onSubmit1099c = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_number1009c);
    formData.append("date_of_event", date_of_event);
    formData.append("tax_year", tax_year1099c);
    formData.append("amount_discharged", amount_discharged);
    formData.append("interest", interest);
    formData.append("debt_description", debt_description);
    formData.append("repayment_debt", repayment_debt);
    formData.append("event_code", event_code1099c);
    formData.append("fair_market_value", fair_market_value1099c);

    PostApi(`${BaseUrl.baseUrl}customer/formC/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-C Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099div
  const [account_numberdiv, setaccount_numberdiv] = useState();
  const [fatca_fillingdiv, setfatca_fillingdiv] = useState(0);
  const [second_tin_noticediv, setsecond_tin_noticediv] = useState(0);
  const [tax_yeardiv, settax_yeardiv] = useState();
  const [total_ordinary, settotal_ordinary] = useState();
  const [qualified_divideds, setqualified_divideds] = useState();
  const [total_capital, settotal_capital] = useState(1);
  const [unrecap, setunrecap] = useState();
  const [section_gain, setsection_gain] = useState();
  const [collectibles, setcollectibles] = useState();
  const [section_ordinary, setsection_ordinary] = useState();
  const [section_capital_gain, setsection_capital_gain] = useState();
  const [nondivided, setnondivided] = useState();
  const [federal_income, setfederal_income] = useState(1);
  const [section_dividends, setsection_dividends] = useState();
  const [investment_expenses, setinvestment_expenses] = useState();
  const [foreign_tax_paid, setforeign_tax_paid] = useState();
  const [foreign_country, setforeign_country] = useState();
  const [cash_liquidation, setcash_liquidation] = useState();
  const [noncash_liquidation, setnoncash_liquidation] = useState();
  const [exempt_interest, setexempt_interest] = useState();
  const [specified_private_activity, setspecified_private_activity] =
    useState();
  const [sel_1div, setsel_1div] = useState(1);
  const [sel_2div, setsel_2div] = useState();
  const [state_identification_1, setstate_identification_1] = useState();
  const [state_identification_2, setstate_identification_2] = useState();
  const [state_tax_1div, setstate_tax_1div] = useState(1);
  const [state_tax_2div, setstate_tax_2div] = useState();

  const onSubmit1099div = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberdiv);
    formData.append("fatca_filling", fatca_fillingdiv);
    formData.append("tax_year", tax_yeardiv);
    formData.append("total_ordinary", total_ordinary);
    formData.append("qualified_divideds", qualified_divideds);
    formData.append("total_capital", total_capital);
    formData.append("unrecap", unrecap);
    formData.append("section_gain", section_gain);
    formData.append("collectibles", collectibles);
    formData.append("section_ordinary", section_ordinary);
    formData.append("section_capital_gain", section_capital_gain);
    formData.append("nondivided", nondivided);
    formData.append("federal_income", federal_income);
    formData.append("section_dividends", section_dividends);
    formData.append("investment_expenses", investment_expenses);
    formData.append("foreign_tax_paid", foreign_tax_paid);
    formData.append("foreign_country", foreign_country);
    formData.append("cash_liquidation", cash_liquidation);
    formData.append("noncash_liquidation", noncash_liquidation);
    formData.append("exempt_interest", exempt_interest);
    formData.append("specified_private_activity", specified_private_activity);
    formData.append("sel_1", sel_1div);
    formData.append("sel_2", sel_2div);
    formData.append("state_identification_1", state_identification_1);
    formData.append("state_identification_2", state_identification_2);
    formData.append("state_tax_1", state_tax_1div);
    formData.append("state_tax_2", state_tax_2div);
    formData.append("second_tin_notice", second_tin_noticediv);

    PostApi(`${BaseUrl.baseUrl}customer/formDIV/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-DIV Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099S
  const [account_numbers, setaccount_numbers] = useState();
  const [tax_years, settax_years] = useState(1);
  const [date_of_closing, setdate_of_closing] = useState();
  const [gross_proceed, setgross_proceed] = useState();
  const [address_legal_description, setaddress_legal_description] = useState();
  const [consideration, setconsideration] = useState(1);
  const [foreign_partnership, setforeign_partnership] = useState(1);
  const [buyer_part_of_real_estate_tax, setbuyer_part_of_real_estate_tax] =
    useState();

  const onSubmit1099s = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numbers);
    formData.append("date_of_closing", date_of_closing);
    formData.append("tax_year", tax_years);
    formData.append("gross_proceed", gross_proceed);
    formData.append("address_legal_description", address_legal_description);
    formData.append("consideration", consideration);
    formData.append("foreign_partnership", foreign_partnership);
    formData.append(
      "buyer_part_of_real_estate_tax",
      buyer_part_of_real_estate_tax
    );

    PostApi(`${BaseUrl.baseUrl}customer/formS/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-S Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // Form 1099-LS
  const [policy_number, setpolicy_number] = useState();
  const [tax_yearls, settax_yearls] = useState();
  const [
    amount_paid_to_payment_recipient,
    setamount_paid_to_payment_recipient,
  ] = useState();
  const [date_of_salels, setdate_of_salels] = useState();
  const [issuer_namels, setissuer_namels] = useState();
  const [acquirer_informationls, setacquirer_informationls] = useState();

  const onSubmit1099ls = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("policy_number", policy_number);
    formData.append(
      "amount_paid_to_payment_recipient",
      amount_paid_to_payment_recipient
    );
    formData.append("tax_year", tax_yearls);
    formData.append("date_of_sale", date_of_salels);
    formData.append("address_legal_description", address_legal_description);
    formData.append("issuer_name", issuer_namels);
    formData.append("acquirer_information", acquirer_informationls);

    PostApi(`${BaseUrl.baseUrl}customer/formLS/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-LS Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // Form 1099-SA
  const [account_numbersa, setaccount_numbersa] = useState();
  const [tax_yearsa, settax_yearsa] = useState();
  const [gross_distribution, setgross_distribution] = useState();
  const [earnings_on_excess_cont, setearnings_on_excess_cont] = useState();
  const [distribution_code, setdistribution_code] = useState();
  const [fmv_date_of_death, setfmv_date_of_death] = useState();
  const [hsa, sethsa] = useState();

  const onSubmit1099sa = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", setaccount_numbersa);
    formData.append("gross_distribution", gross_distribution);
    formData.append("tax_year", tax_yearsa);
    formData.append("earnings_on_excess_cont", earnings_on_excess_cont);
    formData.append("distribution_code", distribution_code);
    formData.append("fmv_date_of_death", fmv_date_of_death);
    formData.append("hsa", hsa);

    PostApi(`${BaseUrl.baseUrl}customer/formSA/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-SA Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  //  form1098
  const [account_number8, setaccount_number8] = useState();
  const [tax_year8, settax_year8] = useState();
  const [
    mortgage_interest_received_from_payer,
    setmortgage_interest_received_from_payer,
  ] = useState();
  const [outstanding_mortgage_principal, setoutstanding_mortgage_principal] =
    useState();
  const [mortgage_origination_date, setmortgage_origination_date] = useState();
  const [refund_of_overpaid_interest, setrefund_of_overpaid_interest] =
    useState();
  const [mortgage_insurance_premiums, setmortgage_insurance_premiums] =
    useState();
  const [
    points_paid_on_purchase_of_principal_residence,
    setpoints_paid_on_purchase_of_principal_residence,
  ] = useState();

  const [
    if_address_of_property_securing_mortgage,
    setif_address_of_property_securing_mortgage,
  ] = useState(1);
  const [
    address_or_description_of_property_securing_mortgage,
    setaddress_or_description_of_property_securing_mortgage,
  ] = useState(1);
  const [other, setother] = useState();
  const [mortgage_acquisition_date, setmortgage_acquisition_date] = useState();
  const [
    no_of_properties_securing_the_mortgage,
    setno_of_properties_securing_the_mortgage,
  ] = useState();

  const onSubmit1098 = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_number8);
    formData.append(
      "mortgage_interest_received_from_payer",
      mortgage_interest_received_from_payer
    );
    formData.append("tax_year", tax_year8);
    formData.append("refund_of_overpaid_interest", refund_of_overpaid_interest);
    formData.append("mortgage_insurance_premiums", mortgage_insurance_premiums);
    formData.append(
      "points_paid_on_purchase_of_principal_residence",
      points_paid_on_purchase_of_principal_residence
    );
    formData.append(
      "outstanding_mortgage_principal",
      outstanding_mortgage_principal
    );
    formData.append("mortgage_origination_date", mortgage_origination_date);
    formData.append(
      "if_address_of_property_securing_mortgage",
      if_address_of_property_securing_mortgage
    );
    formData.append(
      "address_or_description_of_property_securing_mortgage",
      address_or_description_of_property_securing_mortgage
    );
    formData.append("other", other);
    formData.append("mortgage_acquisition_date", mortgage_acquisition_date);
    formData.append(
      "no_of_properties_securing_the_mortgage",
      no_of_properties_securing_the_mortgage
    );

    PostApi(`${BaseUrl.baseUrl}customer/form1098/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1098 Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  //  form1098c
  const [date_of_contribution, setdate_of_contribution] = useState();
  const [tax_year8c, settax_year8c] = useState();
  const [odometer_mileage, setodometer_mileage] = useState();
  const [year, setyear] = useState();
  const [make, setmake] = useState();
  const [model, setmodel] = useState();
  const [
    vehicle_or_other_identification_number,
    setvehicle_or_other_identification_number,
  ] = useState();
  const [donee_certifies, setdonee_certifies] = useState(1);
  const [date_of_sale, setdate_of_sale] = useState();
  const [gross_proceeds_from_sale, setgross_proceeds_from_sale] = useState(1);
  const [intervening_use, setintervening_use] = useState(1);
  const [charitable_purpose, setcharitable_purpose] = useState(1);
  const [donee_certifies_description, setdonee_certifies_description] =
    useState();
  const [under_the_law, setunder_the_law] = useState(1);
  const [
    did_you_provide_goods_services_exchange_for_the_vehicle,
    setdid_you_provide_goods_services_exchange_for_the_vehicle,
  ] = useState(1);
  const [
    value_of_goods_exchange_for_the_vehicle,
    setvalue_of_goods_exchange_for_the_vehicle,
  ] = useState();
  const [describe_the_goods_and_services, setdescribe_the_goods_and_services] =
    useState();

  const onSubmit1098c = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("date_of_contribution", date_of_contribution);
    formData.append("odometer_mileage", odometer_mileage);
    formData.append("tax_year", tax_year8c);
    formData.append("make", make);
    formData.append("year", year);
    formData.append(
      "vehicle_or_other_identification_number",
      vehicle_or_other_identification_number
    );
    formData.append("donee_certifies", donee_certifies);
    formData.append("model", model);
    formData.append("date_of_sale", date_of_sale);
    formData.append("gross_proceeds_from_sale", gross_proceeds_from_sale);
    formData.append("intervening_use", intervening_use);
    formData.append("charitable_purpose", charitable_purpose);
    formData.append("donee_certifies_description", donee_certifies_description);
    formData.append("under_the_law", under_the_law);
    formData.append(
      "did_you_provide_goods_services_exchange_for_the_vehicle",
      did_you_provide_goods_services_exchange_for_the_vehicle
    );
    formData.append(
      "value_of_goods_exchange_for_the_vehicle",
      value_of_goods_exchange_for_the_vehicle
    );
    formData.append(
      "describe_the_goods_and_services",
      describe_the_goods_and_services
    );

    PostApi(`${BaseUrl.baseUrl}customer/form1098C/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1098-C Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  //  form1098t
  const [service_provider, setservice_provider] = useState();
  const [tax_yeart, settax_yeart] = useState();
  const [
    checked_if_at_least_half_time_student,
    setchecked_if_at_least_half_time_student,
  ] = useState(1);
  const [
    payments_received_for_qualified_tuition_and_related_expenses,
    setpayments_received_for_qualified_tuition_and_related_expenses,
  ] = useState();
  const [adjustments_made_for_prior_year, setadjustments_made_for_prior_year] =
    useState();
  const [scholarships_or_grants, setscholarships_or_grants] = useState();
  const [
    adjustments_to_scholarships_grants_prior_year,
    setadjustments_to_scholarships_grants_prior_year,
  ] = useState();
  const [contract_refund, setcontract_refund] = useState();
  const [academic_period_beginning, setacademic_period_beginning] = useState(1);
  const [checked_graduate_student, setchecked_graduate_student] = useState(1);

  const onSubmit1098t = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("service_provider", service_provider);
    formData.append("tax_year", tax_yeart);
    formData.append(
      "checked_if_at_least_half_time_student",
      checked_if_at_least_half_time_student
    );
    formData.append(
      "payments_received_for_qualified_tuition_and_related_expenses",
      payments_received_for_qualified_tuition_and_related_expenses
    );
    formData.append(
      "adjustments_made_for_prior_year",
      adjustments_made_for_prior_year
    );
    formData.append("scholarships_or_grants", scholarships_or_grants);
    formData.append(
      "adjustments_to_scholarships_grants_prior_year",
      adjustments_to_scholarships_grants_prior_year
    );
    formData.append("contract_refund", contract_refund);
    formData.append("checked_graduate_student", checked_graduate_student);
    formData.append("academic_period_beginning", academic_period_beginning);

    PostApi(`${BaseUrl.baseUrl}customer/form1098T/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1098-T Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099int
  const [account_numberint, setaccount_numberint] = useState();
  const [noticeint, setnoticeint] = useState(0);
  const [fatca_fillingint, setfatca_fillingint] = useState(1);
  const [tax_yearint, settax_yearint] = useState();
  const [payers_rtn, setpayers_rtn] = useState();
  const [interest_income, setinterest_income] = useState();
  const [early_withdrawal_penalty, setearly_withdrawal_penalty] = useState(1);
  const [interest_savings_bonds, setinterest_savings_bonds] = useState();
  const [federal_income_taxint, setfederal_income_taxint] = useState(1);
  const [investment_expensesint, setinvestment_expensesint] = useState(1);
  const [foreign_tax_paidint, setforeign_tax_paidint] = useState(1);
  const [foreign_countryint, setforeign_countryint] = useState();
  const [taxexempt_interest, settaxexempt_interest] = useState();
  const [
    specified_private_activity_bond_interest,
    setspecified_private_activity_bond_interest,
  ] = useState();
  const [market_discount, setmarket_discount] = useState();
  const [bond_premium_on_taxexempt_bond, setbond_premium_on_taxexempt_bond] =
    useState();
  const [taxexempt, settaxexempt] = useState();
  const [sel_1int, setsel_1int] = useState();
  const [sel_2int, setsel_2int] = useState();
  const [identification_1int, setidentification_1int] = useState();
  const [identification_2int, setidentification_2int] = useState();
  const [state_income_tax_1int, setstate_income_tax_1int] = useState();
  const [state_income_tax_2int, setstate_income_tax_2int] = useState();
  const [bond_treasury, setbond_treasury] = useState();
  const [bond_exempt, setbond_exempt] = useState();

  const onSubmit1099int = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberint);
    formData.append("fatca_filling", fatca_fillingint);
    formData.append("tax_year", tax_yearint);
    formData.append("noticeint", noticeint);
    formData.append("payers_rtn", payers_rtn);
    formData.append("interest_income", interest_income);
    formData.append("early_withdrawal_penalty", early_withdrawal_penalty);
    formData.append("interest_savings_bonds", interest_savings_bonds);
    formData.append("federal_income_tax", federal_income_taxint);
    formData.append("investment_expenses", investment_expensesint);
    formData.append("foreign_tax_paid", foreign_tax_paidint);
    formData.append("foreign_country", foreign_country);
    formData.append("taxexempt_interest", taxexempt_interest);
    formData.append(
      "specified_private_activity_bond_interest",
      specified_private_activity_bond_interest
    );
    formData.append("market_discount", market_discount);
    formData.append(
      "bond_premium_on_taxexempt_bond",
      bond_premium_on_taxexempt_bond
    );
    formData.append("taxexempt", taxexempt);
    formData.append("sel_1", sel_1int);
    formData.append("sel_2", sel_2int);
    formData.append("identification_1", identification_1int);
    formData.append("identification_2", identification_2int);
    formData.append("state_income_tax_1", state_income_tax_1int);
    formData.append("state_income_tax_2", state_income_tax_2int);
    formData.append("bond_treasury", bond_treasury);
    formData.append("bond_exempt", bond_exempt);

    PostApi(`${BaseUrl.baseUrl}customer/formINT/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-INT Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099k
  const [pses_name, setpses_name] = useState();
  const [noticek, setnoticek] = useState(0);
  const [pses_telephone_number, setpses_telephone_number] = useState(1);
  const [tax_yeark, settax_yeark] = useState();
  const [account_numberk, setaccount_numberk] = useState();
  const [gross_amount_of_payment, setgross_amount_of_payment] = useState();
  const [card_not_present_transactions, setcard_not_present_transactions] =
    useState(1);
  const [merchant_category_code, setmerchant_category_code] = useState();
  const [number_of_payment_transactions, setnumber_of_payment_transactions] =
    useState(1);
  const [federal_income_taxk, setfederal_income_taxk] = useState();
  const [january, setjanuary] = useState();
  const [february, setfebruary] = useState();
  const [march, setmarch] = useState();
  const [april, setapril] = useState();
  const [may, setmay] = useState();
  const [june, setjune] = useState();
  const [july, setjuly] = useState();
  const [august, setaugust] = useState();
  const [september, setseptember] = useState();
  const [october, setoctober] = useState();
  const [november, setnovember] = useState();
  const [december, setdecember] = useState();
  const [state_income_tax_1k, setstate_income_tax_1k] = useState();
  const [state_income_tax_2k, setstate_income_tax_2k] = useState();
  const [sel_1k, setsel_1k] = useState();
  const [sel_2k, setsel_2k] = useState();
  const [identification_1k, setidentification_1k] = useState();
  const [identification_2k, setidentification_2k] = useState();

  const onSubmit1099k = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberk);
    formData.append("pses_name", pses_name);
    formData.append("tax_year", tax_yeark);
    formData.append("notice", noticek);
    formData.append("pses_telephone_number", pses_telephone_number);
    formData.append("gross_amount_of_payment", gross_amount_of_payment);
    formData.append(
      "card_not_present_transactions",
      card_not_present_transactions
    );
    formData.append(
      "number_of_payment_transactions",
      number_of_payment_transactions
    );
    formData.append("merchant_category_code", merchant_category_code);
    formData.append("federal_income_tax", federal_income_taxk);
    formData.append("january", january);
    formData.append("february", february);
    formData.append("march", march);
    formData.append("april", april);
    formData.append("may", may);
    formData.append("june", june);
    formData.append("july", july);
    formData.append("august", august);
    formData.append("september", september);
    formData.append("october", october);
    formData.append("november", november);
    formData.append("december", december);
    formData.append("sel_1", sel_1k);
    formData.append("sel_2", sel_2k);
    formData.append("identification_1", identification_1k);
    formData.append("identification_2", identification_2k);
    formData.append("state_income_tax_1", state_income_tax_1k);
    formData.append("state_income_tax_2", state_income_tax_2k);

    PostApi(`${BaseUrl.baseUrl}customer/formK/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-K Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099oid
  const [
    original_issue_discount_for_the_year,
    setoriginal_issue_discount_for_the_year,
  ] = useState();
  const [noticeoid, setnoticeoid] = useState(1);
  const [fatca_fillingoid, setfatca_fillingoid] = useState(1);
  const [tax_yearoid, settax_yearoid] = useState();
  const [account_numberoid, setaccount_numberoid] = useState();
  const [other_periodic_interest, setother_periodic_interest] = useState();
  const [market_discountoid, setmarket_discountoid] = useState();
  const [federal_income_taxoid, setfederal_income_taxoid] = useState();
  const [acquisition_premium, setacquisition_premium] = useState();
  const [description, setdescription] = useState();
  const [
    original_issue_discount_on_us_treasury_obligations,
    setoriginal_issue_discount_on_us_treasury_obligations,
  ] = useState(1);
  const [investment_expensesoid, setinvestment_expensesoid] = useState();
  const [bond_premium, setbond_premium] = useState();
  const [taxexempt_OID, settaxexempt_OID] = useState();
  const [sel_1oid, setsel_1oid] = useState();
  const [sel_2oid, setsel_2oid] = useState();
  const [identification_1oid, setidentification_1oid] = useState();
  const [identification_2oid, setidentification_2oid] = useState();
  const [state_income_tax_1oid, setstate_income_tax_1oid] = useState();
  const [state_income_tax_2oid, setstate_income_tax_2oid] = useState();

  const onSubmit1099oid = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberoid);
    formData.append("tax_year", tax_yeark);
    formData.append("notice", noticek);

    formData.append("federal_income_tax", federal_income_taxoid);
    formData.append("acquisition_premium", acquisition_premium);

    formData.append("sel_1", sel_1oid);
    formData.append("sel_2", sel_2oid);
    formData.append("identification_1", identification_1oid);
    formData.append("identification_2", identification_2oid);
    formData.append("state_income_tax_1", state_income_tax_1oid);
    formData.append("state_income_tax_2", state_income_tax_2oid);

    PostApi(`${BaseUrl.baseUrl}customer/formOID/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-OID Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  // 1099part
  const [account_numberpart, setaccount_numberpart] = useState();
  const [noticepart, setnoticepart] = useState(1);
  const [patronage_dividend, setpatronage_dividend] = useState(1);
  const [tax_yearpart, settax_yearpart] = useState();
  const [nonpatronage_distributions, setnonpatronage_distributions] =
    useState();
  const [federal_income_tax_withheldpart, setfederal_income_tax_withheldpart] =
    useState();
  const [perunit_retain_allocations, setperunit_retain_allocations] =
    useState();
  const [redeemed_nonqualified_notices, setredeemed_nonqualified_notices] =
    useState();
  const [section_deduction, setsection_deduction] = useState();
  const [qualified_payments, setqualified_payments] = useState();
  const [section_qual_items, setsection_qual_items] = useState();
  const [section_SSTB_items, setsection_SSTB_items] = useState();
  const [investment_credit, setinvestment_credit] = useState();
  const [work_opportunity_credit, setwork_opportunity_credit] = useState();
  const [other_credits_and_deductions, setother_credits_and_deductions] =
    useState();
  const [specified_coop, setspecified_coop] = useState(1);

  const onSubmit1099part = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberpart);
    formData.append("tax_year", tax_yearpart);
    formData.append("notice", noticepart);
    formData.append("perunit_retain_allocations", perunit_retain_allocations);
    formData.append("patronage_dividend", patronage_dividend);
    formData.append("nonpatronage_distributions", nonpatronage_distributions);
    formData.append(
      "federal_income_tax_withheld",
      federal_income_tax_withheldpart
    );
    formData.append(
      "redeemed_nonqualified_notices",
      redeemed_nonqualified_notices
    );
    formData.append("section_deduction", section_deduction);
    formData.append("qualified_payments", qualified_payments);
    formData.append("section_qual_items", section_qual_items);
    formData.append("section_SSTB_items", section_SSTB_items);
    formData.append("investment_credit", investment_credit);
    formData.append("work_opportunity_credit", work_opportunity_credit);
    formData.append(
      "other_credits_and_deductions",
      other_credits_and_deductions
    );
    formData.append("qualified_payments", qualified_payments);
    formData.append("specified_coop", specified_coop);

    PostApi(`${BaseUrl.baseUrl}customer/formPATR/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-PATR Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099r
  const [account_numberr, setaccount_numberr] = useState();
  const [year_of_desig_roth_contrib, setyear_of_desig_roth_contrib] =
    useState(1);
  const [
    amount_allocable_to_IRR_within_years,
    setamount_allocable_to_IRR_within_years,
  ] = useState(1);
  const [tax_yearr, settax_yearr] = useState();
  const [fatca_fillingr, setfatca_fillingr] = useState(1);
  const [date_of_payment, setdate_of_payment] = useState();
  const [gross_distributionr, setgross_distributionr] = useState();
  const [taxable_amount, settaxable_amount] = useState();
  const [taxable_amount_not_determined, settaxable_amount_not_determined] =
    useState();
  const [total_distribution, settotal_distribution] = useState();
  const [capital_gain, setcapital_gain] = useState();
  const [federal_income_taxr, setfederal_income_taxr] = useState();
  const [employee_contributions, setemployee_contributions] = useState();
  const [
    onet_unrealized_appreciation_in_employers_securities,
    setnet_unrealized_appreciation_in_employers_securities,
  ] = useState();
  const [distribution_coder, setdistribution_coder] = useState();
  const [otherr, setotherr] = useState();
  const [percent, setpercent] = useState();
  const [
    your_percentage_of_total_distribution,
    setyour_percentage_of_total_distribution,
  ] = useState();
  const [total_employee_contributions, settotal_employee_contributions] =
    useState();
  const [state_tax_1r, setstate_tax_1r] = useState();
  const [state_tax_2r, setstate_tax_2r] = useState();
  const [sel_1r, setsel_1r] = useState();
  const [sel_2r, setsel_2r] = useState();
  const [state_no_1r, setstate_no_1r] = useState();
  const [state_no_2r, setstate_no_2r] = useState();
  const [state_distribution_1r, setstate_distribution_1r] = useState();
  const [state_distribution_2r, setstate_distribution_2r] = useState();
  const [local_tax_1, setlocal_tax_1] = useState();
  const [local_tax_2, setlocal_tax_2] = useState();
  const [name_locality_1, setname_locality_1] = useState();
  const [name_locality_2, setname_locality_2] = useState();
  const [local_distribution_1, setlocal_distribution_1] = useState();
  const [local_distribution_2, setlocal_distribution_2] = useState();

  const onSubmit1099r = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberr);
    formData.append("tax_year", tax_yearr);
    formData.append("year_of_desig_roth_contrib", year_of_desig_roth_contrib);
    formData.append(
      "amount_allocable_to_IRR_within_years",
      amount_allocable_to_IRR_within_years
    );
    formData.append("fatca_filling", fatca_fillingr);
    formData.append("date_of_payment", date_of_payment);
    formData.append("gross_distribution", gross_distributionr);
    formData.append("taxable_amount", taxable_amount);
    formData.append(
      "taxable_amount_not_determined",
      taxable_amount_not_determined
    );
    formData.append("total_distribution", total_distribution);
    formData.append("capital_gain", capital_gain);
    formData.append("federal_income_tax", federal_income_taxr);
    formData.append("employee_contributions", employee_contributions);
    formData.append(
      "onet_unrealized_appreciation_in_employers_securities",
      onet_unrealized_appreciation_in_employers_securities
    );
    formData.append("distribution_code", distribution_coder);
    formData.append("other", otherr);

    formData.append("percent", percent);
    formData.append(
      "your_percentage_of_total_distribution",
      your_percentage_of_total_distribution
    );
    formData.append(
      "total_employee_contributions",
      total_employee_contributions
    );
    formData.append("state_tax_1", state_tax_1r);
    formData.append("state_tax_2", state_tax_2r);
    formData.append("state_no_1", state_no_1r);
    formData.append("state_no_2", state_no_2r);
    formData.append("sel_1", sel_1r);
    formData.append("sel_2", sel_2r);
    formData.append("state_distribution_1", state_distribution_1r);
    formData.append("state_distribution_2", state_distribution_2r);
    formData.append("local_tax_1", local_tax_1);
    formData.append("local_tax_2", local_tax_2);
    formData.append("name_locality_1", name_locality_1);
    formData.append("name_locality_2", name_locality_2);
    formData.append("local_distribution_1", local_distribution_1);
    formData.append("local_distribution_2", local_distribution_2);

    PostApi(`${BaseUrl.baseUrl}customer/form1099R/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-R Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1099 g
  const [account_numberg, setaccount_numberg] = useState();
  const [noticeg, setnoticeg] = useState(0);
  const [unemployment_compensation, setunemployment_compensation] = useState();
  const [tax_yearg, settax_yearg] = useState();
  const [state_or_local_income, setstate_or_local_income] = useState();
  const [amount_is_for_tax_year, setamount_is_for_tax_year] = useState();
  const [federal_income_taxg, setfederal_income_taxg] = useState(1);
  const [rtaa_payments, setrtaa_payments] = useState();
  const [taxable_grants, settaxable_grants] = useState();
  const [agriculture_payments, setagriculture_payments] = useState();
  const [check_business_income, setcheck_business_income] = useState(1);
  const [market_gain, setmarket_gain] = useState();
  const [sel_1g, setsel_1g] = useState();
  const [sel_2g, setsel_2g] = useState();
  const [identification_1g, setidentification_1g] = useState();
  const [identification_2g, setidentification_2g] = useState();
  const [state_income_tax_1g, setstate_income_tax_1g] = useState();
  const [state_income_tax_2g, setstate_income_tax_2g] = useState();

  const onSubmit1099g = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numberg);
    formData.append("unemployment_compensation", unemployment_compensation);
    formData.append("tax_year", tax_yearg);
    formData.append("notice", noticeg);
    formData.append("state_or_local_income", state_or_local_income);
    formData.append("federal_income_tax", federal_income_taxg);
    formData.append("rtaa_payments", rtaa_payments);
    formData.append("taxable_grants", taxable_grants);
    formData.append("agriculture_payments", agriculture_payments);
    formData.append("market_gain", market_gain);
    formData.append("check_business_income", check_business_income);
    formData.append("sel_1", sel_1g);
    formData.append("sel_2", sel_2g);
    formData.append("identification_1", identification_1g);
    formData.append("identification_2", identification_2g);
    formData.append("state_income_tax_1", state_income_tax_1g);
    formData.append("state_income_tax_2", state_income_tax_2g);

    PostApi(`${BaseUrl.baseUrl}customer/formG/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1099-G Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 1098 E
  const [account_numbere, setaccount_numbere] = useState();
  const [tax_yeare, settax_yeare] = useState();
  const [
    student_loan_interest_received_by_lender,
    setstudent_loan_interest_received_by_lender,
  ] = useState();
  const [
    check_include_loan_origination_fees,
    setcheck_include_loan_origination_fees,
  ] = useState(1);
  const onSubmit1098e = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_numbere);
    formData.append(
      "student_loan_interest_received_by_lender",
      student_loan_interest_received_by_lender
    );
    formData.append("tax_year", tax_yeare);
    formData.append(
      "check_include_loan_origination_fees",
      check_include_loan_origination_fees
    );

    PostApi(`${BaseUrl.baseUrl}customer/form1098E/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1098-E Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  // 3921
  const [account_number3921, setaccount_number3921] = useState();
  const [tax_year3921, settax_year3921] = useState();
  const [date_option_granted3921, setdate_option_granted3921] = useState();
  const [date_option_exercised3921, setdate_option_exercised3921] = useState();
  const [exercise_price_per_share3921, setexercise_price_per_share3921] =
    useState();
  const [fair_market_value3921, setfair_market_value3921] = useState();
  const [no_of_shares_transferred3921, setno_of_shares_transferred3921] =
    useState();
  const [transferred3921, settransferred3921] = useState();

  const onSubmit3921 = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_number3921);
    formData.append("date_option_granted", date_option_granted3921);
    formData.append("tax_year", tax_year3921);
    formData.append("date_option_exercised", date_option_exercised3921);
    formData.append("fair_market_value", fair_market_value3921);
    formData.append("transferred", transferred3921);
    formData.append("no_of_shares_transferred", no_of_shares_transferred3921);
    formData.append("exercise_price_per_share", exercise_price_per_share3921);

    PostApi(`${BaseUrl.baseUrl}customer/form3921/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 3921 Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // 3922
  const [account_number3922, setaccount_number3922] = useState();
  const [tax_year3922, settax_year3922] = useState();
  const [date_option_granted, setdate_option_granted] = useState();
  const [date_option_exercised, setdate_option_exercised] = useState();
  const [fair_market_value_grant_date, setfair_market_value_grant_date] =
    useState();
  const [fair_market_value_exercise_date, setfair_market_value_exercise_date] =
    useState();
  const [exercise_price_per_share, setexercise_price_per_share] = useState();
  const [no_of_shares_transferred, setno_of_shares_transferred] = useState();
  const [date_legal, setdate_legal] = useState();
  const [
    exercise_price_per_share_determined,
    setexercise_price_per_share_determined,
  ] = useState();

  const onSubmit3922 = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("account_number", account_number3922);
    formData.append("date_option_granted", date_option_granted);
    formData.append("tax_year", tax_year3922);
    formData.append("date_option_exercised", date_option_exercised);
    formData.append(
      "fair_market_value_grant_date",
      fair_market_value_grant_date
    );
    formData.append(
      "fair_market_value_exercise_date",
      fair_market_value_exercise_date
    );
    formData.append("exercise_price_per_share", exercise_price_per_share);
    formData.append("no_of_shares_transferred", no_of_shares_transferred);
    formData.append("date_legal", date_legal);
    formData.append(
      "exercise_price_per_share_determined",
      exercise_price_per_share_determined
    );

    PostApi(`${BaseUrl.baseUrl}customer/form3922/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 3922 Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

   // Form 1095-B

   
   

  //  const handleChange = (event) => {
  //    const { name, checked } = event.target;
  //    if (checked) {
  //      setcovered_twelve_months_1([...covered_twelve_months_1, name]);
  //    } else {
  //      setcovered_twelve_months_1(covered_twelve_months_1.filter((box) => box !== name));
  //    }
  //  };



   const [first_name_1, setfirst_name_1] = useState();
   const [tax_yearb, settax_yearb] = useState();
   const [middle_name_1, setmiddle_name_1] = useState();
   const [last_name_1, setlast_name_1] = useState();
   const [dob, setdob] = useState();
   const [stage_1, setstage_1] = useState();
   const [first_name_2, setfirst_name_2] = useState();
   const [middle_name_2, setmiddle_name_2] = useState();
   const [last_name_2, setlast_name_2] = useState();
   const [stage_2, setstage_2] = useState();
   const [dob_1, setdob_1,] = useState();
   const [ssn_1, setssn_1,] = useState();
   const [dob_2, setdob_2,] = useState();
   const [ssn_2, setssn_2,] = useState();
   const [covered_twelve_months_1, setcovered_twelve_months_1] = useState();
   const [health_coverage, sethealth_coverage] = useState();
   const [covered_twelve_months_2, setcovered_twelve_months_2] = useState();
 
   const onSubmit1095b = () => {
     setLoader(true);
     const formData = new FormData();
     formData.append("customer_id", idc);
     formData.append("payer_id", pid);
     formData.append("recipient_id",recid);
     formData.append("dob", dob);
     formData.append("first_name_1", first_name_1);
     formData.append("middle_name_1", middle_name_1);
     formData.append("last_name_1", last_name_1);
     formData.append("tax_year", tax_yearb);
     formData.append("stage_1", stage_1);
     formData.append("first_name_2", first_name_2);
     formData.append("stage_2", stage_2);
     formData.append("dob_1", dob_1);
     formData.append("middle_name_2", middle_name_2);
     formData.append("health_coverage", health_coverage);
     formData.append("last_name_2", last_name_2);
     formData.append("ssn_1", ssn_1);
     formData.append("dob_2", dob_2);
     formData.append("ssn_2", ssn_2);
     formData.append("covered_twelve_months_1", covered_twelve_months_1);
     formData.append("covered_twelve_months_2", covered_twelve_months_2);
    
 
     PostApi(`${BaseUrl.baseUrl}customer/form1095B/store`, formData, userData)
       .then((data) => {
         setLoader(false);
         console.log(data.data.pdf_url, "nnc");
 
         if (data.data.message === "Form 1095-B Successfully Created") {
           Swal.fire({
             title: "success",
             text: data.data.message,
             icon: "success",
             confirmButtonColor: "#29BF12",
             timer: 1500,
           });
           window.location.href = data.data.pdf_url;
 
           getDatar();
           setShowr(false);
 
           // console.log(data.data,"minhaj")
           //   console.log("heheh",data.data.user.name)
           // console.log("name", data.data.accessToken.name)
           // console.log("hahahahaha",data.status)
           // console.log("hahahahaha",data.message)
 
           // localStorage.setItem("token", data.data.accessToken.name);
           // localStorage.setItem("username", data.data.user.name);
           // console.log("login");
           // navigate("/");
         } else {
           // console.log("else========", data.message);
           // // alert(data.data);
           // localStorage.setItem("tokenen", data.data);
           console.log("not login");
           // Swal.fire({
           //   title: "Oops",
           //   text: "Error",
           //   icon: "error",
           //   confirmButtonColor: "#29BF12",
           // });
           console.log(data.data.errors.DOB, "DOB error");
 
           // setDateError(data.data.errors.DOB, "Gender error");
           // setGenderError(data.data.errors.gender, "Gender error");
           // setPasswordError(data.data.errors.password, "Password Error");
           // setPasswordConError(
           //   data.data.errors.passwordConfirmation,
           //   "Password Con Error"
           // );
           // setEmailError(data.data.errors.email, "Email Error");
         }
       })
       .catch((error) => {
         Swal.fire({
           title: "error",
           text: error,
           icon: "error",
           confirmButtonColor: "#29BF12",
           timer: 1500,
         });
       });
   };

    // Form 1095 C

   
   

  //  const handleChange = (event) => {
  //    const { name, checked } = event.target;
  //    if (checked) {
  //      setcovered_twelve_months_1([...covered_twelve_months_1, name]);
  //    } else {
  //      setcovered_twelve_months_1(covered_twelve_months_1.filter((box) => box !== name));
  //    }
  //  };



  const [employee_dob, setemployee_dob] = useState();
  const [tax_year1095c, settax_year1095c] = useState();
  const [plan_start_date, setplan_start_date] = useState();
  const [all_covered_twelve_months, setall_covered_twelve_months] = useState();
  const [jan1095c, setjan1095c] = useState();
  const [feb1095c, setfeb1095c] = useState();
  const [mar1095c, setmar1095c] = useState();
  const [apr1095c, setapr1095c] = useState();
  const [may1095c, setmay1095c] = useState();
  const [june1095c, setjune1095c] = useState();
  const [july1095c, setjuly1095c] = useState();
  const [aug1095c, setaug1095c] = useState();
  const [sept1095c, setsept1095c] = useState();
  const [oct1095c, setoct1095c] = useState();
  const [nov1095c, setnov1095c] = useState();
  const [dec1095c, setdec1095c] = useState();
  const [employee_contribution_1, setemployee_contribution_1] = useState();
  const [employee_contribution_2, setemployee_contribution_2] = useState();
  const [employee_contribution_3, setemployee_contribution_3] = useState();
  const [employee_contribution_4, setemployee_contribution_4] = useState();
  const [employee_contribution_5, setemployee_contribution_5] = useState();
  const [employee_contribution_6, setemployee_contribution_6] = useState();
  const [employee_contribution_7, setemployee_contribution_7] = useState();
 const [employee_contribution_8, setemployee_contribution_8] = useState();
  const [employee_contribution_9, setemployee_contribution_9] = useState();
  const [employee_contribution_10, setemployee_contribution_10] = useState();
  const [employee_contribution_11, setemployee_contribution_11] = useState();
  const [employee_contribution_12, setemployee_contribution_12] = useState();
  const [safe_harbor_1, setsafe_harbor_1] = useState();
 const [safe_harbor_2, setsafe_harbor_2] = useState();
  const [safe_harbor_3, setsafe_harbor_3] = useState();
  const [safe_harbor_4, setsafe_harbor_4] = useState();
  const [safe_harbor_5, setsafe_harbor_5] = useState();
  const [safe_harbor_6, setsafe_harbor_6] = useState();
  const [safe_harbor_7, setsafe_harbor_7] = useState();
  const [safe_harbor_8, setsafe_harbor_8] = useState();
  const [safe_harbor_9, setsafe_harbor_9] = useState();
  const [safe_harbor_10, setsafe_harbor_10] = useState();
  const [safe_harbor_11, setsafe_harbor_11] = useState();
  const [safe_harbor_12, setsafe_harbor_12] = useState();
  const [zip_code_1, setzip_code_1] = useState();
  const [zip_code_2, setzip_code_2] = useState();
  const [zip_code_3, setzip_code_3] = useState();
  const [zip_code_4, setzip_code_4] = useState();
  const [zip_code_5, setzip_code_5] = useState();
  const [zip_code_6, setzip_code_6] = useState();
  const [zip_code_7, setzip_code_7] = useState();
  const [zip_code_8, setzip_code_8] = useState();
  const [zip_code_9, setzip_code_9] = useState();
  const [zip_code_10, setzip_code_10] = useState();
  const [zip_code_11, setzip_code_11] = useState();
  const [zip_code_12, setzip_code_12] = useState();
  const [first_name_11095c, setfirst_name_11095c] = useState();
  const [middle_name_11095c, setmiddle_name_11095c] = useState();
  const [last_name_11095c, setlast_name_11095c] = useState();
  // const [stage_11095c, setstage_11095c] = useState();
  const [ssn_11095c, setssn_11095c] = useState();
  const [dob_11095c, setdob_11095c] = useState();
  const [covered_twelve_months_11095c, setcovered_twelve_months_11095c] = useState();
  // const [covered_twelve_months_21095c, setcovered_twelve_months_21095c] = useState();
  console.log(recid, 'eeeee')

  const onSubmit1095c = () => {
   setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    // formData.append("recipient_id", recid);
    formData.append("employee_dob", employee_dob);
    formData.append("plan_start_date", plan_start_date);
    formData.append("tax_year", tax_year1095c);
    formData.append("all_covered_twelve_months", all_covered_twelve_months);
    formData.append("jan", jan1095c);
    formData.append("feb", feb1095c);
    formData.append("mar", mar1095c);
    formData.append("apr", apr1095c);
    formData.append("may", may1095c);
    formData.append("june", june1095c);
    formData.append("july", july1095c);
    formData.append("aug", aug1095c);
    formData.append("sep", sept1095c);
    formData.append("oct", oct1095c);
    formData.append("nov", nov1095c);
    formData.append("dec", dec1095c);
    formData.append("employee_contribution_1", employee_contribution_1);
    formData.append("employee_contribution_2", employee_contribution_2);
    formData.append("employee_contribution_3", employee_contribution_3);
    formData.append("employee_contribution_4", employee_contribution_4);
    formData.append("employee_contribution_5", employee_contribution_5);
    formData.append("employee_contribution_6", employee_contribution_6);
    formData.append("employee_contribution_7", employee_contribution_7);
    formData.append("employee_contribution_8", employee_contribution_8);
    formData.append("employee_contribution_9", employee_contribution_9);
    formData.append("employee_contribution_10", employee_contribution_10);
    formData.append("employee_contribution_11", employee_contribution_11);
    formData.append("employee_contribution_12", employee_contribution_12);
    formData.append("safe_harbor_1", safe_harbor_1);
    formData.append("safe_harbor_2", safe_harbor_2);
    formData.append("safe_harbor_3", safe_harbor_3);
    formData.append("safe_harbor_4", safe_harbor_4);
    formData.append("safe_harbor_5", safe_harbor_5);
    formData.append("safe_harbor_6", safe_harbor_6);
    formData.append("safe_harbor_7", safe_harbor_7);
    formData.append("safe_harbor_8", safe_harbor_8);
    formData.append("safe_harbor_9", safe_harbor_9);
    formData.append("safe_harbor_10", safe_harbor_10);
    formData.append("safe_harbor_11", safe_harbor_11);
    formData.append("safe_harbor_12", safe_harbor_12);
    formData.append("zip_code_1", zip_code_1);
    formData.append("zip_code_2", zip_code_2);
    formData.append("zip_code_3", zip_code_3);
    formData.append("zip_code_4", zip_code_4);
    formData.append("zip_code_5", zip_code_5);
    formData.append("zip_code_6", zip_code_6);
    formData.append("zip_code_7", zip_code_7);
    formData.append("zip_code_8", zip_code_8);
    formData.append("zip_code_9", zip_code_9);
    formData.append("zip_code_10", zip_code_10);
    formData.append("zip_code_11", zip_code_11);
    formData.append("zip_code_122", zip_code_12);
    formData.append("first_name_1", first_name_11095c);
    formData.append("middle_name_1", middle_name_11095c);
    formData.append("last_name_1", last_name_11095c);
    // formData.append("stage_1", stage_11095c);
    formData.append("ssn_1", ssn_11095c);
    formData.append("dob_1", dob_11095c);
    formData.append("covered_twelve_months_1", covered_twelve_months_11095c);
    // formData.append("covered_twelve_months_2", covered_twelve_months_21095c);
   
    

    PostApi(`${BaseUrl.baseUrl}customer/form1095C/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1095-B Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };



    // Form 1097-BTC

  const [btc_issuer_check, setbtc_issuer_check] = useState();
  const [tax_yearbtc, settax_yearbtc] = useState();
  const [total, settotal] = useState();
  const [code, setcode] = useState();
  const [unique_identifier, setunique_identifier] = useState();
  const [bond_type, setbond_type] = useState();
  const [janbtc, setjanbtc] = useState();
  const [febbtc, setfebbtc] = useState();
  const [marbtc, setmarbtc] = useState();
  const [aprbtc, setaprbtc] = useState();
  const [maybtc, setmaybtc] = useState();
  const [junebtc, setjunebtc] = useState();
  const [julybtc, setjulybtc] = useState();
  const [augbtc, setaugbtc] = useState();
  const [septbtc, setseptbtc] = useState();
  const [octbtc, setoctbtc] = useState();
  const [novbtc, setnovbtc] = useState();
  const [decbtc, setdecbtc] = useState();
  const [comments, setcomments] = useState();

  const onSubmit1097btc = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("btc_issuer_check", btc_issuer_check);
    formData.append("total", total);
    formData.append("code", code);
    formData.append("unique_identifier", unique_identifier);
    formData.append("tax_year", tax_yearbtc);
    formData.append("bond_type", bond_type);
    formData.append("jan", janbtc);
    formData.append("feb", febbtc);
    formData.append("mar", marbtc);
    formData.append("apr", aprbtc);
    formData.append("may", maybtc);
    formData.append("june", junebtc);
    formData.append("july", julybtc);
    formData.append("aug", augbtc);
    formData.append("sep", septbtc);
    formData.append("oct", octbtc);
    formData.append("nov", novbtc);
    formData.append("dec", decbtc);
    formData.append("comments", comments);
   

    PostApi(`${BaseUrl.baseUrl}customer/form1097BTC/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 1097-BTC Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };

  // 941
  const [report_for_this_quarter, setreport_for_this_quarter] = useState();
  const [quarter_1, setquarter_1] = useState();
  const [quarter_2, setquarter_2] = useState();
  const [quarter_3, setquarter_3] = useState();
  const [quarter_4, setquarter_4] = useState();
  const [quarter_5, setquarter_5] = useState();
  const [quarter_6, setquarter_6] = useState();
  const [quarter_7, setquarter_7] = useState();
  const [quarter_8, setquarter_8] = useState();
  const [quarter_9, setquarter_9] = useState();
  const [quarter_10, setquarter_10] = useState();
  const [quarter_11, setquarter_11] = useState();
  const [quarter_12, setquarter_12] = useState();
  const [quarter_13, setquarter_13] = useState();
  const [quarter_14, setquarter_14] = useState();
  const [quarter_15, setquarter_15] = useState();
  const [quarter_16, setquarter_16] = useState();
  const [quarter_17, setquarter_17] = useState();
  const [quarter_18, setquarter_18] = useState();
  const [quarter_19, setquarter_19] = useState();
  const [quarter_20, setquarter_20] = useState();
  const [quarter_21, setquarter_21] = useState();
  const [quarter_22, setquarter_22] = useState();
 const [quarter_23, setquarter_23] = useState();
  const [quarter_24, setquarter_24] = useState();
  const [quarter_25, setquarter_25] = useState();
  const [quarter_26, setquarter_26] = useState();
  const [quarter_27, setquarter_27] = useState();
  const [quarter_28, setquarter_28] = useState();
 const [quarter_29, setquarter_29] = useState();
  const [quarter_30, setquarter_30] = useState();
  const [quarter_31, setquarter_31] = useState();
  const [quarter_32, setquarter_32] = useState();
  const [quarter_33, setquarter_33] = useState();
  const [quarter_34, setquarter_34] = useState();
  const [quarter_35, setquarter_35] = useState();
  const [quarter_36, setquarter_36] = useState();
  const [quarter_37, setquarter_37] = useState();
  const [quarter_38, setquarter_38] = useState();
  const [quarter_39, setquarter_39] = useState();
  const [quarter_40, setquarter_40] = useState();
  const [quarter_41, setquarter_41] = useState();
  
 const onSubmit941 = () => {
   setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    // formData.append("recipient_id", recid);
    formData.append("report_for_this_quarter", report_for_this_quarter);
    formData.append("quarter_1", quarter_1);
    formData.append("quarter_2", quarter_2);
    formData.append("quarter_3", quarter_3);
    formData.append("quarter_4", quarter_4);
    formData.append("quarter_5", quarter_5);
    formData.append("quarter_6", quarter_6);
    formData.append("quarter_7", quarter_7);
    formData.append("quarter_8", quarter_8);
    formData.append("quarter_9", quarter_9);
    formData.append("quarter_10", quarter_10);
    formData.append("quarter_11", quarter_11);
    formData.append("quarter_12", quarter_12);
    formData.append("quarter_13", quarter_13);
    formData.append("quarter_14", quarter_14);
    formData.append("quarter_15", quarter_15);
    formData.append("quarter_16", quarter_16);
    formData.append("quarter_17", quarter_17);
    formData.append("quarter_18", quarter_18);
    formData.append("quarter_19", quarter_19);
    formData.append("quarter_20", quarter_20);
    formData.append("quarter_21", quarter_21);
    formData.append("quarter_22", quarter_22);
    formData.append("quarter_23", quarter_23);
    formData.append("quarter_24", quarter_24);
    formData.append("quarter_25", quarter_25);
    formData.append("quarter_26", quarter_26);
    formData.append("quarter_27", quarter_27);
    formData.append("quarter_28", quarter_28);
    formData.append("quarter_29", quarter_29);
    formData.append("quarter_30", quarter_30);
    formData.append("quarter_31", quarter_31);
    formData.append("quarter_32", quarter_32);
    formData.append("quarter_33", quarter_33);
    formData.append("quarter_34", quarter_34);
    formData.append("quarter_35", quarter_35);
    formData.append("quarter_36", quarter_36);
    formData.append("quarter_37", quarter_37);
    formData.append("quarter_38", quarter_38);
    formData.append("quarter_39", quarter_39);
    formData.append("quarter_40", quarter_40);
    formData.append("quarter_41", quarter_41);

   PostApi(`${BaseUrl.baseUrl}customer/form941/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form 941 Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  // W-2
  const [tax_yearw2, settax_yearw2] = useState();
  const [kind_of_payer, setkind_of_payer] = useState();
  const [kind_of_employee, setkind_of_employee] = useState();
  const [wages, setwages] = useState();
  const [federal_incomew2, setfederal_incomew2] = useState();
  const [social_security_wages, setsocial_security_wages] = useState();
  const [social_security_tax_withheld, setsocial_security_tax_withheld] = useState();
  const [medicare_tax_withheld, setmedicare_tax_withheld] = useState();
  const [medicare_wages_and_tips, setmedicare_wages_and_tips] = useState();
  const [social_security_tips, setsocial_security_tips] = useState();
  const [allocated_tips, setallocated_tips] = useState();
  const [dependent_care_benefits, setdependent_care_benefits] = useState();
  const [nonqualified_plans, setnonqualified_plans] = useState();
  const [see_instructions_for_box_1, setsee_instructions_for_box_1] = useState();
  const [see_instructions_for_box_description_1, setsee_instructions_for_box_description_1] = useState();
  const [see_instructions_for_box_2, setsee_instructions_for_box_2] = useState();
  const [see_instructions_for_box_description_2, setsee_instructions_for_box_description_2] = useState();
  const [see_instructions_for_box_3, setsee_instructions_for_box_3] = useState();
  const [see_instructions_for_box_4, setsee_instructions_for_box_4] = useState();
  const [see_instructions_for_box_description_4, setsee_instructions_for_box_description_4] = useState();
  const [see_instructions_for_box_description_3, setsee_instructions_for_box_description_3] = useState();
  const [other_1, setother_1] = useState();
  const [other_amount_1, setother_amount_1] = useState();
  const [other_2, setother_2] = useState();
 const [other_amount_2, setother_amount_2] = useState();
  const [other_3, setother_3] = useState();
  const [other_amount_3, setother_amount_3] = useState();
  const [other_4, setother_4] = useState();
  const [other_amount_4, setother_amount_4] = useState();
  const [state_employer_1, setstate_employer_1] = useState();
 const [id_number_1, setid_number_1] = useState();
  const [state_employer_2, setstate_employer_2] = useState();
  const [id_number_2, setid_number_2] = useState();
  const [state_employer_3, setstate_employer_3] = useState();
  const [id_number_3, setid_number_3] = useState();
  const [state_employer_4, setstate_employer_4] = useState();
  const [id_number_4, setid_number_4] = useState();
  const [state_wages_1, setstate_wages_1] = useState();
  const [state_wages_2, setstate_wages_2] = useState();
  const [state_wages_3, setstate_wages_3] = useState();
  const [state_wages_4, setstate_wages_4] = useState();
  const [state_income_tax_1, setstate_income_tax_1] = useState();
  const [state_income_tax_2, setstate_income_tax_2] = useState();
  const [state_income_tax_3, setstate_income_tax_3] = useState();
  const [state_income_tax_4, setstate_income_tax_4] = useState();
  const [local_wages_1, setlocal_wages_1] = useState();
  const [local_wages_2, setlocal_wages_2] = useState();
  const [local_wages_3, setlocal_wages_3] = useState();
  const [local_wages_4, setlocal_wages_4] = useState();
  const [local_income_tax_1, setlocal_income_tax_1] = useState();
  const [local_income_tax_2, setlocal_income_tax_2] = useState();
  const [local_income_tax_3, setlocal_income_tax_3] = useState();
  const [local_income_tax_4, setlocal_income_tax_4] = useState();
  const [locality_name_1, setlocality_name_1] = useState();
  const [locality_name_2, setlocality_name_2] = useState();
  const [locality_name_3, setlocality_name_3] = useState();
  const [locality_name_4, setlocality_name_4] = useState();
  
 const onSubmitw2 = () => {
   setLoader(true);
    const formData = new FormData();
    formData.append("customer_id", idc);
    formData.append("payer_id", pid);
    formData.append("recipient_id", recid);
    formData.append("tax_year", tax_yearw2);
    formData.append("kind_of_payer", kind_of_payer);
    formData.append("kind_of_employee", kind_of_employee);
    formData.append("wages", wages);
    formData.append("federal_income", federal_incomew2);
    formData.append("social_security_wages", social_security_wages);
    formData.append("social_security_tax_withheld", social_security_tax_withheld);
    formData.append("medicare_wages_and_tips", medicare_wages_and_tips);
    formData.append("medicare_tax_withheld", medicare_tax_withheld);
    formData.append("social_security_tips", social_security_tips);
    formData.append("allocated_tips", allocated_tips);
    formData.append("dependent_care_benefits", dependent_care_benefits);
    formData.append("nonqualified_plans", nonqualified_plans);
    formData.append("see_instructions_for_box_1", see_instructions_for_box_1);
    formData.append("see_instructions_for_box_description_1", see_instructions_for_box_description_1);
    formData.append("see_instructions_for_box_2", see_instructions_for_box_2);
    formData.append("see_instructions_for_box_description_2", see_instructions_for_box_description_2);
    formData.append("see_instructions_for_box_3", see_instructions_for_box_3);
    formData.append("see_instructions_for_box_description_3", see_instructions_for_box_description_3);
    formData.append("see_instructions_for_box_4", see_instructions_for_box_4);
    formData.append("see_instructions_for_box_description_4", see_instructions_for_box_description_4);
    formData.append("other_1", other_1);
    formData.append("other_amount_1", other_amount_1);
    formData.append("other_2", other_2);
    formData.append("other_amount_2", other_amount_2);
    formData.append("other_3", other_3);
    formData.append("other_amount_3", other_amount_3);
    formData.append("other_4", other_4);
    formData.append("other_amount_4", other_amount_4);
    formData.append("state_employer_1", state_employer_1);
    formData.append("id_number_1", id_number_1);
    formData.append("state_employer_2", state_employer_2);
    formData.append("id_number_2", id_number_2);
    formData.append("state_employer_3", state_employer_3);
    formData.append("id_number_3", id_number_3);
    formData.append("state_employer_4", state_employer_4);
    formData.append("id_number_4", id_number_4);
    formData.append("state_wages_1", state_wages_1);
    formData.append("state_wages_2", state_wages_2);
    formData.append("state_wages_3", state_wages_3);
    formData.append("state_wages_4", state_wages_4);
    formData.append("state_income_tax_1", state_income_tax_1);
    formData.append("state_income_tax_2", state_income_tax_2);
    formData.append("state_income_tax_3", state_income_tax_3);
    formData.append("state_income_tax_4", state_income_tax_4);
    formData.append("locality_name_1", locality_name_1);
    formData.append("locality_name_2", locality_name_2);
    formData.append("locality_name_3", locality_name_3);
    formData.append("locality_name_4", locality_name_4);
    

   PostApi(`${BaseUrl.baseUrl}customer/formw2/store`, formData, userData)
      .then((data) => {
        setLoader(false);
        console.log(data.data.pdf_url, "nnc");

        if (data.data.message === "Form W-2 Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          window.location.href = data.data.pdf_url;

          getDatar();
          setShowr(false);

          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };


  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header style={{background: "linear-gradient(#91A6FF, #FFFFFF)"}}>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> 941 / 941PR</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body style={{background: "linear-gradient(#91A6FF, #FFFFFF)"}}>
          <div className="row">
          <div className="col-lg-2" style={{ marginTop: "3px" }} onClick={f940}>
          <Link to="/dashboard/1099">
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099NEC
                  </h5>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }} >
            <Link to="/dashboard/941">
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    941
                  </h5>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{background: "linear-gradient(#91A6FF, #FFFFFF)"}}>
          <Button
            className="btn btn-primary"
            onClick={back}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",background: "linear-gradient(#141e30, #243b55)", }}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose1}
        style={{backgroundColor:'#ffffff87'}}
       
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header style={{background: "linear-gradient(#91A6FF, #FFFFFF)"}}>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> 1099 / 1098 / 8809 / 480</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body style={{background: "linear-gradient(#91A6FF, #FFFFFF)"}}>
          <div className="row">
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={m1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099MISC
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={nec}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099NEC
                  </h5>
                </div>
              </div>
            </div>
            {/* <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={a1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099A
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={b1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099B
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={c1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099C
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={div1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099DIV
                  </h5>
                </div>
              </div>
            </div>

            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={g1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099G
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={int1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099INT
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={k1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099K
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={oid1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099OID
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={part1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099PART
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={r1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099R
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={s1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099S
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={ls1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099LS
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={sa1099}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099SA
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={Form1098}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1098
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={form1098C}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1098-C
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={t1098}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1098-T
                  </h5>
                </div>
              </div>
            </div>

          
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={e1098}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1098-E
                    <br />
                    <span style={{ color: "#44c18e" }}>.</span>
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={f3921}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form <br />
                    3921
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={f3922}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form <br />
                    3922
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={f1095b}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1095-B
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2"
              style={{ marginTop: "3px" }}
              onClick={f1095c}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1095-C
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}
            onClick={f1097btc}
            >
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 1097-BTC
                  </h5>
                </div>
              </div>
            </div>
           

            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form <br />
                    5498
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form 5498SA
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form <br />
                    W-26
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#141e30, #243b55)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    Form <br />
                    592b
                  </h5>
                </div>
              </div>
            </div> */}

            <h4 style={{ marginTop: "5px" }}>
              8809 | 1042-S | 1042 | 8027 | W-2/W-2C | W-2VI / W-2GU
            </h4>
            {/* <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5E5C5C, #9DC5C3)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    8809
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5E5C5C, #9DC5C3)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1042-S
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5E5C5C, #9DC5C3)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1042
                  </h5>
                </div>
              </div>
            </div> */}
            <div className="col-lg-2" style={{ marginTop: "3px" }} onClick={fW2}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5E5C5C, #9DC5C3)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    W-2
                  </h5>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5E5C5C, #9DC5C3)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5E5C5C, #9DC5C3)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    1099
                  </h5>
                </div>
              </div>
            </div> */}

            <h4 style={{ marginTop: "5px" }}>
              940 | 941 | 941X | 943 | 944 | 945 | 941PR | 944X
            </h4>
            <div className="col-lg-2" style={{ marginTop: "3px" }} onClick={f940}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    940
                  </h5>
                </div>
              </div>
            </div>
            
            <div className="col-lg-2" style={{ marginTop: "3px" }} >
            {/* <Link to="/dashboard/941"> */}
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                      width: '74px',
                    }}
                  >
                    941
                  </h5>
                </div>
              </div>
              {/* </Link> */}
            </div>
           
            {/* <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    944
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    945
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    941X
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    943
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    941PR
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "3px" }}>
              <div
                class="card"
                style={{
                  background: "linear-gradient(#5A585A, #090947)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <div class="card-body">
                  <h5
                    class="card-title"
                    style={{
                      fontSize: "15px",
                      fontFamily: "fantasy",
                      color: "#fff",
                    }}
                  >
                    944X
                  </h5>
                </div>
              </div>
            </div> */}
          </div>
          {/* ------- */}
        </Modal.Body>

        <Modal.Footer style={{background: "linear-gradient(#91A6FF, #FFFFFF)"}}>
          <Button
            className="btn btn-primary"
            onClick={back1}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",background: "linear-gradient(#141e30, #243b55)" }}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
        </Modal.Footer>
      </Modal>

      {/* 1099 modal */}
      <Modal
        show={show2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose2}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-MISC</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  <option>-select--</option>
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER's Federal Identification Number</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER's Federal Identification Number</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                   <option>-select--</option>
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numbermisc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>13 FATCA filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_filling(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setsecond_tin_notice(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-3 mt-2">
              <label>Rents</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setrents(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Royalties</label>
              <input
                type="number"
                min="1"
                max="10000000"
                className="form-control"
                onChange={(e) => {
                  setroyalties(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Other Income</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setother_income(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Fishing proceeds</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setfederal_income_tax(e.target.value);
                }}
                required
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label> Fishing Boat Proceeds</label>
              <input
                type="number"
                max="1"
                min="100000000"
                className="form-control"
                onChange={(e) => {
                  setfishing_boat_proceeds(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 " style={{ marginTop: "15px" }}>
              <label style={{ fontSize: "11px" }}>
                Medical Healthcare Payments{" "}
              </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setmedical_healthcare_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Gross Proceeds Paid</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setgross_proceeds_paid_attorney(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label> fish Purchased For Resale</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setfish_purchased_for_resale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Recipient for Resale</label>
              <input
                type="checkbox"
                value="0"
                onChange={(e) => {
                  setrecipient_for_resale(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-0115</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearmisc(e.target.value);
                }}
              >
                <option>--select--</option>
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label>Section Def</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setsection_def(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Excess Golden</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setexcess_golden(e.target.value);
                }}
              />
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Medical health pay</label>
              <input type="text" className="form-control" />
            </div> */}
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Substitute payments</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setsubstitute_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Gross proceeds paid</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "10px" }}>
                Crop Insurance Proceeds
              </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setcrop_insurance_proceeds(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Nonqualified deferred </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setnonqualified(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2"></div>
            <div className="col-lg-3 mt-2">
              <label>16 State tax withheld </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_tax_1misc(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_tax_2misc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>17 State / Payer's state no.</label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1misc(e.target.value);
                }}
              >
                <option>--select--</option>
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2misc(e.target.value);
                }}
              >
                 <option>--select--</option>
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{ marginTop: "27px" }}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setpayer_state_1(e.target.value);
                }}
              />

              <input
                type="text"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setpayer_state_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>18 State income</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_1misc(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_2misc(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmitmisc}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back2}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Form 1099-NEC */}
      <Modal
        show={show3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose3}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-NEC</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnotice(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2"></div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-0116</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Nonemployee compensation
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setnonemployee_amount(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Payer made direct sales totaling $5, 000 or more of consumer
                products{" "}
              </label>
              <input
                type="checkbox"
                value="0"
                onChange={(e) => {
                  setrecipient_resale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setfederal_income_tax(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label>16 State tax withheld </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setstate_tax_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="10000000000"
                placeholder="100"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_tax_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>17 State / Payer's state no.</label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1(e.target.value);
                }}
              >
                <option>--select--</option>
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2(e.target.value);
                }}
              >
                 <option>--select--</option>
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{ marginTop: "27px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="payer state"
                onChange={(e) => {
                  setpayer_state_no_1(e.target.value);
                }}
              />

              <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setpayer_state_no_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State income</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_2(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmitnnc}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back3}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* FORM 1099A */}
      <Modal
        show={show4}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose4}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-A</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                LENDER'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              {/* <label>PAYER's Federal Identification Number</label> */}
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>

                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  placeholder="LENDER'S TIN"
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              {/* <label>PAYER's Federal Identification Number</label> */}
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  placeholder="BORROWER'S TIN"
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                BORROWER'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Name</label>

              <input type="text" className="form-control" value={namer} />
            </div>
            <div className="col-lg-2 mt-2">
              <label>State</label>

              <input type="text" className="form-control" value={staterr} />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Address</label>

              <input type="text" className="form-control" value={addressrr} />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Zip Code</label>

              <input type="text" className="form-control" value={zipcoder} />
            </div>
            <div className="col-lg-2 mt-2">
              <label>City</label>

              <input type="text" className="form-control" value={cityrr} />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Phone</label>

              <input type="text" className="form-control" value={phoner} />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number (see Instructions)</label>
              <input type="text" className="form-control" disabled />
            </div>

            <label>OMB No. 1545-0877</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yeara(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "12px" }}>
                Date of lender's Acquisition{" "}
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_lender(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Balance of principal outstanding
              </label>
              <input
                type="number"
                min="1"
                max="100000"
                placeholder="10000000"
                className="form-control"
                onChange={(e) => {
                  setbalance_of_principle(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Fair market value of property
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                placeholder="10000"
                className="form-control"
                onChange={(e) => {
                  setfair_market_value(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Description of property
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                onChange={(e) => {
                  setdescription_of_property(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "10px" }}>
                Check if the borrower was personally liable for repayment of the
                debt
              </label>
              <input
                type="checkbox"
                value="0"
                name="checkbox"
                onChange={(e) => {
                  setborrower_repayment(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit009a}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back4}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 1099b  */}
      <Modal
        show={show5}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose5}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-B</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <br />
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER'S TIN</label>
              <br />
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT'S TIN</label>
              <br />

              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>

                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <br />
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Applicable check box on Form8949</label>
              <br />
              <div className="d-flex flex-row">
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={box}
                />
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Box}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <label>Account number </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numbermisc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>2nd TIN not.</label>
              <br />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_filling(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>CUSIP number</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numbermisc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>FATCA filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_filling(e.target.value);
                }}
              />
            </div>
            <label>OMB No. 1545-0115</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearmisc(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "10PX" }}>
                Description of property (Example 100 sh. XYZ Co.)
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setrents(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Date acquired</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setroyalties(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>Date sold or disposed</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setother_income(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Proceeds</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setfederal_income_tax(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>1e. Cost or other basis</label>
              <input
                type="number"
                max="1"
                min="100000000"
                className="form-control"
                onChange={(e) => {
                  setfishing_boat_proceeds(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 " style={{ marginTop: "15px" }}>
              <label style={{ fontSize: "11px" }}>
                1f. Accrued market discount{" "}
              </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setmedical_healthcare_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>1g. Wash sale loss disallowed</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setgross_proceeds_paid_attorney(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label>Federal income tax withheld</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setfish_purchased_for_resale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Profit or (loss) & closed contracts</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setfish_purchased_for_resale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Unrealized profit & (loss) open contracts
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setfish_purchased_for_resale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                  Short-term gain or loss
                </label>
              </div>
              <div className="d-flex justify-content-start">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                  Long-term gain or loss
                </label>
              </div>
              <div className="d-flex justify-content-start">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                  Ordinary
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input type="checkbox" value="HTML" />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Check if proceeds from: Collectibles
                </label>
              </div>
              <div className="d-flex justify-content-start">
                <input type="checkbox" value="HTML" />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  QOF
                </label>
              </div>
              <div className="d-flex justify-content-start">
                <input type="checkbox" value="HTML" />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Check if noncovered security
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div>
                <label>Reported to IRS:</label>
              </div>

              <div className="d-flex justify-content-start">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                  Gross proceeds
                </label>
              </div>
              <div className="d-flex justify-content-start">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                  Net proceeds
                </label>
              </div>
            </div>

            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input type="checkbox" value="HTML" />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Check if loss is not allowed based on amount in 1d
                </label>
              </div>
              <div className="d-flex justify-content-start">
                <input type="checkbox" value="HTML" />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Check if basis reported to IRS
                </label>
              </div>
            </div>

            <div className="col-lg-3 mt-2">
              <label>Unrealized profit & (loss) open contracts</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setsection_def(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Aggregate profit & (loss) on contracts</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setexcess_golden(e.target.value);
                }}
              />
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Medical health pay</label>
              <input type="text" className="form-control" />
            </div> */}
            <div className="col-lg-3 mt-2">
              <label style={{}}>Bartering</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setsubstitute_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2"></div>

            <div className="col-lg-3 mt-2">
              <label>16 State tax withheld </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_tax_1misc(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_tax_2misc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>17 State / Payer's state no.</label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1misc(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2misc(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{ marginTop: "27px" }}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setpayer_state_1(e.target.value);
                }}
              />

              <input
                type="text"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setpayer_state_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>18 State income</label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_1misc(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_2misc(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmitmisc}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back5}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Form 1099-DIV */}
      <Modal
        show={show8}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose8}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-DIV</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT’S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberdiv(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setsecond_tin_noticediv(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>ATCA filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_fillingdiv(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-0110</label>
            <div className="col-lg-2 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yeardiv(e.target.value);
                }}
                value="2022"
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>Total ordinary divid</label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  settotal_ordinary(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>section ordinary </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setsection_ordinary(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>Qualified dividends</label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setqualified_divideds(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Total capital gain distr
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  settotal_capital(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>Section 1202 gain</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsection_gain(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>
                Section 897 Ordinary Divid
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  settotal_ordinary(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>
                Nondividend distributions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setnondivided(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>Section 199A dividends</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsection_dividends(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>Section 199A dividends</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setinvestment_expenses(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>Foreign tax pai</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setforeign_tax_paid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>
                Cash liquidation distribut
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setcash_liquidation(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>
                Exempt-interest dividends
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setexempt_interest(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>
                Exempt-interest dividends
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setspecified_private_activity(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "9px" }}>
                {" "}
                Unrecap. Sec. 1250 gain
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setunrecap(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Collectibles (28%) gain
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setcollectibles(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Section 897 Capital Gain
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsection_capital_gain(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "9px" }}>
                Federal income tax withheld
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfederal_income(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                {" "}
                Foreign Country or U.S Possession
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setforeign_country(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                {" "}
                Noncash liquidation distributions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setnoncash_liquidation(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                {" "}
                Specified private activity bond
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setstate_tax_1(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>State</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setsel_1div(e.target.value);
                }}
              >
                <option value="SEL">SEL</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AS">AS</option>
              </select>
              <select
                className="form-control"
                style={{ marginTop: "6px" }}
                onChange={(e) => {
                  setsel_2div(e.target.value);
                }}
              >
                <option value="SEL">SEL</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AS">AS</option>
              </select>
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>
                State identification no.
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setstate_identification_1(e.target.value);
                }}
              />
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                style={{ marginTop: "6px" }}
                onChange={(e) => {
                  setstate_identification_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>State tax withheld</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setstate_tax_1div(e.target.value);
                }}
              />
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                style={{ marginTop: "6px" }}
                onChange={(e) => {
                  setstate_tax_2div(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099div}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back8}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 1099C */}
      <Modal
        show={show6}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose6}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099C</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                Creditor's name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>CREDITOR'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>DEBTOR'S TI</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                Debtor's name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number1099c(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-1424</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year1099c(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Date of identifiable event
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_event(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Amount of debt discharged
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                value="0"
                onChange={(e) => {
                  setamount_discharged(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Interest, if included in box 2
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setinterest(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Fair market value of property
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setfair_market_value1099c(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Identifiable event code
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  setevent_code1099c(e.target.value);
                }}
              >
                <option value="0">--Select--</option>
                <option value="A">A-Bankruptcy</option>
                <option value="B">B-Other judicial debt relief</option>
                <option value="C">
                  C-Statute of limitations or expiration of deficiency period
                </option>
                <option value="D">D-Foreclosure election</option>
                <option value="E">
                  E-Debt relief from probate or similar proceeding
                </option>
                <option value="F">F-By Agreement</option>
                <option value="G">
                  G-Decision or policy to discontinue collection
                </option>
                <option value="H">
                  H-Other actual discharge before identifiable event
                </option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setrepayment_debt(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  If checked, the debtor was personally liable for repayment of
                  the debt
                </label>
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label style={{ fontSize: "11px" }}>
                Debt description (Characters remaining: 39)
              </label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setdebt_description(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099c}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back6}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 1099s */}
      <Modal
        show={show9}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose9}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099S</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                Creditor's name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>FILER'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>TRANSFEROR'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                TRANSFEROR'S name, street address, city, state, ZIP code, and
                telephone no.?
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numbers(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-0997</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_years(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Date of Closing</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_closing(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Gross Proceeds</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                value="0"
                onChange={(e) => {
                  setgross_proceed(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Buyer’s part of real estate tax
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setbuyer_part_of_real_estate_tax(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setconsideration(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  .Check here if the transferor received or will receive
                  property or services as part of the consideration
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setforeign_partnership(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Check here if the transferor is a foreign person (nonresident
                  alien, foreign partnership, foreign estate, or foreign trust)
                </label>
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label style={{ fontSize: "11px" }}>
                Address or Legal Description including city, state, and ZIP code
                (Characters remaining: 39)
              </label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setaddress_legal_description(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099s}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back9}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 1099ls */}
      <Modal
        show={show10}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose10}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-LS</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                Creditor's name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>ACQUIRER’S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYMENT RECIPIENT’S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                PAYMENT RECIPIENT’S name, address, city, state, ZIP code, and
                telephone no
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Policy number</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setpolicy_number(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-0997</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearls(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Date of sale</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_salels(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Amount Paid To Payment Recipient
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setamount_paid_to_payment_recipient(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2"></div>

            <div className="col-lg-6 mt-2">
              <label style={{ fontSize: "11px" }}>Issuer's name</label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setissuer_namels(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{ fontSize: "11px" }}>
                Acquirer's information name, street address, city, country,
                state, ZIP code, and telephone no. if different from ACQUIRER
                (Characters remaining: 39)
              </label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setacquirer_informationls(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099ls}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back10}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 1099sa */}
      <Modal
        show={show11}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose11}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 1099-SA</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numbersa(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-1517</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearsa(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Gross Distribution</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setgross_distribution(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Earnings on Excess Cont
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setearnings_on_excess_cont(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>FMV on date of death</label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setfmv_date_of_death(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6 mt-2">
              <div className="d-flex justify-content-start">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">HSA</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => {
                    sethsa(e.target.value);
                  }}
                />
                <label for="css">Archer MSA</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => {
                    sethsa(e.target.value);
                  }}
                />

                <label for="css">MA MSA</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => {
                    sethsa(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099sa}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back11}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* form 1098  */}
      <Modal
        show={show12}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose12}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1098</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                RECIPIENT/LENDER Name,Street Address, City, State, ZIP Code and
                Telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT/LENDER'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css"> TIN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER/BORROWER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                PAYER/BORROWER Name, Street Address, City, State, ZIP Code and
                Telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>

            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>
                No.of properties securing the mortgage
              </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number8(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Other</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setother(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number1099c(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-1380</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year8(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Mortgage interest received from payer(s)/borrower(s)
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setmortgage_interest_received_from_payer(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Outstanding mortgage principal
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setoutstanding_mortgage_principal(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Mortgage origination date
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setmortgage_origination_date(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Refund of overpaid interest
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setrefund_of_overpaid_interest(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Mortgage insurance premiums
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setmortgage_insurance_premiums(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Points paid on purchase of principal residence
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setpoints_paid_on_purchase_of_principal_residence(
                    e.target.value
                  );
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setif_address_of_property_securing_mortgage(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  If address of property securing mortgage is the same as
                  PAYER/BORROWER address, the box is checked, or the address of
                  description is entered in box 8.
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Debt description (Characters remaining: 39)
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setaddress_or_description_of_property_securing_mortgage(
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                no of properties securing the mortgage
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setno_of_properties_securing_the_mortgage(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Mortgage Acquisition
                <br /> date
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setmortgage_acquisition_date(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1098}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back12}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Form 1098-C */}
      <Modal
        show={show13}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose13}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1098-C</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                Donee's name, street address, city or town, state or province,
                country, ZIP or foreign postal code, and telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>Donee's TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css"> TIN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>Donor's TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                Donor's name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label>
                Did you provide goods or services in exchange for the
                vehicle?YesNo
              </label>
              <div className="d-flex justify-content-start">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="yes"
                  onChange={(e) => {
                    setdid_you_provide_goods_services_exchange_for_the_vehicle(
                      e.target.value
                    );
                  }}
                />
                <label for="html">YES</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="yes"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => {
                    setdid_you_provide_goods_services_exchange_for_the_vehicle(
                      e.target.value
                    );
                  }}
                />
                <label for="css">NO</label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <label>
                Value of goods and services provided in exchange for the vehicle
              </label>
              <input
                type="number"
                min="1"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setvalue_of_goods_exchange_for_the_vehicle(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Describe the goods and services checked</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setdescribe_the_goods_and_services(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Date of contribution</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_contribution(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-1959</label>
            <div className="col-lg-2 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year8c(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>Odometer mileage</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setodometer_mileage(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>2b. Year</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setyear(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>2c. Make</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setmake(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>2d. Mode</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setmodel(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Vehicle or other identification number
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setvehicle_or_other_identification_number(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>Date of sale</label>
              <input
                type="date"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setdate_of_sale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>
                Gross proceeds from sale (see instructions)
              </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setgross_proceeds_from_sale(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{ fontSize: "11px" }}>
                Donee certifies the following detailed description
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setdonee_certifies_description(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setdonee_certifies(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Donee certifies that vehicle will not be transferred for
                  money, other property, or services before completion of
                  material improvements or significant intervening use
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setcharitable_purpose(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Donee certifies that vehicle is to be transferred to a needy
                  individual for significantly below fair market value in
                  furtherance of donee’s charitable purpose
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setintervening_use(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Donee certifies the following detailed description of material
                  improvements or significant intervening use and duration of
                  use
                </label>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  value="0"
                  onChange={(e) => {
                    setunder_the_law(e.target.value);
                  }}
                />
                <label
                  for="html"
                  style={{ flot: "right", marginLeft: "7px", fontSize: "11px" }}
                >
                  Under the law, the donor may not claim a deduction of more
                  than $500 for this vehicle if this box is checked
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1098c}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back13}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 1099t */}
      <Modal
        show={show14}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose14}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 1098-T</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                FILER'S name, street address, city or town, state or province,
                country, ZIP or foreign postal code, and telephone number
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>FILER’S employer identification no</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>STUDENT'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                STUDENT'S name, street address, city or town, state or province,
                country, and ZIP or foreign postal code
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Service Provider/Acct. No. (see instr.)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setservice_provider(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-4 mt-2">
              <label>Checked if at least half-time student</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setchecked_if_at_least_half_time_student(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-0997</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yeart(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label>Payments received for qualified </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setpayments_received_for_qualified_tuition_and_related_expenses(
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Adjustments made for a prior year </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setadjustments_made_for_prior_year(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Scholarships or grants </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setscholarships_or_grants(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Adjustments to scholarships </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setadjustments_to_scholarships_grants_prior_year(
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Ins.contract reimb./refund </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setcontract_refund(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label>checked if the amount in box 1 </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setacademic_period_beginning(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Checked if a graduate student</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setchecked_graduate_student(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1098t}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back14}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* FORM 1099-G */}
      <Modal
        show={show15}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose15}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> FORM 1099-G</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberg(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnoticeg(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2"></div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-0120</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearg(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Unemployment compensation
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setunemployment_compensation(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                State or local income tax
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setstate_or_local_income(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setfederal_income_taxg(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Box 2 amount is for tax year
              </label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setamount_is_for_tax_year(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div>

            <div className="col-lg-3 mt-2">
              <label>RTAA payments </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setrtaa_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Taxable grants</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  settaxable_grants(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Agriculture payments</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setagriculture_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Market gain</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmarket_gain(e.target.value);
                }}
              />
              <label style={{ fontSize: "11px" }}>
                Check if box 2 is trade or business income
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setcheck_business_income(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>17 State / Payer's state no.</label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1g(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2g(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
                b.State identification no
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="payer state"
                onChange={(e) => {
                  setidentification_1g(e.target.value);
                }}
              />

              <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2g(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State income tax withheld</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_tax_1g(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_tax_2g(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099g}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back15}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1099-K */}
      <Modal
        show={show16}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose16}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-INT</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberint(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnoticeint(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>FATCA filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_fillingint(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2"></div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-0112</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearint(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Unemployment compensation
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setnonemployee_amount(e.target.value);
                }}
              />
            </div> */}
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Payer's RTN (optional)</label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setpayers_rtn(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Interest income</label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setinterest_income(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Early Withdrawal Penalty
              </label>

              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setearly_withdrawal_penalty(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Interest on U.S. Savings Bonds{" "}
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setinterest_savings_bonds(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfederal_income_taxint(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Investment <br />
                expenses
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setinvestment_expenses(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Foreign tax
                <br />
                paid
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setforeign_tax_paid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Foreign country or U.S. possession
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setforeign_country(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Tax-exempt
                <br /> interest
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  settaxexempt_interest(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Specified private activity bond interest
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setspecified_private_activity_bond_interest(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Market
                <br /> discount
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setmarket_discount(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Bond
                <br /> premium
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setbond_premium_on_taxexempt_bond(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Bond premium on Treasury obligations
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setbond_treasury(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Bond premium on tax–exempt bond
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setbond_exempt(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Tax-exempt and tax credit bond CUSIP no.
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  settaxexempt(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>15 State </label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1int(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2int(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
                b.State identification no
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="payer state"
                onChange={(e) => {
                  setidentification_1int(e.target.value);
                }}
              />

              <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2int(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State income tax withheld</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_tax_1int(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_tax_2int(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099int}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back16}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1099-k */}
      <Modal
        show={show17}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose17}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-K</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                FILER'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>Check to indicate if FILER is a (an):</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">Payment settlement entity (PSE</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">
                  Electronic Payment Facilitator (EPF) /Otherthird party
                </label>
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>Check to indicate transactions reported are:</label>
              <div className="d-flex justify-content-start">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">Payment car</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">Third party network</label>
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                PAYEE'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberk(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnoticek(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>PSE’S Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setpses_name(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>PSE’S Telephone number</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setpses_telephone_number(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-2205</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yeark(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>FILER'S TIN</label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                // onChange={(e) => {
                //   setnonemployee_amount(e.target.value);
                // }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>PAYEE'S TIN</label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                // onChange={(e) => {
                //   setnonemployee_amount(e.target.value);
                // }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Gross amount of payment
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setgross_amount_of_payment(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Card Not Present transactions
              </label>

              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setcard_not_present_transactions(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>Merchant category code</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmerchant_category_code(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Number of Payment transactions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setnumber_of_payment_transactions(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfederal_income_taxk(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>January</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setjanuary(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>February</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setfebruary(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>March</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setmarch(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>April</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setapril(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>May</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setmay(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}> June</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setjune(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>July</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setjuly(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>August</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setaugust(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>September</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setseptember(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>October</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setoctober(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>November</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setnovember(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>December</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setdecember(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>15 State </label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1k(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2k(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
                State identification no.
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="payer state"
                onChange={(e) => {
                  setidentification_1k(e.target.value);
                }}
              />

              <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2k(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State income tax withheld</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_tax_1k(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_tax_2k(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099k}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back17}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1099-OID */}
      <Modal
        show={show18}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose18}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-OID</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state,ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberoid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnoticeoid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>FATCA filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_fillingoid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2"></div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-0117</label>
            <div className="col-lg-2 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearoid(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Original Issue Discount for the year
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setoriginal_issue_discount_for_the_year(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Other periodic interest
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setother_periodic_interest(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Early withdrawal penalty
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setearly_withdrawal_penalty(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>

              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfederal_income_taxoid(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>Market discount </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmarket_discountoid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                {" "}
                Acquisition
                <br /> premium
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setacquisition_premium(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Original issue
                <br /> discount on U.S
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setoriginal_issue_discount_on_us_treasury_obligations(
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Investment
                <br /> expenses
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setinvestment_expenses(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Bond
                <br /> premium
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setbond_premium(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Tax-exempt <br />
                OID
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  settaxexempt_OID(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label style={{ fontSize: "11px" }}>
                {" "}
                Description Character remaining: 39
              </label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setdescription(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="col-lg-3 mt-2">
              <label>15 State </label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1oid(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2oid(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
                b.State identification no
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="payer state"
                onChange={(e) => {
                  setidentification_1oid(e.target.value);
                }}
              />

              <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2oid(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State income tax withheld</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_tax_1oid(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_tax_2oid(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099oid}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back18}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1099-PATR */}
      <Modal
        show={show19}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose19}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-PATR</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT'S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberpart(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Second TIN Notice</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnoticepart(e.target.value);
                }}
              />
            </div>
            {/* <div className="col-lg-2 mt-2">
              <label>FATCA filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnotice(e.target.value);
                }}
              />
            </div> */}
            <div className="col-lg-2 mt-2"></div>

            <div className="col-lg-2 mt-2"></div>
            <div className="col-lg-2 mt-2"></div>

            <label>OMB No. 1545-0118</label>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Select
                <br />
                Year
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearpart(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Patronage
                <br /> dividends
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setpatronage_dividend(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Nonpatronage distributions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setnonpatronage_distributions(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Per-unit retain allocations
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setperunit_retain_allocations(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>

              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfederal_income_tax_withheldpart(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Redeemed nonqualified notices
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setredeemed_nonqualified_notices(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Section 199A(g) deduction
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsection_deduction(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Qualified payments (Section 199A(b)(7))
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setqualified_payments(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Section 199A(a) qual. items
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsection_qual_items(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Section 199A(a) SSTB items
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setsection_SSTB_items(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}> Investment credit</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setinvestment_credit(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Work opportunity credit
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setwork_opportunity_credit(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Other credits and deductions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setother_credits_and_deductions(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label> Specified Coop</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setspecified_coop(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099part}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back19}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1099-R */}
      <Modal
        show={show20}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose20}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1099-R</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER’S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT’S name, street address, city, state, ZIP code, and
                telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Fatca filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setfatca_fillingr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Amount allocable to IRR </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setamount_allocable_to_IRR_within_years(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>1st year of desig. Roth contrib.</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setyear_of_desig_roth_contrib(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label>
                Date of
                <br /> payment
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_payment(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-0119</label>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Select
                <br /> Year
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearr(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Gross <br />
                distributionr
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  setgross_distributionr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Taxable
                <br /> amount
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  settaxable_amount(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Capital gain (included in box 2a)
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setcapital_gain(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Federal income tax withheld
              </label>

              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfederal_income_taxr(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                .Employee contributions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setemployee_contributions(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Net unrealized appreciation
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setnet_unrealized_appreciation_in_employers_securities(
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="col-lg-2 " style={{ marginTop: "15px" }}>
              <label style={{ fontSize: "11px" }}>Other</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setotherr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2" style={{ marginTop: "40px" }}>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setpercent(e.target.value);
                }}
              />
            </div>
            {/* <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Bond
                <br /> premium
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setnotice(e.target.value);
                }}
              />
            </div> */}
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                {" "}
                Your percentage of total distribution
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setyour_percentage_of_total_distribution(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Total employee contributions
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  settotal_employee_contributions(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{ fontSize: "11px" }}>Distribution code(s)</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setdistribution_coder(e.target.value);
                }}
              >
                <option value="Select Code">Select Code</option>
                <option value="1">1</option>
                <option value="18">18</option>
                <option value="1B">1B</option>
                <option value="1D">1D</option>
                <option value="1K">1K</option>
                <option value="1L">1L</option>
                <option value="1P">1P</option>
                <option value="2">2</option>
                <option value="28">28</option>
                <option value="2B">2B</option>
                <option value="2D">2D</option>
                <option value="2K">2K</option>
                <option value="2L">2L</option>
                <option value="2P">2P</option>
                <option value="3">3</option>
                <option value="3D">3D</option>
                <option value="4">4</option>
                <option value="48">48</option>
                <option value="4A">4A</option>
                <option value="4B">4B</option>
                <option value="4D">4D</option>
                <option value="4G">4G</option>
                <option value="4H">4H</option>
                <option value="4K">4k</option>
                <option value="4L">4L</option>
                <option value="4M">4M</option>
                <option value="4P">4P</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="6W">6W</option>
                <option value="7">7</option>
                <option value="7A">7A</option>
                <option value="7B">7B</option>
                <option value="7D">7D</option>
                <option value="7K">7K</option>
                <option value="7L">7L</option>
                <option value="7M">7M</option>
                <option value="8">8</option>
                <option value="81">81</option>
                <option value="82">82</option>
                <option value="84">84</option>
                <option value="8B">8B</option>
                <option value="8J">8J</option>
                <option value="8K">8K</option>
                <option value="9">9</option>
                <option value="A4">A4</option>
                <option value="A7">A7</option>
                <option value="B">B</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="B4">B4</option>
                <option value="B7">B7</option>
                <option value="B8">B8</option>
                <option value="BG">BG</option>
                <option value="BL">BL</option>
                <option value="BM">BM</option>
                <option value="BP">BP</option>
                <option value="BU">BU</option>
                <option value="C">C</option>
                <option value="CD">CD</option>
                <option value="D">D</option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
                <option value="D3">D3</option>
                <option value="D4">D4</option>
                <option value="D7">D7</option>
                <option value="DC">DC</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="G4">G4</option>
                <option value="GB">GB</option>
                <option value="GK">GK</option>
                <option value="H">H</option>
                <option value="H4">H4</option>
                <option value="J">J</option>
                <option value="J8">J8</option>
                <option value="JP">JP</option>
                <option value="K">K</option>
                <option value="K1">K1</option>
                <option value="K2">K2</option>
                <option value="K4">K4</option>
                <option value="K7">K7</option>
                <option value="K8">K8</option>
                <option value="KG">KG</option>
                <option value="L">L</option>
                <option value="L1">L1</option>
                <option value="L2">L2</option>
                <option value="L4">L4</option>
                <option value="L7">L7</option>
                <option value="LB">LB</option>
                <option value="M">M</option>
                <option value="M1">M1</option>
                <option value="M2">M2</option>
                <option value="M4">M4</option>
                <option value="M7">M7</option>
                <option value="MB">MB</option>
                <option value="N">N</option>
                <option value="P">P</option>
                <option value="P1">P1</option>
                <option value="P2">P2</option>
                <option value="P4">P4</option>
                <option value="PB">PB</option>
                <option value="PJ">PJ</option>
                <option value="Q">Q</option>
                <option value="R">R</option>
                <option value="S">S</option>
                <option value="T">T</option>
                <option value="U">U</option>
                <option value="UB">UB</option>
                <option value="W">W</option>
                <option value="W6">W6</option>
              </select>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Taxable amount not determined</label>
              <input
                type="text"
                className="form-control"
                value="taxable"
                onChange={(e) => {
                  settaxable_amount_not_determined(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>
                Total
                <br /> distribution
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="hhhhh"
                onChange={(e) => {
                  settotal_distribution(e.target.value);
                }}
              />
            </div>
            {/* <div className="col-lg-2 mt-2">
            <label>IRA/SEP/<br/>SIMPLE</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnotice(e.target.value);
                }}
              />
              </div> */}

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>State tax withheld</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setstate_tax_1r(e.target.value);
                }}
              />
              <input
                style={{ marginTop: "5PX" }}
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setstate_tax_2r(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-4 mt-1">
              <label style={{ fontSize: "11px" }}>
                15.State/Payer’s state no{" "}
              </label>
              <div className="d-flex justify-content-start">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => {
                    setsel_1r(e.target.value);
                  }}
                >
                  <option value="SELL">SELL</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setstate_no_1r(e.target.value);
                  }}
                />
              </div>

              <div className="d-flex justify-content-start mt-2">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => {
                    setsel_2r(e.target.value);
                  }}
                >
                  <option value="SELL">SELL</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setstate_no_2r(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
                b.State identification no
              </label>
              <input
                type="number"
                min="1"
                max="10000000"
                className="form-control"
                placeholder="payer state"
                onChange={(e) => {
                  setstate_distribution_1r(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="10000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_distribution_2r(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State distribution</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setstate_income_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Local tax withheld</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setlocal_tax_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocal_tax_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Name of locality</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setname_locality_1(e.target.value);
                }}
              />

              <input
                type="text"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setname_locality_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Local distribution</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setlocal_distribution_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocal_distribution_2(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1099r}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back20}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1098-E*/}
      <Modal
        show={show21}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose21}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 1098-E</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                PAYER'S/BORROWER'S Name, Street Address,City or town, state or
                province, country, and ZIP or foreign postal code
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT’S TIN</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>BORROWER’S TIN</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                RECIPIENT’S/LENDER'S name, street address, city or town, state
                or province, country, ZIP or foreign postal code, and telephone
                number
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numbere(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-1576</label>
            <div className="col-lg-3 mt-2">
              <label>
                Select
                <br /> Year
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yeare(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label>Student loan interest received by lender </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setstudent_loan_interest_received_by_lender(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                heck if box 1 does not include loan origination fees and/or
                capitalized interest,{" "}
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setcheck_include_loan_origination_fees(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1098e}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back21}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 3921*/}
      <Modal
        show={show22}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose22}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 3921</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                TRANSFEROR'S name, street address, city or town, state or
                province, country, and ZIP or foreign postal code
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>TRANSFEROR’S TIN</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>EMPLOYEE’S TIN</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                EMPLOYEE’S name, street address(including apt. no.), City or
                town, state or province, country, and ZIP or foreign postal cod
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number3921(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-2129</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year3921(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label>Date option granted </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_option_granted3921(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Date option exercised </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_option_exercised3921(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Fair market value per share</label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setfair_market_value3921(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Exercise price per share </label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setexercise_price_per_share3921(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>.No. of shares transferred</label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setno_of_shares_transferred3921(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label style={{ fontSize: "11px" }}>
                If other than TRANSFEROR, name, address,{" "}
              </label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  settransferred3921(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit3921}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back22}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

 {/*  Form 941*/}
 <Modal
        show={show27f}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose27f}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 941 Employer's Quarterly Federal Tax Return</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <div col-lg-12> */}
             
              {/* <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div> */}
            {/* </div> */}
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled

              />
            </div>
            <div className="col-lg-2">
              <label>DisregardedEntity</label>
              <input
                Type="text"
                className="form-control"
                value={disregarded_entity}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Name Control</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label style={{fontSize:'11px'}}>identification (EIN)</label>
              <input
                Type="text"
                className="form-control"
                value={einn}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Trade Name </label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Telephone No</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label>Address</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  value={address}
                  disabled
                />
              </div>
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>City</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  value={city}
                  disabled

                />
              </div>
            </div>
            {/* <div className="col-lg-2 mt-2">
              <label>
                State
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div> */}
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
          
            
            <div className="col-lg-2">
              <label>Zip </label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Country</label>
              <input
                Type="text"
                className="form-control"
                value={countryes}
                disabled
              />
            </div>
            <div className="col-lg-2">
              
             
            </div>
            <label style={{fontWeight:'bold'}}>Report for this Quarter of 2022</label>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" onChange={(e) => {
                  setreport_for_this_quarter(e.target.value);
                }} />
              
                <label for="html">1st Quarter<br/>2022</label>
            </div>
          
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1"
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }} 
               />
                <label for="html">2nd Quarter 2022</label>
            </div>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" 
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }}
              />
                <label for="html">3rd Quarter 2022</label>
            </div>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" 
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }}
              />
                <label for="html">4th Quarter 2022</label>
            </div>
            <div className="col-lg-4">
              
            </div>
            <label style={{fontWeight:'bold'}}>Part 1:Answer these questions for this quarter.</label>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>. Number of employees who received wages, tips, or other compensation for the pay period including:
                 June 12(Quarter 2), Sept. 12(Quarter 3), or Dec. 12(Quarter 4). . . . . . . </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>02:Wages,tips and other compensation . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>03:Federal income tax withheld 
              from wages, tips, and other compensation</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_3(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>03.Federal income tax withheld from wages,
               tips, and other compensation . . .</label>
              
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5a.Taxable social security wages*</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_5(e.target.value);
                }}
              />
              <label>*.124 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_6(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5a.(i) Qualified sick leave wages*</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_7(e.target.value);
                }}
              />
              <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_8(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5a.(ii) Qualified family leave wages*</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_9(e.target.value);
                }}
              />
              <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_10(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5b.Taxable social security tips</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_11(e.target.value);
                }}
              />
              <label>*.124=</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_12(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5c.Taxable Medicare wages & tips</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_13(e.target.value);
                }}
              />
              <label>*.029=</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_14(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>5d.Taxable wages & tips subject 
              to Additional Medicare Tax withholding</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_15(e.target.value);
                }}
              />
              <label>*.009 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_16(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>5e. Total social security and Medicare taxes.
               Add Column 2 from lines 5a, 5a(i), 5a(ii), 5b, 5c, and 5d . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_17(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>5f.Section 3121(q) Notice and
               Demand—Tax due on unreported tips (See instructions) . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_18(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>06.Total taxes before adjustments
               (add lines 3, 5e, and 5f) . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_19(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>07.Current quarter’s adjustment for
               fractions of cents . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_20(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>08.Current quarter’s
               adjustment for sick pay . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_21(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>09.Current quarter’s 
              adjustments for tips and group-term life insurance . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_22(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>10.Total taxes after adjustments.
               Combine lines 6 through 9 . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_23(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11aQualified small business payroll tax credit 
              for increasing research activities. Attach Form 8974</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_24(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11bNonrefundable portion of credit for qualified sick and family 
              leave wages for leave taken before April 1, 2021. . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_25(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11c:Reserved for future use<br/> . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_26(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11d.Nonrefundable portion of credit for qualified sick and family 
              leave wages for leave taken after March 31, 2021, and before October 1, 2021 . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_27(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>11e.Reserved for future use <br/>. . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_28(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>11f.Reserved for future use<br/> . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_29(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>11g.Total nonrefundable credits. Add lines 11a, 11b, and 11d . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_30(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>12.Total taxes after adjustments and nonrefundable credits. Subtract line 11g from line 10 .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_31(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>13a. Total deposits for this quarter, including overpayment applied from a prior quarter..</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_32(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>13b. Reserved for future use </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_33(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13c.Refundable portion of credit for 
              qualified sick and family leave..</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_34(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13d.Reserved for future use . . . . . . . . . . . . . . . . . </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_35(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13e.Refundable portion of credit for qualified sick and family leave wages for leave taken after March 31, 2021, and before October</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_36(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13f.Reserved for future use<br/> . . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_37(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13g. Total deposits and refundable credits. Add lines 13a, 13c, and 13e </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_38(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13h.Reserved for future use<br/> . . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_39(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13i.Reserved for future use . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_40(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>14.Balance due. If line 12 is more than line 13g, enter the difference and see instructions </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_41(e.target.value);
                }}
              />
             
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>15.Overpayment. If line 13g is more than line 12, enter the difference</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number3922(e.target.value);
                }}
              />
             
            </div> */}

            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" style={{background: "linear-gradient(#141e30, #243b55)"}} onClick={onSubmit941}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back27f }
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "linear-gradient(#141e30, #243b55)" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/*  Form 940*/}
 <Modal
        show={show29}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose29}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 940 for 2022: Employer's Annual Federal Unemployment (FUTA) Tax Return</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            {/* <div col-lg-12> */}
             
              {/* <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div> */}
            {/* </div> */}
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled

              />
            </div>
            <div className="col-lg-2">
              <label>DisregardedEntity</label>
              <input
                Type="text"
                className="form-control"
                value={disregarded_entity}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Name Control</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label style={{fontSize:'11px'}}>identification (EIN)</label>
              <input
                Type="text"
                className="form-control"
                value={einn}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Trade Name </label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Telephone No</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label>Address</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  value={address}
                  disabled
                />
              </div>
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>City</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  value={city}
                  disabled

                />
              </div>
            </div>
            {/* <div className="col-lg-2 mt-2">
              <label>
                State
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div> */}
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
          
            
            <div className="col-lg-2">
              <label>Zip </label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Country</label>
              <input
                Type="text"
                className="form-control"
                value={countryes}
                disabled
              />
            </div>
            <div className="col-lg-2">
              
             
            </div>
            <label style={{fontWeight:'bold'}}>Report for this Quarter of 2022</label>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" onChange={(e) => {
                  setreport_for_this_quarter(e.target.value);
                }} />
              
                <label for="html">1st Quarter<br/>2022</label>
            </div>
          
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1"
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }} 
               />
                <label for="html">2nd Quarter 2022</label>
            </div>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" 
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }}
              />
                <label for="html">3rd Quarter 2022</label>
            </div>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" 
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }}
              />
                <label for="html">4th Quarter 2022</label>
            </div>
            <div className="col-lg-4">
              
            </div>
            <label style={{fontWeight:'bold'}}>Part 1:Answer these questions for this quarter.</label>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>. Number of employees who received wages, tips, or other compensation for the pay period including:
                 June 12(Quarter 2), Sept. 12(Quarter 3), or Dec. 12(Quarter 4). . . . . . . </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>02:Wages,tips and other compensation . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>03:Federal income tax withheld 
              from wages, tips, and other compensation</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_3(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>03.Federal income tax withheld from wages,
               tips, and other compensation . . .</label>
              
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5a.Taxable social security wages*</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_5(e.target.value);
                }}
              />
              <label>*.124 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_6(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5a.(i) Qualified sick leave wages*</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_7(e.target.value);
                }}
              />
              <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_8(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5a.(ii) Qualified family leave wages*</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_9(e.target.value);
                }}
              />
              <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_10(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5b.Taxable social security tips</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_11(e.target.value);
                }}
              />
              <label>*.124=</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_12(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>5c.Taxable Medicare wages & tips</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_13(e.target.value);
                }}
              />
              <label>*.029=</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_14(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>5d.Taxable wages & tips subject 
              to Additional Medicare Tax withholding</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_15(e.target.value);
                }}
              />
              <label>*.009 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_16(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>5e. Total social security and Medicare taxes.
               Add Column 2 from lines 5a, 5a(i), 5a(ii), 5b, 5c, and 5d . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_17(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>5f.Section 3121(q) Notice and
               Demand—Tax due on unreported tips (See instructions) . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_18(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>06.Total taxes before adjustments
               (add lines 3, 5e, and 5f) . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_19(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>07.Current quarter’s adjustment for
               fractions of cents . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_20(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>08.Current quarter’s
               adjustment for sick pay . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_21(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>09.Current quarter’s 
              adjustments for tips and group-term life insurance . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_22(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>10.Total taxes after adjustments.
               Combine lines 6 through 9 . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_23(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11aQualified small business payroll tax credit 
              for increasing research activities. Attach Form 8974</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_24(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11bNonrefundable portion of credit for qualified sick and family 
              leave wages for leave taken before April 1, 2021. . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_25(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11c:Reserved for future use<br/> . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_26(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>11d.Nonrefundable portion of credit for qualified sick and family 
              leave wages for leave taken after March 31, 2021, and before October 1, 2021 . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_27(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>11e.Reserved for future use <br/>. . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_28(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>11f.Reserved for future use<br/> . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_29(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>11g.Total nonrefundable credits. Add lines 11a, 11b, and 11d . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_30(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>12.Total taxes after adjustments and nonrefundable credits. Subtract line 11g from line 10 .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_31(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>13a. Total deposits for this quarter, including overpayment applied from a prior quarter..</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_32(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>13b. Reserved for future use </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_33(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13c.Refundable portion of credit for 
              qualified sick and family leave..</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_34(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13d.Reserved for future use . . . . . . . . . . . . . . . . . </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_35(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13e.Refundable portion of credit for qualified sick and family leave wages for leave taken after March 31, 2021, and before October</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_36(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13f.Reserved for future use<br/> . . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_37(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13g. Total deposits and refundable credits. Add lines 13a, 13c, and 13e </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_38(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13h.Reserved for future use<br/> . . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_39(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>13i.Reserved for future use . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_40(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>14.Balance due. If line 12 is more than line 13g, enter the difference and see instructions </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_41(e.target.value);
                }}
              />
             
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>15.Overpayment. If line 13g is more than line 12, enter the difference</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number3922(e.target.value);
                }}
              />
             
            </div> */}

            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" style={{background: "linear-gradient(#141e30, #243b55)"}} onClick={onSubmit941}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back27f }
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "linear-gradient(#141e30, #243b55)" }}
          >
            Close
          </Button>
        </Modal.Footer>

        {/* old */}
        {/* <Modal.Body>
          <div className="row">
           <div col-lg-12>
             
               <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div> 
             </div> 
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled

              />
            </div>
            <div className="col-lg-2">
              <label>DisregardedEntity</label>
              <input
                Type="text"
                className="form-control"
                value={disregarded_entity}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Name Control</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label style={{fontSize:'11px'}}>identification (EIN)</label>
              <input
                Type="text"
                className="form-control"
                value={einn}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Trade Name </label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Telephone No</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label>Address</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  value={address}
                  disabled
                />
              </div>
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>City</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                  value={city}
                  disabled

                />
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>
                State
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div> 
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
          
            
            <div className="col-lg-2">
              <label>Zip </label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Country</label>
              <input
                Type="text"
                className="form-control"
                value={countryes}
                disabled
              />
            </div>
            <div className="col-lg-2">
              
             
            </div>
            <label style={{fontWeight:'bold'}}>Type of Return (Check all that apply.)</label>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" onChange={(e) => {
                  setreport_for_this_quarter(e.target.value);
                }} />
              
                <label for="html">Amended</label>
            </div>
          
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1"
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }} 
               />
                <label for="html">Successor Emp</label>
            </div>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" 
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }}
              />
                <label for="html">No payments </label>
            </div>
            <div className="col-lg-2">
              <input type="radio" id="html" name="fav_language" value="1" 
              onChange={(e) => {
                setreport_for_this_quarter(e.target.value);
              }}
              />
                <label for="html"> Business closed </label>
            </div>
            <div className="col-lg-4">
              
            </div>
            <label style={{fontWeight:'bold'}}>Part 1:
Tell us about your return. If any line does NOT apply, leave it blank. See instructions before completing Part 1.</label>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>If you had to pay state 
              unemployment tax in one state only, enter the state abbreviation. </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>Check here.Complete Schedule A (Form 940)</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{fontSize:'11px'}}>Check here. Complete Schedule A (Form 940)</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
            </div>
            <label style={{fontWeight:'bold'}}>Part 2: Determine your FUTA tax before adjustments . If any line does NOT apply, leave it blank.</label>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>03:Total payment to all employees...</label>
              
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_5(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>Payments exempt from FUTA tax . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_5(e.target.value);
                }}
              />
              <label>*.124 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_6(e.target.value);
                }}
              /> 
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>Fringe benefits</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
              <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_8(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>Retirement/Pension</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
              <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_8(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>Other</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
              /
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{fontSize:'11px'}}>Group-term life insurance</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
             <label>*.062 =</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_8(e.target.value);
                }}
              /> 
            </div>
           
            <div className="col-lg-2 mt-2">
            <label style={{fontSize:'11px'}}>Dependent care</label>
            <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setquarter_4(e.target.value);
                }}
              />
              <label>*.029=</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_14(e.target.value);
                }}
              />
            </div>
           
            <div className="col-lg-5 mt-2">
              <label style={{fontSize:'11px'}}>05:Total of payments made to each employee in excess of $7,000</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_15(e.target.value);
                }}
              />
             
            </div>
            
            <div className="col-lg-5 mt-2">
              <label style={{fontSize:'11px'}}>06:Subtotal (line 4 + line 5 = line 6) </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_15(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>07Total taxable FUTA wages (line 3 - line 6 = line 7) (see instructions) . . . . . . . . . </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_15(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-6 mt-2">
              <label style={{fontSize:'11px'}}>08:FUTA tax before adjustments (line 7 X 0.006 = line 8) . . . . . . . . . . . . . . . . . . </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_15(e.target.value);
                }}
              />
             
            </div>
            <label style={{fontWeight:'bold'}}>Part 3:Determine your adjustments. If any line does NOT apply, leave it blank.</label>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>If ALL of the taxable FUTA wages you paid were excluded from state unemployment tax,</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_17(e.target.value);
                }}
              />
             
            </div>
            
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>If SOME of the taxable FUTA wages you paid were excluded from state unemployment tax, OR you  </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_17(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>If credit reduction applies, enter the amount total from Schedule A (Form 940) ...</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_17(e.target.value);
                }}
              />
             
            </div>
            <label style={{fontWeight:'bold'}}>Part 4:Determine your FUTA tax and balance due or overpayment . If any line does NOT apply, leave it blank.</label>
            
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>Total FUTA tax after adjustments (lines 8 + 9 + 10 + 11 = line 12). . . . . . . . . . . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_18(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>FUTA tax deposited for the year, including any overpayment applied from a prior year . . . . . . .</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_18(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>Balance due (If line 12 is more than line 13, enter the excess on line 14.).
       If line 14 is more than $500, </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_18(e.target.value);
                }}
              />
             
            </div>
            <div className="col-lg-4 mt-2">
              <label style={{fontSize:'11px'}}>Overpayment (If line 13 is more than line 12, enter the excess on line 15 and check a box below.)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setquarter_18(e.target.value);
                }}
              />
             
            </div>
            
        </div>
        </Modal.Body> */}

        {/* <Modal.Footer>
          <Button variant="primary" style={{background: "linear-gradient(#141e30, #243b55)"}} onClick={onSubmit941}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back29}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "linear-gradient(#141e30, #243b55)" }}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>




      {/*  Form 3921*/}
      <Modal
        show={show23}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose23}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title>Form 3922</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
                CORPORATION'S name, street address, city or town, state or
                province, country, and ZIP or foreign postal code
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>CORPORATION'S federal identification number</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>EMPLOYEE’S identification number</label>
              <div className="d-flex flex-row">
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
                EMPLOYEE’S name, street address(including apt. no.), City or
                town, state or province, country, and ZIP or foreign postal code
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onClick={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-12 mt-2">
              <label>Account number </label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_number3922(e.target.value);
                }}
              />
            </div>

            <label>OMB No. 1545-2129</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year3922(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label>Date option granted </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_option_granted(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Date option exercised</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_option_exercised(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Date legal title transferred</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_legal(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Fair market value per share on grant date
              </label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setfair_market_value_grant_date(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Fair market value per share on exercise{" "}
              </label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setfair_market_value_exercise_date(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Exercise price paid per share{" "}
              </label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setexercise_price_per_share(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                No. of shares transferred
              </label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setno_of_shares_transferred(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Exercise price per share determined as if{" "}
              </label>
              <input
                type="number"
                min="1"
                max="10000"
                className="form-control"
                onChange={(e) => {
                  setexercise_price_per_share_determined(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit3922}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back23}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1095-B */}
      <Modal
        show={show24}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose24}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}

          <Modal.Title> Form 1095-B</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                Part 1:
              </span>{" "}
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Information About Certain Employer-Sponsored Coverage (see
                instructions)
              </span>
              <br />
              <label>
                {/* PAYER’S name, street address, city, state, ZIP code, and
                telephone no. */}
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>
            <div className="col-lg-4">
              <label>Employer identification number (EIN)</label>
              <input Type="text" className="form-control" value={phone} />
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4"></div>

            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              Part 2: Responsible Individual
            </span>
            <br />
            <div className="col-lg-12 mt-2">
              <label>
                Street address (including apartment no.), City or town, State or
                province, Country and ZIP or foreign postal code
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Social security number(SSN) or other TIN</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                // onChange={(e) => {
                //   setaccount_numberr(e.target.value);
                // }}
                disabled
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>DOB (If SSN or other TIN is not available)</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdob(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>
                {" "}
                Enter letter identifying Origin of the Health Coverage (see
                instructionsfor codes):
              </label>
              <br />
              <div className="d-flex flex-row">
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={healts}
                  onChange={(e) => {
                    sethealth_coverage(e.target.value);
                  }}
                />
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={health}
                >
                  Add
                </button>
              </div>
            </div>
            <div col-lg-12>
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                Part 3:
              </span>{" "}
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Issuer or Other Coverage Provider (see instructions)
              </span>
              <br />
              <label>
                {/* PAYER’S name, street address, city, state, ZIP code, and
                telephone no. */}
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={user}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label> Employer identification number EIN</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                // onChange={(e) => {
                //   setaccount_numberr(e.target.value);
                // }}
              />
            </div>
            <div className="col-lg-3 mt-2">
            <select
                className="form-control"
                onChange={(e) => {
                  settax_yearb(e.target.value);
                }}
                style={{ marginTop: "35px" }}
              >
                <option value="2022">2022</option>
              
              </select>
            </div>
            <div className="col-lg-3 mt-2"></div>
            <div className="col-lg-3 mt-2"></div>
            <div className="col-lg-3 mt-2"></div>
            {/* <div className="col-lg-3 mt-2">
              <label>Account number (see Instructions)</label>
              <input
                type="number"
                min="0"
                max="100000"
                className="form-control"
                onChange={(e) => {
                  setaccount_numberr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Fatca filing requirement</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setfatca_fillingr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>Amount allocable to IRR </label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setamount_allocable_to_IRR_within_years(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>1st year of desig. Roth contrib.</label>
              <input
                type="number"
                min="1"
                max="1000000"
                className="form-control"
                onChange={(e) => {
                  setyear_of_desig_roth_contrib(e.target.value);
                }}
              />
            </div> */}

            {/* <div className="col-lg-3 mt-2">
              <label>
                Date of
                <br /> payment
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdate_of_payment(e.target.value);
                }}
              />
            </div> */}
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              Part 4: Covered Individuals(Enter the information for each covered
              individual.)
            </span>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Name of covered individual(s)
              </label>

              <input
                type="text"
                placeholder="first name"
                className="form-control"
                onChange={(e) => {
                  setfirst_name_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <input
                style={{ marginTop: "35px" }}
                type="text"
                placeholder="Middle Name"
                className="form-control"
                onChange={(e) => {
                  setmiddle_name_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <input
                type="text"
                placeholder="Last Name"
                className="form-control"
                onChange={(e) => {
                  setlast_name_1(e.target.value);
                }}
                style={{ marginTop: "35px" }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <select
                className="form-control"
                onChange={(e) => {
                  setstage_1(e.target.value);
                }}
                style={{ marginTop: "35px" }}
              >
                <option value="Jr">Jr</option>
                <option value="Sr">Sr</option>
                <option value="2nd">2nd</option>
                <option value="C3rd">C3rd</option>
                <option value="II">II</option>
                <option value="III">III</option>
                <option value="IV">IV</option>
                <option value="V">V</option>
                <option value="VI">VI</option>
              </select>
            </div>
            <div className="col-lg-1 mt-2">
              <label>Covered all 12 months</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="covered twelve months"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-1 mt-2">
              <label style={{ fontSize: "11px" }}>
                (b) SSN (or)
                <br /> (c) DOB
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setssn_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                (b) SSN (or)
                <br /> (c) DOB
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdob_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Name of covered individual(s)
              </label>

              <input
                type="text"
                placeholder="first name"
                className="form-control"
                onChange={(e) => {
                  setfirst_name_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <input
                style={{ marginTop: "35px" }}
                type="text"
                placeholder="Middle Name"
                className="form-control"
                onChange={(e) => {
                  setmiddle_name_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <input
                type="text"
                placeholder="Last Name"
                className="form-control"
                onChange={(e) => {
                  setlast_name_2(e.target.value);
                }}
                style={{ marginTop: "35px" }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <select
                className="form-control"
                onChange={(e) => {
                  setstage_2(e.target.value);
                }}
                style={{ marginTop: "35px" }}
              >
                <option value="Jr">Jr</option>
                <option value="Sr">Sr</option>
                <option value="2nd">2nd</option>
                <option value="C3rd">C3rd</option>
                <option value="II">II</option>
                <option value="III">III</option>
                <option value="IV">IV</option>
                <option value="V">V</option>
                <option value="VI">VI</option>
              </select>
            </div>
            <div className="col-lg-1 mt-2">
              <label>Covered all 12 months</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="covered twelve months"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-1 mt-2">
              <label style={{ fontSize: "11px" }}>
                (b) SSN (or)
                <br /> (c) DOB
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setssn_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                (b) SSN (or)
                <br /> (c) DOB
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  setdob_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-12">
              <div className="row">

            <label style={{ fontWeight: "bold" }}>(e) Months of Coverage</label>
            <div className="col-lg-1 mt-2">
              <label>jan</label>
              
                 <input
                 type="checkbox"
                 id="jan"
                 name="jan"
                 value="jan"
                 onChange={(e) => {
                   setcovered_twelve_months_2(e.target.value);
                 }}
                 disabled
               />
            
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Feb</label>
            <input
                type="checkbox"
                id="feb"
                name="feb"
                value="feb"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Mar</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Mar"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Apr</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Apr"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
                
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>May</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="May"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Jun</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Jun"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>July</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="July"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Aug</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Aug"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Sep</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Sep"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Oct</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Oct"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Nov</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Nov"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Dec</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Dec"
                onChange={(e) => {
                  setcovered_twelve_months_1(e.target.value);
                }}
                disabled
              />
             
            </div>
            </div>
            </div>
            <div className="col-lg-12">
              <div className="row">

            
            <div className="col-lg-1 mt-2">
              <label>jan</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="jan"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Feb</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="feb"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Mar</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Mar"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Apr</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Apr"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>May</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="May"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Jun</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Jun"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>July</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="July"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Aug</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Aug"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Sep</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Sep"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Oct</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Oct"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            <div className="col-lg-1 mt-2">
              <label>Nov</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Nov"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
              
            </div>
            <div className="col-lg-1 mt-2">
              <label>Dec</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Dec"
                onChange={(e) => {
                  setcovered_twelve_months_2(e.target.value);
                }}
                disabled
              />
             
            </div>
            </div>
            </div>

            {/* <div className="col-lg-2 mt-2">
              <label style={{ fontSize: "11px" }}>
                Bond
                <br /> premium
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                onChange={(e) => {
                  setnotice(e.target.value);
                }}
              />
            </div> */}

            {/* <div className="col-lg-2 mt-2">
            <label>IRA/SEP/<br/>SIMPLE</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setnotice(e.target.value);
                }}
              />
              </div> */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1095b}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back24}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Form 1095-C */}
      <Modal
        show={show25}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose25}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}

          <Modal.Title> Form 1095-C</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                Part 1:
              </span>{" "}
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Applicable Large Employer Member (Employer) [7-13]
              </span>
              <br />
              <label>
                {/* PAYER’S name, street address, city, state, ZIP code, and
                telephone no. */}
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>
            <div className="col-lg-4">
              <label>Employer identification number (EIN)</label>
              <input Type="text" className="form-control" value="" disabled />
            </div>

            <div className="col-lg-4"></div>
            <div className="col-lg-4"></div>

            <div col-lg-12>
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Employee [1-6]
              </span>
              <br />
              <label>
                {/* PAYER’S name, street address, city, state, ZIP code, and
                telephone no. */}
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>
            <div className="col-lg-4">
              <label>Social security number (SSN)</label>
              <input Type="text" className="form-control" value="" disabled />
            </div>

            <div className="col-lg-4"></div>
            <div className="col-lg-4"></div>

            <div className="col-lg-12 mt-2">
              <div className="row">
                <div
                  className="col-lg-4"
                  style={{ border: "1px solid #ced4da" }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    Part II: Employee Offer and Coverage
                  </label>
                </div>
                <div
                  className="col-lg-4 d-flex justify-content-cente"
                  style={{ border: "1px solid #ced4da" }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    Employee Age Jan1
                  </label>

                  <input
                    type="date"
                    // min="1"
                    // max="1000"
                    className="form-control"
                    onChange={(e) => {
                      setemployee_dob(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="col-lg-4 d-flex justify-content-cente"
                  style={{ border: "1px solid #ced4da" }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    Plan Start Month:
                  </label>
                  <input
                    type="date"
                    // min="1"
                    // max="1000"
                    className="form-control"
                    onChange={(e) => {
                      setplan_start_date(e.target.value);
                    }}
                  />
                  {/* <select className="form-control" 
                   onChange={(e) => {
                    setplan_start_date(e.target.value);
                  }}
                  >
                    <option value="0"></option>
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select> */}
                </div>
              </div>
            </div>
            <label>OMB No. 1545-2251 </label>
            <div className="col-lg-12 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_year1095c(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <label style={{ fontWeight: "bold", marginTop: "10px" }}>
              (e) Offer of Coverage (enter required code)
            </label>
            <div className="col-lg-2 mt-2">
              <label>All 12 Months</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={coverages}
                  onChange={(e) => {
                    setall_covered_twelve_months(e.target.value);
                  }}
                />

                <button onClick={coverage} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>jan</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={jan}
                  onChange={(e) => {
                    setjan1095c(e.target.value);
                  }}
                />

                <button onClick={janm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Feb</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={feb}
                  onChange={(e) => {
                    setfeb1095c(e.target.value);
                  }}
                />

                <button onClick={febm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Mar</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={mar}
                  onChange={(e) => {
                    setmar1095c(e.target.value);
                  }}
                />

                <button onClick={marm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Apr</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={apr}
                  onChange={(e) => {
                    setapr1095c(e.target.value);
                  }}
                />

                <button onClick={aprm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>May</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={maym}
                  onChange={(e) => {
                    setmay1095c(e.target.value);
                  }}
                />

                <button onClick={maymm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Jun</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={jun}
                  onChange={(e) => {
                    setjune1095c(e.target.value);
                  }}
                />

                <button onClick={junm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>July</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={julym}
                  onChange={(e) => {
                    setjuly1095c(e.target.value);
                  }}
                />

                <button onClick={julymm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>

            <div className="col-lg-2 mt-2">
              <label>Aug</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={aug}
                  onChange={(e) => {
                    setaug1095c(e.target.value);
                  }}
                />

                <button onClick={augm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Sep</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={sep}
                  onChange={(e) => {
                    setsept1095c(e.target.value);
                  }}
                />

                <button onClick={sepm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Oct</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={oct}
                  onChange={(e) => {
                    setoct1095c(e.target.value);
                  }}
                />

                <button onClick={octm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label> nov</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={nov}
                  onChange={(e) => {
                    setnov1095c(e.target.value);
                  }}
                />

                <button onClick={novm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Dec</label>
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={dec}
                  onChange={(e) => {
                    setdec1095c(e.target.value);
                  }}
                />

                <button onClick={decm} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <label style={{ fontWeight: "bold", marginTop: "10px" }}>
              {" "}
              Employee Required Contribution (see instructions)
            </label>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                value={namer}
                onChange={(e) => {
                  setemployee_contribution_1(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_2(e.target.value);
                }}
                
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_3(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_4(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_5(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_6(e.target.value);
                }}
                
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_7(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_8(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_9(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_10(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_11(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              <label>$</label>
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setemployee_contribution_12(e.target.value);
                }}
                
              />
            </div>
          
            <label style={{ fontWeight: "bold", marginTop: "10px" }}>
              {" "}
              Section 4980H Safe Harbor and Other Relief (enter code, if applicable)
            </label>
            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app1}
                  onChange={(e) => {
                    setsafe_harbor_1(e.target.value);
                  }}
                />

                <button onClick={appl1} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>

            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app2}
                  onChange={(e) => {
                    setsafe_harbor_2(e.target.value);
                  }}
                />

                <button onClick={appl2} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app3}
                  onChange={(e) => {
                    setsafe_harbor_3(e.target.value);
                  }}
                />

                <button onClick={appl3} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app4}
                  onChange={(e) => {
                    setsafe_harbor_4(e.target.value);
                  }}
                />

                <button onClick={appl4} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
           
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app5}
                  onChange={(e) => {
                    setsafe_harbor_5(e.target.value);
                  }}
                />

                <button onClick={appl5} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app6}
                  onChange={(e) => {
                    setsafe_harbor_6(e.target.value);
                  }}
                />

                <button onClick={appl6} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app7}
                  onChange={(e) => {
                    setsafe_harbor_7(e.target.value);
                  }}
                />

                <button onClick={appl7} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app8}
                  onChange={(e) => {
                    setsafe_harbor_8(e.target.value);
                  }}
                />

                <button onClick={appl8} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
             
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app9}
                  onChange={(e) => {
                    setsafe_harbor_9(e.target.value);
                  }}
                />

                <button onClick={appl9} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app10}
                  onChange={(e) => {
                    setsafe_harbor_10(e.target.value);
                  }}
                />

                <button onClick={appl10} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
            
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app11}
                  onChange={(e) => {
                    setsafe_harbor_11(e.target.value);
                  }}
                />

                <button onClick={appl11} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            <div className="col-lg-2 mt-2">
             
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app12}
                  onChange={(e) => {
                    setsafe_harbor_12(e.target.value);
                  }}
                />

                <button onClick={appl12} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div>
            {/* <div className="col-lg-2 mt-2">
              
              <div className="d-flex justify-content-start">
                <input
                  type="text"
                  className="form-control"
                  value={app13}
                  onChange={(e) => {
                    settotal_distribution(e.target.value);
                  }}
                />

                <button onClick={appl13} className="btn btn-primary">
                  ↆ
                </button>
              </div>
            </div> */}
            {/* <div className="col-lg-10"> </div> */}
            <label style={{ fontWeight: "bold", marginTop: "10px" }}>
              {" "}
              ZipCode
            </label>
            <div className="col-lg-2 mt-2">
             
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                
                onChange={(e) => {
                  setzip_code_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_2(e.target.value);
                }}
                
              />
            </div>
            <div className="col-lg-2 mt-2">
             
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_3(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
            
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_4(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
             
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_5(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_6(e.target.value);
                }}
                
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_6(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_7(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
            
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_8(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_9(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_10(e.target.value);
                }}
               
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_11(e.target.value);
                }}
                
              />
            </div>
            <div className="col-lg-2 mt-2">
              
              <input
                Type="number"
                max="100000"
                min="1"
                className="form-control"
                onChange={(e) => {
                  setzip_code_12(e.target.value);
                }}
                
              />
            </div>
            {/* <div className="col-lg-11">

            </div> */}

            

            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            Part III: Covered Individuals
If Employer provided self-insured coverage, check the box and enter 
the information for each individual enrolled in coverage, including the employee. 
            </span>
            <br />
            <div className="col-lg-2 ">
              <label style={{fontSize:'11px'}}>
              Name of covered 
              </label>
              <input
                Type="text"
                placeholder="fname"
                className="form-control"
                onChange={(e) => {
                  setfirst_name_11095c(e.target.value);
                }}
                
              />
            
            </div>
            <div className="col-lg-2" style={{marginTop:'20px'}}>
              
              <input
                Type="text"
                placeholder="middle name"
                className="form-control"
                onChange={(e) => {
                  setmiddle_name_11095c(e.target.value);
                }}
                
              />
            
            </div>
            <div className="col-lg-2 " style={{marginTop:'20px'}}>
              
              <input
                Type="text"
                placeholder="last name"
                className="form-control"
                onChange={(e) => {
                  setlast_name_11095c(e.target.value);
                }}
                
              />
            
            </div>
            <div className="col-lg-2 ">
              <label style={{fontSize:'11px'}}>SSN or other TIN</label>
              <input
                Type="text"
               className="form-control"
                onChange={(e) => {
                  setssn_11095c(e.target.value);
                }}
                
                
              />
            
            </div>
            <div className="col-lg-2">
              <label style={{fontSize:'11px'}}>DOB (if SSN or )</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setdob_11095c(e.target.value);
                }}
                
              />
            </div>
            <div className="col-lg-2">
              <label>Covered all 12 months</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="1"
                onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-1">
              <label>Jan</label>
              <input
                type="checkbox"
                id="Jan"
                name="Jan"
                value="Jan"
                onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Feb</label>
              <input
                type="checkbox"
                 value="Feb"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Mar</label>
              <input
                type="checkbox"
                 value="mar"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Apr</label>
              <input
                type="checkbox"
                 value="apr"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>May</label>
              <input
                type="checkbox"
                 value="may"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Jun</label>
              <input
                type="checkbox"
                 value="jun"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>July</label>
              <input
                type="checkbox"
                 value="july"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Aug</label>
              <input
                type="checkbox"
                 value="AUG"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Sep</label>
              <input
                type="checkbox"
                 value="sep"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Oct</label>
              <input
                type="checkbox"
                 value="oct"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Nov</label>
              <input
                type="checkbox"
                 value="nov"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="col-lg-1">
              <label>Dec</label>
              <input
                type="checkbox"
                 value="dec"
                 onChange={(e) => {
                  setcovered_twelve_months_11095c(e.target.value);
                }}
                disabled
              />
            </div>
            
            
            
           
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit1095c}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back25}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Form 1097-BTC */}
      <Modal
        show={show26}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose26}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form 1097-BTC</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
              ISSUER'S name, street address, 
              city, state, ZIP code, and telephone no.

              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>ISSUER'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <label>RECIPIENT'S TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <label>
              RECIPIENT'S name, street address, city, state, ZIP code, and telephone no.
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
            <div className="col-lg-6 mt-2">
              
              <label for="html">Issuer of bond or its agent
               filing current year Form 1097-BTC for credit being reported</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="1"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => {
                    setbtc_issuer_check(e.target.value);
                  }}
                />
            </div>
            <div className="col-lg-6 mt-2">
              
              <label for="html">An entity or a person that received or should have received a current year
Form 1097-BTC and is distributing part or all of that credit to others</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="1"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => {
                    setbtc_issuer_check(e.target.value);
                  }}
                  
                />
            </div>
            

            <label>OMB No. 1545-2197</label>
            <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearbtc(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              1.Total
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                className="form-control"
                onChange={(e) => {
                  settotal(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              2a.Code
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  setcode(e.target.value);
                }}
              >
                <option >---Select--</option>
                <option value="a">Account number</option>
                <option value="c">CUSIP number</option>
                <option value="o">Any other identifier</option>
              </select>
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              Unique identifier
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setunique_identifier(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              .Bond type
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  setbond_type(e.target.value);
                }}
              >
                <option >---Select--</option>
                <option value="101">Clean Renewable Energy Bond</option>
                <option value="199">Other</option>
            
              </select>
            </div>

            <div className="col-lg-3 mt-2">
              <label>January </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setjanbtc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label> February</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setfebbtc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>March</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmarbtc(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>April</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setaprbtc(e.target.value);
                }}
              />
             
              
            </div>
            <div className="col-lg-3 mt-2">
              <label>May</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmaybtc(e.target.value);
                }}
              />
             
              
            </div>
            <div className="col-lg-3 mt-2">
              <label>June</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setjunebtc(e.target.value);
                }}
              />

             
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
                july
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setjulybtc(e.target.value);
                }}
              />

              
            </div>
            <div className="col-lg-3 mt-2">
              <label>August</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setaugbtc(e.target.value);
                }}
              />

             
            </div>
            <div className="col-lg-3 mt-2">
              <label>September</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setseptbtc(e.target.value);
                }}
              />

             
            </div>
            <div className="col-lg-3 mt-2">
              <label>October</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setoctbtc(e.target.value);
                }}
              />

             
            </div>
            <div className="col-lg-3 mt-2">
              <label>November</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setnovbtc(e.target.value);
                }}
              />

             
            </div>
            <div className="col-lg-3 mt-2">
              <label>December</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setdecbtc(e.target.value);
                }}
              />

             
            </div>
            <div className="col-lg-3 mt-2">
              <label>Comments</label>
              <input
                type="number"
                min="1"
                max="1000000000"
                placeholder="100000"
                className="form-control"
                onChange={(e) => {
                  setcomments(e.target.value);
                }}
              />

             
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          
          <Button
            className="btn btn-primary"
            onClick={back26}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        {/* Form Employer */}
        <Modal
        show={show27}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose27}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form Employer</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-8">
              <label>
              Employer Name.

              </label>
           
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  <option>--Select--</option>
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                </div>
            <div className="col-lg-4">
              <label>EIN</label>
              <input
                Type="text"
                className="form-control"
                value={einn}
                disabled
              />
            </div>
                {/* <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button> */}
              
           
          

           

            
            
           
          
            
          </div>
        </Modal.Body>

        <Modal.Footer>
        {einn === undefined?
          <Button variant="secondary">
            Next
          </Button>:<Button variant="primary" style={{background: "linear-gradient(#141e30, #243b55)"}} onClick={f941f}>
          Next
          </Button>
          }
         
        </Modal.Footer>
      </Modal>
       {/* Form EmployerE */}
       <Modal
        show={show27e}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose27e}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form Employer</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-8">
              <label>
              Employer Name.

              </label>
           
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  <option>--Select--</option>
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                </div>
            <div className="col-lg-4">
              <label>EIN</label>
              <input
                Type="text"
                className="form-control"
                value={einn}
                disabled
              />
            </div>
                {/* <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button> */}
              
           
          

           

            
            
           
          
            
          </div>
        </Modal.Body>

        <Modal.Footer>
        {einn === undefined?
          <Button variant="secondary">
            Next
          </Button>:<Button variant="primary" style={{background: "linear-gradient(#141e30, #243b55)"}} onClick={f940e}>
          Next
          </Button>
          }
         
        </Modal.Footer>
      </Modal>

      {/* Recipient modal */}
      <Modal
        show={showr}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloser}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> ADD RECIPIENT</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-6>
              <label>Type</label>

              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  settype(e.target.value);
                }}
              >
                <option value="Business">Business</option>
                <option value="individual">individual</option>
              </select>
            </div>
            <div className="col-lg-6">
              <label>First Name</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setfirst_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Middle Name</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setmiddle_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Last Name</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setlast_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>suffix</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setsuffix(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>ssn</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setssn(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6">
              <label>EIN</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  sete_in(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Business Name</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setbusiness_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Attention to</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setattention_to(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setaddressr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>Line2</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setline_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setcityr(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>State</label>

              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setstater(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6 mt-2">
              <label>zipcode</label>

              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setzip_code(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>Country</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setcountry(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>Phone no</label>

              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setphone_no(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>Email</label>

              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={backr}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Recipient modal */}
      <Modal
        show={showuser}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseuser}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Add Issuer</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setfirst_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>EIN:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setmiddle_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Address:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setlast_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>(Line2):</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setsuffix(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>City:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setssn(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6">
              <label>State:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  sete_in(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Zip Code:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setbusiness_name(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Country:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setattention_to(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6">
              <label>Phone:</label>
              <input
                Type="text"
                className="form-control"
                onChange={(e) => {
                  setaddressr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>Check here for Foreign Address</label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setfatca_filling(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={backuser}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show7}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose7}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Select Applicable Checkbox on Form 8949</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="A"
                onChange={(e) => {
                  setbox(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                A- short-term transaction for which the cost or other basis is
                being reported to the IRS.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="B"
                onChange={(e) => {
                  setbox(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                B- short-term transaction for which the cost or other basis is
                not being reported to the IRS.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="D"
                onChange={(e) => {
                  setbox(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                D- long-term transaction for which the cost or other basis is
                being reported to the IRS.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="E"
                onChange={(e) => {
                  setbox(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                E- long-term transaction for which the cost or other basis is
                not being reported to the IRS.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="X"
                onChange={(e) => {
                  setbox(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                X- transaction if you cannot determine whether the recipient
                should check Box B or Box E on Form 8949 because the holding
                period is unknown.
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={onSubmit}>Save</Button> */}
          <Button
            className="btn btn-primary"
            onClick={back7}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Enter letter identifying Origin of the Health Coverage
(see instructionsfor codes):  modal */}

      <Modal
        show={showhealth}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showhealth}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Select Origin Policy Code</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="A"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                A- Small Business Health Options Program (SHOP)
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="B"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                B- Employer-sponsored coverage.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="C"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                C- Select Origin Policy Code
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="D"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                D- Individual market insurance.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="E"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                E- Multiemployer plan.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="F"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                F- Other designated minimum essential coverage.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="G"
                onClick={(e) => {
                  sethealths(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                G- Individual coverage health reimbursement arrangement (HRA).
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backhealth}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) all of month */}

      <Modal
        show={showcov}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showcov}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="E"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setcoverages(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backcov}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) jan */}

      <Modal
        show={showjan}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showjan}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="E"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setjan(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backjan}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) feb */}

      <Modal
        show={showfeb}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showfeb}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setfeb(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backfeb}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) mar */}

      <Modal
        show={showmar}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showmar}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setmar(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backmar}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) apr */}

      <Modal
        show={showapr}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapr}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setapr(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapr}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) may */}

      <Modal
        show={showmaym}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showmaym}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setmaym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backmaym}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) jun */}

      <Modal
        show={showjun}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showjun}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setjun(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backjun}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) july */}

      <Modal
        show={showjulym}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showjulym}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setjulym(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backjulym}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) aug */}

      <Modal
        show={showaug}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showaug}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setaug(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backaug}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) sep */}

      <Modal
        show={showsep}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showsep}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setsep(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backsep}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) oct */}

      <Modal
        show={showoct}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showoct}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setoct(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backoct}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) nov */}

      <Modal
        show={shownov}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={shownov}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setnov(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backnov}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Offer of Coverage (enter required code) nov */}

      <Modal
        show={showdec}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showdec}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Offer of Coverage (enter required code)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1A"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1A- Minimum essential coverage providing minimum value offered
                to you with an employee required contribution
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1B"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1B- Minimum essential coverage providing minimum value offered
                to you and minimum essential
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1C"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                1C- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1D"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1D- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1E"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1E- Minimum essential coverage providing minimum value offered
                to you and minimum essential coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1F"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1F- Minimum essential coverage NOT providing minimum value
                offered to you, or you and your spouse or dependent(s),
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1G"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1G- You were NOT a full-time employee for any month of the
                calendar year but were enrolled in self-insured
                employer-sponsored coverage
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1H"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1H- No offer of coverage (you were NOT offered any health
                coverage or you were offered coverage that is NOT minimum
                essential coverage).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1I"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1I- Reserved for future use.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1J"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1J- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1K"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1K- Minimum essential coverage providing minimum value offered
                to you; minimum essential coverage conditionally offered to your
                spouse;
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1L"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1L- Individual coverage health reimbursement arrangement (HRA)
                offered to you only with affordability determined by using
                employee’s primary
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1M"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1M- Individual coverage HRA offered to you and dependent(s) (not
                spouse) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1N"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1N- ndividual coverage HRA offered to you, spouse and
                dependent(s) with affordability determined by using employee’s
                primary residence
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1O"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1O- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1P"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1P- Individual coverage HRA offered to you only using the
                employee’s primary employment site ZIP code affordability safe
                harbor.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1Q"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1Q- Individual coverage HRA offered to you, spouse and
                dependent(s) using the employee’s primary employment site ZIP
                code affordability safe harbor
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1R"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1R- Individual coverage HRA that is NOT affordable offered to
                you; employee and spouse or dependent(s); or employee, spouse,
                and dependents.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="1S"
                onClick={(e) => {
                  setdec(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                1S- Individual coverage HRA offered to an individual who was not
                a full-time employee
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backdec}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 1 */}

      <Modal
        show={showapp1}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp1}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp1(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp1}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 2 */}

      <Modal
        show={showapp2}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp2}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp2(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp2}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 3 */}

       <Modal
        show={showapp3}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp3}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp3(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp3}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 4 */}

      <Modal
        show={showapp4}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp4}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp4(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp4}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 5 */}

      <Modal
        show={showapp5}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp5}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp5(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp5}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 6 */}

       <Modal
        show={showapp6}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp6}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp6(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp6}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 7 */}

      <Modal
        show={showapp7}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp7}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp7(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp7}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 8 */}

        <Modal
        show={showapp8}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp8}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp8(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp8}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 9 */}

      <Modal
        show={showapp9}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp9}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp9(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp9}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 10 */}

       <Modal
        show={showapp10}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp10}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp10(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp10}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 11 */}

      <Modal
        show={showapp11}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp11}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp11(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp11}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 12 */}

      <Modal
        show={showapp12}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp12}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp12(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp12}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Applicable Section 4980H Safe Harbor (enter code, if applicable 13 */}

       <Modal
        show={showapp13}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showapp13}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Applicable Section 4980H Safe Harbor (enter code, if applicable)</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2A"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2A- Employee not employed during the month. Enter code 2A if the employee
                 was not employed on any day of the calendar month.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2B"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2B- Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day
                 of the month solely because the employee terminated employment during the month
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2C"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                2C- Employee enrolled in health coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example,
                 the code for a section 4980H affordability safe harbor) except as provided below.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2D"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2D- Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b)
                 Limited Non-Assessment Period) for the month,
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2E"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2E- MMultiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply. This relief is described
                 under Offer of Health Coverage in the Definitions section of these instructions.
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2F"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2F- Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for the year. 
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2G"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2G- Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE member used the section 4980H federal poverty line safe harbor to determine 
                affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="2H"
                onClick={(e) => {
                  setapp13(e.target.value);
                }}
              />
              <label for="html" style={{ flot: "right", marginLeft: "7px" }}>
                {" "}
                2H- Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine
                 affordability for purposes of section 4980H(b) for this employee for any month(s).
              </label>
            </div>
            
            
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="primary" onClick={health}>OK</Button> */}
          <Button
            className="btn btn-primary"
            onClick={backapp13}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* FORM 1099-G */}
      <Modal
        show={show28}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose28}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
          <Modal.Title> Form W-2</Modal.Title>
          {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div col-lg-12>
              <label>
              EMPLOYER'S name, street address, city, state, ZIP code, 
              and telephone no.

              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledata(e.target.value)}
                >
                  {datas.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  ADD
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  EDIT
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={name}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={address}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcode}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={city}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phone}
                disabled
              />
            </div>

            {/* <div className="col-lg-6 mt-2">
              <label>PAYER TIN</label>
              <div className="d-flex flex-row">
                <input type="radio" id="html" name="fav_language" value="EIN" />
                <label for="html">EIN</label>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="SSN"
                  style={{ marginLeft: "5px" }}
                />
                <label for="css">SSN</label>
                <input
                  Type="text"
                  className="form-control"
                  style={{ marginLeft: "5px" }}
                />
              </div>
            </div> */}

            <div className="col-lg-2 mt-2">
              <label>Employee's social security number</label>
              <div className="d-flex flex-row">
                <input type="text"  className="form-control" 
                //  onChange={(e) => setsocial_security_wages(e.target.value)}
                 
                />
                
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Employer Identification </label>
              <div className="d-flex flex-row">
                <input type="text"  className="form-control" />
                
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Kind of <br/>Employer </label>
              <div className="d-flex flex-row">
              <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => setkind_of_employee(e.target.value)}
                >
                    <option >--Select--</option>
                  <option value="R">Regular (941)</option>
<option value="F">Regular (944)</option>
<option value="A">Agricultural (943)</option>
<option value="H">HouseHold</option>
<option value="M">Military</option>
<option value="Q">MedicareQualGovEm</option>
<option value="X">RailRoad (CT-1)</option>
                </select>
                
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Kind of<br/> Payer </label>
              <div className="d-flex flex-row">
              <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => setkind_of_payer(e.target.value)}
                >
                    <option >--Select--</option>
                    <option value="F">Federal govt.</option>
<option value="S">State/local non-501c.</option>
<option value="T">501c non-govt.</option>
<option value="Y">State/local 501c.</option>
<option value="N">None Apply</option>
                </select>
                
              </div>
            </div>
            <div className="col-lg-2 mt-2">
              <label>Check here if your business was 
            </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="2022"
                onChange={(e) => {
                  settax_yearw2(e.target.value);
                }}
              />
             
            </div>


            
            <div className="col-lg-12 mt-2">
              <label>
              EMPLOYEE'S name, street address, city, state, ZIP code, and telephone no.
?
              </label>
              <div className="d-flex flex-row">
                <select
                  name="cars"
                  id="cars"
                  className="form-control"
                  onChange={(e) => singledatar(e.target.value)}
                >
                  {datar.map((v, index) => {
                    return <option value={v.id}>{v.first_name}</option>;
                  })}
                </select>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={Recip}
                >
                  Recipient
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <label>Name</label>
              <input
                Type="text"
                className="form-control"
                value={namer}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>State</label>
              <input
                Type="text"
                className="form-control"
                value={staterr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Address</label>
              <input
                Type="text"
                className="form-control"
                value={addressrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Zip Code</label>
              <input
                Type="text"
                className="form-control"
                value={zipcoder}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>City</label>
              <input
                Type="text"
                className="form-control"
                value={cityrr}
                disabled
              />
            </div>
            <div className="col-lg-2">
              <label>Phone</label>
              <input
                Type="text"
                className="form-control"
                value={phoner}
                disabled
              />
            </div>
           

            <label>OMB NO. 1545-0008</label>
            {/* <div className="col-lg-3 mt-2">
              <label>Select Year</label>
              <select
                className="form-control"
                onChange={(e) => {
                  settax_yearg(e.target.value);
                }}
              >
                <option value="2022">2022</option>
              </select>
            </div> */}
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              Wages, tips, other compensation
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                placeholder="1000"
                className="form-control"
                onChange={(e) => {
                  setwages(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              Federal income tax withheld
              </label>
              <input
                type="number"
                min="1"
                max="100000000000"
                placeholder="1000"
                className="form-control"
                onChange={(e) => {
                  setfederal_incomew2(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              Social security wages
              </label>
              <input
                type="number"
                min="1"
                max="10000000000"
                className="form-control"
                placeholder="10000"
                onChange={(e) => {
                  setsocial_security_wages(e.target.value);
                }}
              />
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label style={{ fontSize: "11px" }}>
              Social security tax withheld
              </label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setamount_is_for_tax_year(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div> */}

            <div className="col-lg-3 mt-2">
              <label>Social security tax withheld </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsocial_security_tax_withheld(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Medicare wages and tip</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmedicare_wages_and_tips(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Medicare tax withheld</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setmedicare_tax_withheld(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Social security tips</label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsocial_security_tips(e.target.value);
                }}
              />
              {/* <label style={{ fontSize: "11px" }}>
                
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setcheck_business_income(e.target.value);
                }}
              /> */}
            </div>
            {/* <div className="col-lg-3 mt-2">
              <label>17 State / Payer's state no.</label>
              <select
                name="cars"
                id="cars"
                className="form-control"
                onChange={(e) => {
                  setsel_1g(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>

              <select
                name="cars"
                id="cars"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setsel_2g(e.target.value);
                }}
              >
                <option value="SELL">SELL</option>
              </select>
            </div> */}
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
              Allocated tips
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setallocated_tips(e.target.value);
                }}
              />

              {/* <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2g(e.target.value);
                }}
              /> */}
            </div>
            <div className="col-lg-3 " style={{}}>
              <label style={{ fontSize: "11px" }}>
              Dependent care benefits
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setdependent_care_benefits(e.target.value);
                }}
              />

              {/* <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2g(e.target.value);
                }}
              /> */}
            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
              Nonqualified plans
              </label>
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setnonqualified_plans(e.target.value);
                }}
              />

              {/* <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2g(e.target.value);
                }}
              /> */}
            </div>
            <div className="col-lg-6 d-flex justify-content-start" style={{}}>
              <div className="col-lg-2">
              <label style={{ fontSize: "11px" }}>
              Statutory employee
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setcheck_business_income(e.target.value);
                }}
              />
              </div>
              <div className="col-lg-2">
              <label style={{ fontSize: "11px" }}>
              Retirement plan
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setcheck_business_income(e.target.value);
                }}
              />
              </div>
              <div className="col-lg-2">
              <label style={{ fontSize: "11px" }}>
              Third-party sick pay
              </label>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="0"
                onChange={(e) => {
                  setcheck_business_income(e.target.value);
                }}
              />
              </div>
              {/* <input
                type="text"
                placeholder="payer state"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setidentification_2g(e.target.value);
                }}
              /> */}
            </div>
            
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
              See instructions for box 12
              </label>
              <select class="form-control"  onChange={(e) => {
                  setsee_instructions_for_box_1(e.target.value);
                }}>
  <option value="SEL">SEL</option>
<option value="A">A</option>
<option value="B">B</option>
<option value="C">C</option>
<option value="D">D</option>
<option value="E">E</option>
<option value="F">F</option>
<option value="G">G</option>
<option value="H">H</option>
<option value="J">J</option>
<option value="K  ">K</option>
<option value="L">L</option>
<option value="M">M</option>
<option value="N">N</option>
<option value="P">P</option>
<option value="Q">Q</option>
<option value="R">R</option>
<option value="S">S</option>
<option value="T">T</option>
<option value="V">V</option>
<option value="W">W</option>
<option value="Y">Y</option>
<option value="Z">Z</option>
<option value="AA">AA</option>
<option value="BB">BB</option>
<option value="DD">DD</option>
<option value="EE">EE</option>
<option value="FF">FF</option>
<option value="GG" >GG</option>
<option value="HH">HH</option>
</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsee_instructions_for_box_description_1(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
              See instructions for box 12
              </label>
              <select class="form-control"  onChange={(e) => {
                  setsee_instructions_for_box_2(e.target.value);
                }}>
  <option value="SEL">SEL</option>
<option value="A">A</option>
<option value="B">B</option>
<option value="C">C</option>
<option value="D">D</option>
<option value="E">E</option>
<option value="F">F</option>
<option value="G">G</option>
<option value="H">H</option>
<option value="J">J</option>
<option value="K  ">K</option>
<option value="L">L</option>
<option value="M">M</option>
<option value="N">N</option>
<option value="P">P</option>
<option value="Q">Q</option>
<option value="R">R</option>
<option value="S">S</option>
<option value="T">T</option>
<option value="V">V</option>
<option value="W">W</option>
<option value="Y">Y</option>
<option value="Z">Z</option>
<option value="AA">AA</option>
<option value="BB">BB</option>
<option value="DD">DD</option>
<option value="EE">EE</option>
<option value="FF">FF</option>
<option value="GG" >GG</option>
<option value="HH">HH</option>
</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsee_instructions_for_box_description_2(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
              See instructions for box 12
              </label>
              <select class="form-control" onChange={(e) => {
                  setsee_instructions_for_box_3(e.target.value);
                }}>
  <option value="SEL">SEL</option>
<option value="A">A</option>
<option value="B">B</option>
<option value="C">C</option>
<option value="D">D</option>
<option value="E">E</option>
<option value="F">F</option>
<option value="G">G</option>
<option value="H">H</option>
<option value="J">J</option>
<option value="K  ">K</option>
<option value="L">L</option>
<option value="M">M</option>
<option value="N">N</option>
<option value="P">P</option>
<option value="Q">Q</option>
<option value="R">R</option>
<option value="S">S</option>
<option value="T">T</option>
<option value="V">V</option>
<option value="W">W</option>
<option value="Y">Y</option>
<option value="Z">Z</option>
<option value="AA">AA</option>
<option value="BB">BB</option>
<option value="DD">DD</option>
<option value="EE">EE</option>
<option value="FF">FF</option>
<option value="GG" >GG</option>
<option value="HH">HH</option>
</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsee_instructions_for_box_description_3(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3" style={{}}>
              <label style={{ fontSize: "11px" }}>
              See instructions for box 12
              </label>
              <select class="form-control"  onChange={(e) => {
                  setsee_instructions_for_box_4(e.target.value);
                }}>
  <option value="SEL">SEL</option>
<option value="A">A</option>
<option value="B">B</option>
<option value="C">C</option>
<option value="D">D</option>
<option value="E">E</option>
<option value="F">F</option>
<option value="G">G</option>
<option value="H">H</option>
<option value="J">J</option>
<option value="K  ">K</option>
<option value="L">L</option>
<option value="M">M</option>
<option value="N">N</option>
<option value="P">P</option>
<option value="Q">Q</option>
<option value="R">R</option>
<option value="S">S</option>
<option value="T">T</option>
<option value="V">V</option>
<option value="W">W</option>
<option value="Y">Y</option>
<option value="Z">Z</option>
<option value="AA">AA</option>
<option value="BB">BB</option>
<option value="DD">DD</option>
<option value="EE">EE</option>
<option value="FF">FF</option>
<option value="GG" >GG</option>
<option value="HH">HH</option>
</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setsee_instructions_for_box_description_4(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3 mt-2">
             
              <input
                type="text"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setother_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setother_amount_1(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
              <input
                type="text"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setother_2(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setother_amount_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
              <input
                type="text"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setother_3(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setother_amount_3(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
              <input
                type="text"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setother_4(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setother_amount_4(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3" style={{}}>
             
              <select class="form-control" onChange={(e) => {
                  setstate_employer_1(e.target.value);
                }}>
              <option value="SEL">SEL</option>
<option value="AL">AL</option>
<option value="AK">AK</option>
<option value="AS">AS</option>
<option value="AZ">AZ</option>
<option value="AR">AR</option>
<option value="AA">AA</option>
<option value="AE">AE</option>
<option value="AP">AP</option>
<option value="CA">CA</option>
<option value="CO">CO</option>
<option value="CT">CT</option>
<option value="DE">DE</option>
<option value="DC">DC</option>
<option value="FM">FM</option>
<option value="FL">FL</option>
<option value="GA">GA</option>
<option value="GU">GU</option>
<option value="HI">HI</option>
<option value="ID">ID</option>
<option value="IL">IL</option>
<option value="IN">IN</option>
<option value="IA">IA</option>
<option value="KS">KS</option>
<option value="KY">KY</option>
<option value="LA">LA</option>
<option value="ME">ME</option>
<option value="MD">MD</option>
<option value="MA">MA</option>
<option value="MI">MI</option>
<option value="MN">MN</option>
<option value="MS">MS</option>
<option value="MO">MO</option>
<option value="MT">MT</option>
<option value="NE">NE</option>
<option value="NV">NV</option>
<option value="NH">NH</option>
<option value="NJ">NJ</option>
<option value="NM">NM</option>
<option value="NY">NY</option>
<option value="NC">NC</option>
<option value="ND">ND</option>
<option value="MP">MP</option>
<option value="OH">OH</option>
<option value="OK">OK</option>
<option value="OR">OR</option>
<option value="PA">PA</option>
<option value="PR">PR</option>
<option value="RI">RI</option>
<option value="SC">SC</option>
<option value="SD">SD</option>
<option value="TN">TN</option>
<option value="TX">TX</option>
<option value="VI">VI</option>
<option value="UT">UT</option>
<option value="VT">VT</option>
<option value="VA">VA</option>
<option value="WA">WA</option>
<option value="WV">WV</option>
<option value="WI">WI</option>
<option value="WY">WY</option>

</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setid_number_1(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3" style={{}}>
              
              <select class="form-control" onChange={(e) => {
                  setstate_employer_2(e.target.value);
                }}>
              <option value="SEL">SEL</option>
<option value="AL">AL</option>
<option value="AK">AK</option>
<option value="AS">AS</option>
<option value="AZ">AZ</option>
<option value="AR">AR</option>
<option value="AA">AA</option>
<option value="AE">AE</option>
<option value="AP">AP</option>
<option value="CA">CA</option>
<option value="CO">CO</option>
<option value="CT">CT</option>
<option value="DE">DE</option>
<option value="DC">DC</option>
<option value="FM">FM</option>
<option value="FL">FL</option>
<option value="GA">GA</option>
<option value="GU">GU</option>
<option value="HI">HI</option>
<option value="ID">ID</option>
<option value="IL">IL</option>
<option value="IN">IN</option>
<option value="IA">IA</option>
<option value="KS">KS</option>
<option value="KY">KY</option>
<option value="LA">LA</option>
<option value="ME">ME</option>
<option value="MD">MD</option>
<option value="MA">MA</option>
<option value="MI">MI</option>
<option value="MN">MN</option>
<option value="MS">MS</option>
<option value="MO">MO</option>
<option value="MT">MT</option>
<option value="NE">NE</option>
<option value="NV">NV</option>
<option value="NH">NH</option>
<option value="NJ">NJ</option>
<option value="NM">NM</option>
<option value="NY">NY</option>
<option value="NC">NC</option>
<option value="ND">ND</option>
<option value="MP">MP</option>
<option value="OH">OH</option>
<option value="OK">OK</option>
<option value="OR">OR</option>
<option value="PA">PA</option>
<option value="PR">PR</option>
<option value="RI">RI</option>
<option value="SC">SC</option>
<option value="SD">SD</option>
<option value="TN">TN</option>
<option value="TX">TX</option>
<option value="VI">VI</option>
<option value="UT">UT</option>
<option value="VT">VT</option>
<option value="VA">VA</option>
<option value="WA">WA</option>
<option value="WV">WV</option>
<option value="WI">WI</option>
<option value="WY">WY</option>

</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setid_number_2(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3" style={{}}>
              
              <select class="form-control"    onChange={(e) => {
                  setstate_employer_3(e.target.value);
                }}>
              <option value="SEL">SEL</option>
<option value="AL">AL</option>
<option value="AK">AK</option>
<option value="AS">AS</option>
<option value="AZ">AZ</option>
<option value="AR">AR</option>
<option value="AA">AA</option>
<option value="AE">AE</option>
<option value="AP">AP</option>
<option value="CA">CA</option>
<option value="CO">CO</option>
<option value="CT">CT</option>
<option value="DE">DE</option>
<option value="DC">DC</option>
<option value="FM">FM</option>
<option value="FL">FL</option>
<option value="GA">GA</option>
<option value="GU">GU</option>
<option value="HI">HI</option>
<option value="ID">ID</option>
<option value="IL">IL</option>
<option value="IN">IN</option>
<option value="IA">IA</option>
<option value="KS">KS</option>
<option value="KY">KY</option>
<option value="LA">LA</option>
<option value="ME">ME</option>
<option value="MD">MD</option>
<option value="MA">MA</option>
<option value="MI">MI</option>
<option value="MN">MN</option>
<option value="MS">MS</option>
<option value="MO">MO</option>
<option value="MT">MT</option>
<option value="NE">NE</option>
<option value="NV">NV</option>
<option value="NH">NH</option>
<option value="NJ">NJ</option>
<option value="NM">NM</option>
<option value="NY">NY</option>
<option value="NC">NC</option>
<option value="ND">ND</option>
<option value="MP">MP</option>
<option value="OH">OH</option>
<option value="OK">OK</option>
<option value="OR">OR</option>
<option value="PA">PA</option>
<option value="PR">PR</option>
<option value="RI">RI</option>
<option value="SC">SC</option>
<option value="SD">SD</option>
<option value="TN">TN</option>
<option value="TX">TX</option>
<option value="VI">VI</option>
<option value="UT">UT</option>
<option value="VT">VT</option>
<option value="VA">VA</option>
<option value="WA">WA</option>
<option value="WV">WV</option>
<option value="WI">WI</option>
<option value="WY">WY</option>

</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setid_number_3(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3" style={{}}>
              
              <select class="form-control"  onChange={(e) => {
                  setstate_employer_4(e.target.value);
                }}>
              <option value="SEL">SEL</option>
<option value="AL">AL</option>
<option value="AK">AK</option>
<option value="AS">AS</option>
<option value="AZ">AZ</option>
<option value="AR">AR</option>
<option value="AA">AA</option>
<option value="AE">AE</option>
<option value="AP">AP</option>
<option value="CA">CA</option>
<option value="CO">CO</option>
<option value="CT">CT</option>
<option value="DE">DE</option>
<option value="DC">DC</option>
<option value="FM">FM</option>
<option value="FL">FL</option>
<option value="GA">GA</option>
<option value="GU">GU</option>
<option value="HI">HI</option>
<option value="ID">ID</option>
<option value="IL">IL</option>
<option value="IN">IN</option>
<option value="IA">IA</option>
<option value="KS">KS</option>
<option value="KY">KY</option>
<option value="LA">LA</option>
<option value="ME">ME</option>
<option value="MD">MD</option>
<option value="MA">MA</option>
<option value="MI">MI</option>
<option value="MN">MN</option>
<option value="MS">MS</option>
<option value="MO">MO</option>
<option value="MT">MT</option>
<option value="NE">NE</option>
<option value="NV">NV</option>
<option value="NH">NH</option>
<option value="NJ">NJ</option>
<option value="NM">NM</option>
<option value="NY">NY</option>
<option value="NC">NC</option>
<option value="ND">ND</option>
<option value="MP">MP</option>
<option value="OH">OH</option>
<option value="OK">OK</option>
<option value="OR">OR</option>
<option value="PA">PA</option>
<option value="PR">PR</option>
<option value="RI">RI</option>
<option value="SC">SC</option>
<option value="SD">SD</option>
<option value="TN">TN</option>
<option value="TX">TX</option>
<option value="VI">VI</option>
<option value="UT">UT</option>
<option value="VT">VT</option>
<option value="VA">VA</option>
<option value="WA">WA</option>
<option value="WV">WV</option>
<option value="WI">WI</option>
<option value="WY">WY</option>

</select>
              
              <input
                type="number"
                min="1"
                max="100000000000000"
                className="form-control"
                placeholder="1000"
                onChange={(e) => {
                  setid_number_4(e.target.value);
                }}
              />


            </div>
            <div className="col-lg-3 mt-2">
              <label>16. State wages, tips, etc</label>
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setstate_wages_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_wages_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setstate_wages_3(e.target.value);
                }}
                style={{marginTop:'20px'}}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_wages_4(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>State income tax</label>
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setstate_income_tax_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_tax_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setstate_income_tax_3(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setstate_income_tax_4(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Local income tax</label>
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setlocal_income_tax_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocal_income_tax_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2" >
             
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setlocal_income_tax_3(e.target.value);
                }}
                style={{marginTop:'20px'}}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocal_income_tax_4(e.target.value);
                }}
              />
            </div>


            <div className="col-lg-3 mt-2">
              <label>Local wages, tips, etc</label>
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setlocal_wages_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocal_wages_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setlocal_wages_3(e.target.value);
                }}
                style={{marginTop:'20px'}}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocal_wages_4(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
              <label>Locality name</label>
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setlocality_name_1(e.target.value);
                }}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocality_name_2(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 mt-2">
             
             
              <input
                 type="number"
                 min="1"
                 max="1000000000"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  setlocality_name_3(e.target.value);
                }}
                style={{marginTop:'20px'}}
              />

              <input
                type="number"
                min="1"
                max="1000000000"
                className="form-control"
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setlocality_name_4(e.target.value);
                }}
              />
            </div>
            
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onSubmitw2}>
            Save
          </Button>
          <Button
            className="btn btn-primary"
            onClick={back28}
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {loader ? <Loader fullpage loading /> : null}

      <div className="row">
        <div className="col-lg-3">
          <div
            class="card"
            style={{
              padding: "50px",
              background: "linear-gradient(#141e30, #243b55)",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            onClick={() => setShow(true)}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  color: "#fff",
                }}
              >
                2023
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div
            class="card"
            style={{
              padding: "50px",
              background: "linear-gradient(#141e30, #243b55)",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            onClick={() => setShow1(true)}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  color: "#fff",
                }}
              >
                2022
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div
            class="card"
            style={{
              padding: "50px",
              background: "linear-gradient(#141e30, #243b55)",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  color: "#fff",
                }}
              >
                2021
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newform;
