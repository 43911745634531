/* eslint-disable import/no-anonymous-default-export */
// const baseurl = `https://tax.dev-nuh.xyz/api/`
// export default baseurl
export default {

    
    // baseUrl:"https://api.valet-parking.ga/",

    
    baseUrl:"https://tax.dev-nuh.xyz/api/",
    // imgUrl:"https://api.cnchub.ml/media/"
    // baseUrl:"https://api.thecnchub.com/webapi",
    // imgUrl:"https://api.thecnchub.com/media/"

    // baseUrl:"https://api.thecnchub.com/webapi",
    // imgUrl:"https://api.thecnchub.com/media/"


    

  

    

}

// http://cnchub.pythonanywhere.com/webapi