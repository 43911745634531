
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { DropzoneArea } from "material-ui-dropzone";
// import IconButton from "@material-ui/core/IconButton";





import React, { forwardRef, useEffect, useState } from 'react';

// import
// import DeleteIcon from "@material-ui/icons/Delete";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PostApi } from "../../Helper/helper";
import { getApiWithToken } from "../../Helper/helper";

import BaseUrl from '../../BaseUrl/index'
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader"
import 'react-overlay-loader/styles.css';


const Order = () => {
    const [files, setFiles] = useState('');
    const [files2, setFiles2] = useState('');
    console.log(files, 'file')

  const handleFileChange = (files) => {
    setFiles(files[0]);
  };
  const handleFileChange2 = (files) => {
    setFiles2(files[0]);
  };

  const acceptedFileTypes = ["application/pdf"];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const back =()=>{
    setShow(false)
  }
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow(false);
  const back1 =()=>{
    setShow1(false)
  }
  // const [name ,setname] = useState();
  // const [trade_name ,settrade_name] = useState();
  // const [disregarded_entity ,setdisregarded_entity] = useState();
  // const [identification ,setidentification] = useState();
  // const [address ,setaddress] = useState();
  // const [another_address, setanother_address] = useState();
  // const [city, setcity] = useState();
  
  // update state
  
  // const [nameu, setnameu] = useState();
  // const [trade_nameu, settrade_nameu] = useState();
  // const [disregarded_entityu, setdisregarded_entityu] = useState();


 
 
  
  const [loader, setLoader] = useState(false)
  // const [type, settype] = useState();
 


  // add Managepayer
  const [uid, setuid] = useState();
  // const [e_in, sete_in] = useState();
  // const [business_name, setbusiness_name] = useState()
  // const [disregarded_entity, setdisregarded_entity] = useState()
  // const [address, setaddress] = useState()
  // const [line_2, setline_2] = useState()
  // const [city, setcity] = useState()
  // const [state, setstate] = useState();
  // const [zip_code, setzip_code] = useState();
  // const [country, setcountry] = useState();
  // const [phone_no, setphone_no] = useState();
  // const [email, setemail] = useState();
  // const [client_id, setclient_id] = useState();
  // const [holding, setholding] = useState();
  // const [last_filling, setlast_filling] = useState();
  // const [un_mask, setun_mask] = useState();
  // update Managepayer
 
  


  
  

  const [userData] = useState(localStorage.getItem("token"));
  const [idc]=useState(localStorage.getItem("id"))
  const [datas, setdatas] = useState([])

  useEffect(() => {
   
    // if (!localStorage.getItem("token")) {
    //   navigate("/Login");
    // }
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = () => {
    setLoader(true)
    const formData = new FormData();
    formData.append("customer_id", idc);

    // formData.append("name", name);
    formData.append("file", files);
    // formData.append("e_in", e_in);
    // formData.append("business_name", business_name);
    // formData.append("disregarded_entity", disregarded_entity);
    // formData.append("address", address);
    // formData.append("line_2", line_2);
    // formData.append("city", city);
    // formData.append("state", state);
    // formData.append("zip_code", zip_code);
    // formData.append("country", country);
    // formData.append("phone_no", phone_no);
    // formData.append("email", email);
    // formData.append("holding", holding);
    // formData.append("last_filling", last_filling);
    // formData.append("client_id", client_id);
    // formData.append("un_mask", un_mask);


    PostApi(`${BaseUrl.baseUrl}customer/file/store`, formData, userData)
      .then((data) => {
        setLoader(false)
        console.log(data.data, "ali");
        
        if (data.data.message === "File Successfully Uploaded") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          getData()
          setShow(false)
         
          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  const updateData = () => {
    setLoader(true)
    let formData = new FormData();
    
    
    formData.append("file", files2);
    formData.append("customer_id", idc);
    // formData.append("type", typeu);
    // formData.append("e_in", e_inu);
    // formData.append("business_name", business_nameu);
    // formData.append("disregarded_entity", disregarded_entityu);
    // formData.append("address", addressu);
    // formData.append("line_2", line_2u);
    // formData.append("city", cityu);
    // formData.append("state", stateu);
    // formData.append("zip_code", zip_codeu);
    // formData.append("country", countryu);
    // formData.append("phone_no", phone_nou);
    // formData.append("email", emailu);
    // formData.append("holding", holdingu);
    // formData.append("last_filling", last_fillingu);
    // formData.append("client_id", client_idu);
    // formData.append("un_mask", un_masku);
    // formdata.append(
    //   "picture",
    //   UpdateProfileImage == null ? ProfileImage : UpdateProfileImage
    // );

    // console.log("form data", formdata);

    PostApi(`${BaseUrl.baseUrl}customer/file/update/${uid}`, formData, userData)
      .then((response) => {
        setLoader(false)
        console.log("hhhhh>>>>>", response);
        console.log("minhaj", response.data.message);
        if (response.data.status) {
          Swal.fire({
            title: "success",
            text: response.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          setShow1(false)
          getData();
          
         
        } 
        else {

          Swal.fire({
            title: "error",
            text: response.data.message,
            icon: "error",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
         
        }

        
       
      })
      .catch((err) => {
        Swal.fire({
          title: "error",
          text: err,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
       
      
      });
  };
  const updatemodal = (rowData) => {
    // setLoader(true);
    console.log(rowData.id, 'uid')
    setuid(rowData.id);

    getApiWithToken(`${BaseUrl.baseUrl}customer/file/edit/${rowData.id}`, "", userData)
      .then(({ data }) => {
        console.log("minhaj", data.data);
        setFiles2(data.data.file);
        // setbusiness_nameu(data.data.business_name);
        // setcityu(data.data.city);
        // setcountryu(data.data.country);
        // setdisregarded_entityu(data.data.disregarded_entity);
        // sete_inu(data.data.e_in);
        // setemailu(data.data.email)
        // setholdingu(data.data.holding);
        // setlast_fillingu(data.data.last_filling);
        // setline_2u(data.data.line_2);
        // setphone_nou(data.data.phone_no);
        // setstateu(data.data.state);
        // settypeu(data.data.type);
        // setun_masku(data.data.un_mask);
        // setzip_codeu(data.data.zip_code);
        // setaddressu(data.data.address);
        

        

        setLoader(false);
        setShow1(true);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const getData = () => {
    setLoader(true)
    getApiWithToken(`${BaseUrl.baseUrl}customer/file/view`, "", userData)
      .then(({ data }) => {
        console.log("mmmmmmmmmmmmmmmmmmmmm", data.data);
        setdatas(data.data)
         
        // setName(data.name);
        // setEmail(data.email);
        // setProfileImage(data.picture);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const delData = (rowData) => {
    console.log("didasf", rowData.id);
    // setdid(rowData.id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        getApiWithToken(
          `${BaseUrl.baseUrl}customer/file/delete/${rowData.id}`,
          "",
          userData
        )
          .then((response) => {
            console.log("response", response);

            // Swal.fire({
            //   title: "success",
            //   text: "Update Successfully ",
            //   icon: "success",
            //   confirmButtonColor: "#29BF12",
            //   timer: 1500,
            // });
          })
          .catch((err) => {
            console.log("errrrr", err);
          });
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your file has been deleted. ',
            showConfirmButton: false,
            timer: 1500
          })

      
        getData();
      }
    });
  };
 
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DeleteOutline: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  return (
    <>
     {loader ? <Loader fullpage loading /> : null}
    <Modal 
      show={show1} 
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose1}>
                {/* <Modal.Header closeButton> */}
                <Modal.Header  >
                    {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
                    <Modal.Title >EDIT  PAYER</Modal.Title>
                    {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}

                </Modal.Header >
                <Modal.Body >
                <DropzoneArea
        onChange={handleFileChange2}
        acceptedFiles={acceptedFileTypes}
        // initialFiles={[files2]}
        filesLimit={1}
        dropzoneText={"Drag and drop a PDF file here or click to browse"}
      />
      <br/>
      <div class="d-grid gap-2">
  <button class="btn btn-primary" type="button" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={updateData}>Submit</button>
  
</div>
                 
                  
                  
                    
                 



                </Modal.Body>
                
                <Modal.Footer >
                {/* <Button  className="btn btn-primary"  style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}  onClick={updateData}>
                        Save
                    </Button> */}
               
                    <Button  className="btn btn-primary" onClick={back1} style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
                </Modal.Footer>
            </Modal>
     <Modal 
      show={show} 
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}>
                {/* <Modal.Header closeButton> */}
                <Modal.Header  >
                    {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
                    <Modal.Title >PDF UPLOAD</Modal.Title>
                    {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}

                </Modal.Header >
                <Modal.Body >
                <DropzoneArea
        onChange={handleFileChange}
        acceptedFiles={acceptedFileTypes}
        filesLimit={1}
        dropzoneText={"Drag and drop a PDF file here or click to browse"}
      />
      <br/>
      <div class="d-grid gap-2">
  <button class="btn btn-primary" type="button" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={onSubmit}>Submit</button>
  
</div>
                 
                  
                  
                    
                 



                </Modal.Body>
                
                <Modal.Footer >
                {/* <Button  className="btn btn-primary"  style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}} onClick={onSubmit}>
                        Add
                    </Button> */}
               
                    <Button  className="btn btn-primary" onClick={back} style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
                </Modal.Footer>
            </Modal>

    <div className="col-lg-12 " style={{marginBottom: '10px'}}>
        <button className="btn btn-primary d-flex flex-row-reverse " onClick={() => setShow(true)}>PDF Upload</button>
        </div>
      
      <div className='col-lg-12'>
        
      <MaterialTable
      icons={tableIcons}
                  title="List"
                  columns={[
                    { title: "ID", field: "id" },
                    { title: 'file', field: 'Link', render: rowData => <Button className='btn btn-danger  round btn-glow px-2' href={rowData.file} target="_blank" >View for File </Button> },
                   
                    
                    
                  ]}
                  data={datas}
                  options={{
                    addRowPosition: "first",
                  }}
                  actions={[
                    {
                      icon: Edit,
                      tooltip: "Edit User",
                      onClick: (event, rowData) => {
                        updatemodal(rowData);
                      },
                    },
                    (rowData) => ({
                      icon: DeleteOutline,
                      tooltip: "Delete User",
                      onClick: (id, rowData) => {
                        delData(rowData);
                      },
                    }),
                  ]}
                />
      </div>
    </>
  )
}

export default Order