import React from "react";

const Tab1 = () => {
  return (
    <>
    <section className="border mb-4">
      <div className="container d-flex justify-content-center mt-3">
        <div className="row justify-content-center">
          <div className="col-md-3 mt-3 mt-md-0" >
            <input type="number" className="form-control" placeholder="I Need"/>
          </div>
          <div className="col-md-3 mt-3 mt-md-0">
            <select className="form-control w-100">
              <option selected disabled> &nbsp;&nbsp; Select</option>
              <option value="1" style={{overflow: "hidden"}}>
                IRS eFile 1099,1098,W-2,W-2C,W-2G, <br/>1095,5498,3921,3922,1097-BTC,
                592-B
              </option>
              <option value="7">Direct-to-state eFiling</option>
              <option value="2">
                IRS eFile 941,944,940,945,943,941PR,941-X
              </option>
              <option value="3">IRS eFile 1042-S,Paper 1042</option>
              <option value="4">TIN Match</option>
              <option value="5">Mailing</option>
              <option value="8">W8/W9 Requests</option>
              <option value="9">W-2VI,W-2GU</option>
              <option value="10">8809,8027</option>
            </select>
          </div>
          <div className="col-md-3 mt-3 mt-md-0">    
            <button className="btn btn-outline-primary">Add a service</button>
          </div>
        </div>
      </div>
      <div className="row mt-5">
            <h2 className="text-primary">Your service subtotal is : $ 0.00</h2>
        </div>
        <div className="row mt-3 lightblue py-4 rounded mx-3">
            <div className="col-md-6"> 
                <h6 className="text-primary">- Integrate, import, or input info</h6>
                <h6 className="text-primary">- View & edit before submitting</h6>
                <h6 className="text-primary">- Pay as you go for W-9 & TIN match</h6>
            </div>
            <div className="col-md-6">
                <h6 className="text-primary">- Integrate, import, or input info</h6>
                <h6 className="text-primary">- View & edit before submitting</h6>
                <h6 className="text-primary">- Pay as you go for W-9 & TIN match</h6>
            </div>
        </div>
        <div className="row">
            <h4 className="text-primary mt-4">Your Essential eFiIe plan subtotal: $0.00</h4>
            <h2 className="text-primary mt-4">Your Estimated total* is : $0.00</h2>
            <p className="text-primary mt-1">* Actual cost may vary based on services selected when checking out</p>
            <div>
                <button className="btn btn-danger rounded">Proceed</button>
            </div>
            <p className="text-primary mt-1">Looking for more features? Check out our eFile Plus & Enterprise plans. </p>
        </div>
        </section>
    </>
  );
};

export default Tab1;
