/* eslint-disable react-hooks/exhaustive-deps */

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import IconButton from "@material-ui/core/IconButton";





import React, { forwardRef, useEffect, useState } from 'react';

// import
// import DeleteIcon from "@material-ui/icons/Delete";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PostApi } from "../../Helper/helper";
import { getApiWithToken } from "../../Helper/helper";

import BaseUrl from '../../BaseUrl/index'
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader"
import 'react-overlay-loader/styles.css';


const Newemp = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const back =()=>{
    setShow(false)
  }
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow(false);
  const back1 =()=>{
    setShow1(false)
  }
  const [name ,setname] = useState();
  const [trade_name ,settrade_name] = useState();
  const [disregarded_entity ,setdisregarded_entity] = useState();
  const [identification ,setidentification] = useState();
  const [address ,setaddress] = useState();
  const [another_address, setanother_address] = useState();
  const [city, setcity] = useState();
  const [state, setstate] = useState();
  const [zip_code, setzip_code] = useState();
  const [country, setcountry] = useState();
  const [phone_no, setphone_no] = useState();
  const [email, setemail] = useState();
  const [client_id, setclient_id] = useState();
  const [datas, setdatas] = useState();
  // update state
  const [uid, setuid] = useState();
  const [nameu, setnameu] = useState();
  const [trade_nameu, settrade_nameu] = useState();
  const [disregarded_entityu, setdisregarded_entityu] = useState();
  const [identificationu, setidentificationu] = useState();
  const [addressu, setaddressu] = useState();
  const [another_addressu, setanother_addressu] = useState();
  const [cityu, setcityu] = useState();
  const [countryu, setcountryu] = useState();
  const [zip_codeu, setzip_codeu] = useState();
  const [stateu, setstateu] = useState();
  const [phone_nou, setphone_nou] = useState();
  const [emailu, setemailu] = useState();
  const [client_idu, setclient_idu] = useState();
  const [loader, setLoader] = useState(false)
  

  const [userData,] = useState(localStorage.getItem("token"));
  const [idc, ]=useState(localStorage.getItem("id"))

  useEffect(() => {
   
    // if (!localStorage.getItem("token")) {
    //   navigate("/Login");
    // }
    getData();
  }, []);
  const onSubmit = () => {
    setLoader(true)
    const formData = new FormData();
    formData.append("customer_id", idc);

    formData.append("name", name);
    formData.append("trade_name", trade_name);
    formData.append("disregarded_entity", disregarded_entity);
    formData.append("identification", identification);
    formData.append("another_address", another_address);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zip_code", zip_code);
    formData.append("country", country);
    formData.append("phone_no", phone_no);
    formData.append("email", email);
    formData.append("client_id", client_id);

    PostApi(`${BaseUrl.baseUrl}customer/employee/store`, formData, userData)
      .then((data) => {
        setLoader(false)
        console.log(data.data, "ali");
        
        if (data.data.message === "Employee Successfully Created") {
          Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          getData()
          setShow(false)
         
          // console.log(data.data,"minhaj")
          //   console.log("heheh",data.data.user.name)
          // console.log("name", data.data.accessToken.name)
          // console.log("hahahahaha",data.status)
          // console.log("hahahahaha",data.message)

          // localStorage.setItem("token", data.data.accessToken.name);
          // localStorage.setItem("username", data.data.user.name);
          // console.log("login");
          // navigate("/");
        } else {
          // console.log("else========", data.message);
          // // alert(data.data);
          // localStorage.setItem("tokenen", data.data);
          console.log("not login");
          // Swal.fire({
          //   title: "Oops",
          //   text: "Error",
          //   icon: "error",
          //   confirmButtonColor: "#29BF12",
          // });
          console.log(data.data.errors.DOB, "DOB error");

          // setDateError(data.data.errors.DOB, "Gender error");
          // setGenderError(data.data.errors.gender, "Gender error");
          // setPasswordError(data.data.errors.password, "Password Error");
          // setPasswordConError(
          //   data.data.errors.passwordConfirmation,
          //   "Password Con Error"
          // );
          // setEmailError(data.data.errors.email, "Email Error");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
      });
  };
  const updateData = () => {
    setLoader(true)
    let formdata = new FormData();
    
    
    formdata.append("customer_id", idc);
    formdata.append("name", nameu);
    formdata.append("trade_name", trade_nameu);
    formdata.append("disregarded_entity", disregarded_entityu);
    formdata.append("identification", identificationu);
    formdata.append("address", addressu);

    formdata.append("another_address", another_addressu);
    formdata.append("city", cityu);
    formdata.append("country", countryu);

    formdata.append("zip_code", zip_codeu);
    formdata.append("state", stateu);
    formdata.append("phone_no", phone_nou);

    formdata.append("email", emailu);
    formdata.append("state", stateu);
    formdata.append("client_id", client_idu);
    // formdata.append(
    //   "picture",
    //   UpdateProfileImage == null ? ProfileImage : UpdateProfileImage
    // );

    // console.log("form data", formdata);

    PostApi(`${BaseUrl.baseUrl}customer/employee/update/${uid}`, formdata, userData)
      .then((response) => {
        setLoader(false)
        console.log("hhhhh>>>>>", response);
        console.log("minhaj", response.data.message);
        if (response.data.status) {
          Swal.fire({
            title: "success",
            text: response.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
          setShow1(false)
          getData();
          
         
        } 
        else {

          Swal.fire({
            title: "error",
            text: response.data.message,
            icon: "error",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
         
        }

        
       
      })
      .catch((err) => {
        Swal.fire({
          title: "error",
          text: err,
          icon: "error",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });
       
      
      });
  };
  const updatemodal = (rowData) => {
    // setLoader(true);
    console.log(rowData.id, 'uid')
    setuid(rowData.id);

    getApiWithToken(`${BaseUrl.baseUrl}customer/employee/edit/${rowData.id}`, "", userData)
      .then(({ data }) => {
        console.log("getdata", data.data);
        // setuid(data.user.id);
        setnameu(data.data.name);
        settrade_nameu(data.data.trade_name);
        setdisregarded_entityu(data.data.disregarded_entity);
        setidentificationu(data.data.identification);
        setaddressu(data.data.address);
        setanother_addressu(data.data.another_address)
        setcityu(data.data.city);
        setcountryu(data.data.country);
        setzip_codeu(data.data.zip_code);
        setstateu(data.data.state);
        setphone_nou(data.data.phone_no);
        setemailu(data.data.email);
        setclient_idu(data.data.client_id);
        

        

        // setLoader(false);
        setShow1(true);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const getData = () => {
    setLoader(true)
    getApiWithToken(`${BaseUrl.baseUrl}customer/employee/view`, "", userData)
      .then(({ data }) => {
        console.log("mmmmmmmmmmmmmmmmmmmmm", data.data);
        setdatas(data.data)
         
        // setName(data.name);
        // setEmail(data.email);
        // setProfileImage(data.picture);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const delData = (rowData) => {
    console.log("didasf", rowData.id);
    // setdid(rowData.id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        getApiWithToken(
          `${BaseUrl.baseUrl}customer/employee/delete/${rowData.id}`,
          "",
          userData
        )
          .then((response) => {
            console.log("response", response);

            // Swal.fire({
            //   title: "success",
            //   text: "Update Successfully ",
            //   icon: "success",
            //   confirmButtonColor: "#29BF12",
            //   timer: 1500,
            // });
          })
          .catch((err) => {
            console.log("errrrr", err);
          });
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your file has been deleted. ',
            showConfirmButton: false,
            timer: 1500
          })

      
        getData();
      }
    });
  };
 
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DeleteOutline: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  return (
    <>
     {loader ? <Loader fullpage loading /> : null}
    <Modal 
      show={show1} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose1}>
                {/* <Modal.Header closeButton> */}
                <Modal.Header  >
                    {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
                    <Modal.Title >EDIT EMPLOYER</Modal.Title>
                    {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}

                </Modal.Header >
                <Modal.Body >
                    <div className='row'>
                        <div className='col-lg-6'>
                          <label >Name</label >
                          <input type="text" className="form-control" name="name" value={nameu}  onChange={(e) => {
                            setnameu(e.target.value);
                          }}/>
                          <label >Disregarded Entity</label >
                          <input type="text" className="form-control" name="disregarded entity" value={disregarded_entityu} 
                          onChange={(e) => {
                            setdisregarded_entityu(e.target.value)
                          }}
                          />
                          <label >Address</label >
                          <input type="text" className="form-control" name="address" value={addressu} onChange={(e) => {
                            setaddressu(e.target.value)
                          }}/>
                          <label >City</label >
                          <input type="text" className="form-control" name="City" value={cityu} onChange={(e) => {
                            setcityu(e.target.value)
                          }}/>
                          <label >Zip Code</label >
                          <input type="text" className="form-control" name="Zip code" value={zip_codeu}
                          onChange={(e) => {
                            setzip_codeu(e.target.value)
                          }}/>
                          <label >phone no</label >
                          <input type="text" className="form-control" name="phone no" value={phone_nou}
                          onChange={(e) => {
                            setphone_nou(e.target.value)
                          }}/>
                          <label >client id</label >
                          <input type="text" className="form-control" name="client_id" value={client_idu}
                          onChange={(e) => {
                            setclient_idu(e.target.value)
                          }}/>

                        </div>
                        <div className='col-lg-6'>
                        <label >Trade Name</label >
                          <input type="text" className="form-control" name="trade name" value={trade_nameu}
                           onChange={(e) => {
                            settrade_nameu(e.target.value)
                          }}/>
                          <label >Identification</label >
                          <input type="text" className="form-control" name="Identification" value={identificationu}
                          onChange={(e) => {
                            setidentificationu(e.target.value)
                          }}/>
                          <label >Another_address</label >
                          <input type="text" className="form-control" name="Another Address" value={another_addressu}
                           onChange={(e) => {
                            setanother_addressu(e.target.value)
                          }}/>
                          <label >Country</label >
                          <input type="text" className="form-control" name="country" value={countryu}
                           onChange={(e) => {
                            setcountryu(e.target.value)
                          }}/>
                          <label >State</label >
                          <input type="text" className="form-control" name="State" value={stateu}
                           onChange={(e) => {
                            setstateu(e.target.value)
                          }}/>
                          <label >Email</label >
                          <input type="text" className="form-control" name="email" value={emailu}
                          onChange={(e) => {
                            setemailu(e.target.value)
                          }}/>

                        </div>
                      

                    </div>
                 



                </Modal.Body>
                
                <Modal.Footer >
                <Button  className="btn btn-primary"  style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}  onClick={updateData}>
                        Save
                    </Button>
               
                    <Button  className="btn btn-primary" onClick={back1} style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
                </Modal.Footer>
            </Modal>
     <Modal 
      show={show} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}>
                {/* <Modal.Header closeButton> */}
                <Modal.Header  >
                    {/* <i className='fa fa-close'>baloch</i>
                    <AiFillCloseCircle fontSize={20} /> */}
                    <Modal.Title >ADD EMPLOYER</Modal.Title>
                    {/* <AiFillCloseCircle onClick={handleClose} style={{ marginLeft: "190", cursor: "pointer" }} fontSize={40} /> */}

                </Modal.Header >
                <Modal.Body >
                    <div className='row'>
                        <div className='col-lg-6'>
                          <label >Name</label >
                          <input type="text" className="form-control" name="name"  onChange={(e) => {
                            setname(e.target.value);
                          }}/>
                          <label >Disregarded Entity</label >
                          <input type="text" className="form-control" name="disregarded entity" 
                          onChange={(e) => {
                            setdisregarded_entity(e.target.value)
                          }}
                          />
                          <label >Address</label >
                          <input type="text" className="form-control" name="address" onChange={(e) => {
                            setaddress(e.target.value)
                          }}/>
                          <label >City</label >
                          <input type="text" className="form-control" name="City" onChange={(e) => {
                            setcity(e.target.value)
                          }}/>
                          <label >Zip Code</label >
                          <input type="text" className="form-control" name="Zip code"
                          onChange={(e) => {
                            setzip_code(e.target.value)
                          }}/>
                          <label >phone no</label >
                          <input type="text" className="form-control" name="phone no"
                          onChange={(e) => {
                            setphone_no(e.target.value)
                          }}/>
                          <label >client id</label >
                          <input type="text" className="form-control" name="client_id"
                          onChange={(e) => {
                            setclient_id(e.target.value)
                          }}/>

                        </div>
                        <div className='col-lg-6'>
                        <label >Trade Name</label >
                          <input type="text" className="form-control" name="trade name"
                           onChange={(e) => {
                            settrade_name(e.target.value)
                          }}/>
                          <label >Identification</label >
                          <input type="text" className="form-control" name="trade name"
                          onChange={(e) => {
                            setidentification(e.target.value)
                          }}/>
                          <label >Another_address</label >
                          <input type="text" className="form-control" name="Another Address"
                           onChange={(e) => {
                            setanother_address(e.target.value)
                          }}/>
                          <label >Country</label >
                          <input type="text" className="form-control" name="country"
                           onChange={(e) => {
                            setcountry(e.target.value)
                          }}/>
                          <label >State</label >
                          <input type="text" className="form-control" name="State"
                           onChange={(e) => {
                            setstate(e.target.value)
                          }}/>
                          <label >Email</label >
                          <input type="text" className="form-control" name="email"
                          onChange={(e) => {
                            setemail(e.target.value)
                          }}/>

                        </div>
                      

                    </div>
                 



                </Modal.Body>
                
                <Modal.Footer >
                <Button  className="btn btn-primary"  style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}} onClick={onSubmit}>
                        Add
                    </Button>
               
                    <Button  className="btn btn-primary" onClick={back} style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={addcustomer}>
                        Add
                    </Button> */}
                </Modal.Footer>
            </Modal>

    <div className="col-lg-12 " style={{marginBottom: '10px'}}>
        <button className="btn btn-primary d-flex flex-row-reverse " onClick={() => setShow(true)}>New Payer</button>
        </div>
      
      <div className='col-lg-12'>
        
      <MaterialTable
      icons={tableIcons}
                  title="List"
                  columns={[
                    { title: "Name", field: "name" },
                    { title: "Address", field: "address" },
                    { title: "Another Address", field: "another_address" },
                    { title: "Disregarded Entity", field: "disregarded_entity" },
                    { title: "Identification", field: "identification" },

                    { title: "Trade Name", field: "trade_name" },
                    
                  ]}
                  data={datas}
                  options={{
                    addRowPosition: "first",
                  }}
                  actions={[
                    {
                      icon: Edit,
                      tooltip: "Edit User",
                      onClick: (event, rowData) => {
                        updatemodal(rowData);
                      },
                    },
                    (rowData) => ({
                      icon: DeleteOutline,
                      tooltip: "Delete User",
                      onClick: (id, rowData) => {
                        delData(rowData);
                      },
                    }),
                  ]}
                />
      </div>
    </>
  )
}

export default Newemp